import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  createTheme,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material'
import { Add, Remove } from '@mui/icons-material'
import CarSpecs from '../CarSpecs'
import { setCarMainData } from '../../../store/features/addNewCarSlice/addNewCar.reducer'
import { useSelector, useDispatch } from 'react-redux'
import { colorsConfig } from '../../../config/themeConfig'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import IconApple from '../../../assests/img/icons/icon-apple.svg'
import { gearboxTypes } from '../../../config/formsConfig'
import ManufacturerCarSpecs from '../CarSpecs/ManufacturerCarSpecs'
import { useTranslation, Trans } from 'react-i18next'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import UIBadgeSuccess from '../../../ui/Badges/UIBadgeSuccess'
import IconCarAutofilled from '../../../assests/img/icons/icon-car-autofilled.svg'
import CarRequiredFields from '../FormTemplates/CarRequiredFields'
import CarOptionalFields from '../FormTemplates/CarOptionalFields'
import CarPriceFields from '../FormTemplates/CarPriceFields'
import UITextTitleSmall from '../../../ui/UIText/UITextTitleSmall'
import CarEcoFields from '../FormTemplates/CarEcoFields'
import SimpleCheckbox from '../../../ui/SimpleCheckbox'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import { getUpholsteryCheckboxOptions } from '../addNewVehicleForm.helpers'
import CarColorField from '../FormTemplates/CarColorField'
import { getEnumDetails } from '../../../store/features/references/references.actions'

const MainDataStep = ({ carSpecsBlocks, changeCarSpec, showErrors }) => {
  const { t } = useTranslation()

  const theme = createTheme({
    spacing: [0, 5, 10, 20],
  })

  const carData = useSelector((state) => state.addCar.carMainData)
  const enumsDetails = useSelector((state) => state.ref.enumDetails.data)
  const isCarDataAutofilled = useSelector((state) => state.addCar.vinProvider.data)
  const locale = useSelector((state) => state.auth.user?.locale)
  const dispatch = useDispatch()

  const manufacturerSpecs = carData?.manufacturerSpecs ?? []

  const [showSpecs, setShowSpecs] = useState(false)

  const handleTranslateSpecs = () => {
    const newSpecs = carData?.allLocaleSpecs?.[locale]
    if (newSpecs) {
      dispatch(
        setCarMainData({
          ...carData,
          additionalSpecsDetails: newSpecs.join('; '),
        }),
      )
    }
  }

  useEffect(() => {
    if (carData.engineType?.name === 'Electric') {
      dispatch(
        setCarMainData({
          ...carData,
          gearbox: gearboxTypes.filter((item) => item.systemName === 'automatic')[0],
          gearsNumber: 1,
          engineSize: '',
          cylinder: '',
          fuelConsumption: null,
        }),
      )
    }
  }, [carData.engineType])

  useEffect(() => {
    handleTranslateSpecs()
    dispatch(getEnumDetails())
  }, [locale])

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {isCarDataAutofilled && (
          <UIBadgeSuccess
            prefixIcon={<img src={IconCarAutofilled} alt="" />}
            text="badge_car_info_autofilled"
            sx={{ mb: '25px' }}
          />
        )}
        <Stack gap="40px">
          <Stack gap="14px">
            <TextBoldNormal
              sx={{
                fontSize: '20px',
              }}
            >
              {t('title_required_fields')} <span style={{ color: colorsConfig.mainRed }}>*</span>
            </TextBoldNormal>
            <CarRequiredFields
              isCarDataAutofilled={isCarDataAutofilled}
              formData={carData}
              setFormData={(data) => dispatch(setCarMainData(data))}
              showErrors={showErrors}
            />
          </Stack>
          <Stack gap="14px">
            <TextBoldNormal
              sx={{
                fontSize: '20px',
              }}
            >
              {t('title_optional_fields')}
            </TextBoldNormal>
            <Stack gap="20px">
              <CarOptionalFields
                formData={carData}
                setFormData={(data) => dispatch(setCarMainData(data))}
              />
              <CarPriceFields
                isCarDataAutofilled={isCarDataAutofilled}
                formData={carData}
                setFormData={(data) => dispatch(setCarMainData(data))}
              />
            </Stack>
          </Stack>
          <Stack gap="14px">
            <UITextTitleSmall
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {t('title_eco_data')} <img src={IconApple} alt="" style={{ marginLeft: '4px' }} />
            </UITextTitleSmall>
            <CarEcoFields
              formData={carData}
              setFormData={(data) => dispatch(setCarMainData(data))}
            />
          </Stack>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ mt: '30px' }}>
            <TextFieldSmallEdible
              fullWidth
              label="title_additional_specifications"
              type="textarea"
              multiline
              rows={8}
              maxRows={10}
              value={carData.additionalSpecsDetails}
              onChange={(e) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    additionalSpecsDetails: e.target.value,
                  }),
                )
              }}
            />
          </Grid>
          {isCarDataAutofilled ? (
            <Grid item xs={12} sx={{ mb: '-35px', color: colorsConfig.mainBlue }}>
              <ManufacturerCarSpecs manufacturerSpecs={manufacturerSpecs} />
            </Grid>
          ) : null}
          <Grid item xs={12} sx={{ mt: '35px', mb: '25px', color: colorsConfig.mainBlue }}>
            <Button
              onClick={() => {
                setShowSpecs(!showSpecs)
              }}
              sx={{
                fontWeight: 700,
                textTransform: 'none',
                color: colorsConfig.mainBlue,
                fontSize: '16px',
              }}
              startIcon={
                !showSpecs ? (
                  <Add
                    style={{ fill: colorsConfig.mainBlue }}
                    sx={{
                      marginTop: '-2px',
                      position: 'relative',
                    }}
                  />
                ) : (
                  <Remove sx={{ marginTop: '-2px', position: 'relative' }} />
                )
              }
            >
              {t('title_additional_specifications')}
            </Button>
            {showSpecs ? <CarSpecs blocks={carSpecsBlocks} changeCarSpec={changeCarSpec} /> : null}
          </Grid>
          <Grid item sm={6} xs={12}>
            <CarColorField
              title="form_input_label_exterior_color"
              value={carData.exteriorColor}
              setValue={(value) =>
                dispatch(
                  setCarMainData({
                    ...carData,
                    exteriorColor: value,
                  }),
                )
              }
              sx={{ mb: '20px' }}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '24px',
                color: colorsConfig.mainBlack,
                mb: '20px',
              }}
            >
              {t('form_input_label_paintwork')}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Stack direction="row" gap="4px" alignItems="center">
                  <SimpleCheckbox
                    value={carData.isMetallic}
                    setValue={(value) =>
                      dispatch(setCarMainData({ ...carData, isMetallic: value }))
                    }
                  />
                  <UITextRegular text="paintwork_type_metallic" />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack direction="row" gap="4px" alignItems="center">
                  <SimpleCheckbox
                    value={carData.isMatt}
                    setValue={(value) => dispatch(setCarMainData({ ...carData, isMatt: value }))}
                  />
                  <UITextRegular text="paintwork_type_matt" />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack direction="row" gap="4px" alignItems="center">
                  <SimpleCheckbox
                    value={carData.isWrapped}
                    setValue={(value) => dispatch(setCarMainData({ ...carData, isWrapped: value }))}
                  />
                  <UITextRegular text="paintwork_type_wrapped" />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CarColorField
              title="form_input_label_interior_color"
              value={carData.interiorColor}
              setValue={(value) =>
                dispatch(
                  setCarMainData({
                    ...carData,
                    interiorColor: value,
                  }),
                )
              }
              sx={{ mb: '20px' }}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '24px',
                color: colorsConfig.mainBlack,
                mb: '20px',
              }}
            >
              {t('form_input_label_upholstery')}
            </Typography>
            <Grid container spacing={3}>
              {getUpholsteryCheckboxOptions(enumsDetails).map((item) => {
                return (
                  <Grid key={item?.name} item xs={3}>
                    <Stack direction="row" gap="4px" alignItems="center">
                      <SimpleCheckbox
                        value={carData.upholstery === item?.value}
                        setValue={(value) => {
                          dispatch(
                            setCarMainData({ ...carData, upholstery: value ? item?.value : null }),
                          )
                        }}
                      />
                      <UITextRegular
                        text={item?.readableValue}
                        sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      />
                    </Stack>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default MainDataStep
