import React, { useEffect, useState } from 'react'
import { Box, Grid, InputAdornment, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getRviPolicy,
  updateRviPolicy,
} from '../../../../store/features/rviPolicy/rviPolicy.actions'
import { resetRviPolicy } from '../../../../store/features/rviPolicy/rviPolicy.reducer'
import UIPreloader from '../../../../ui/UIPreloader/UIPreloader'
import UIBreadcrumbs from '../../../../ui/UIBreadcrumbs/UIBreadcrumbs'
import { AssuredWorkloadRounded } from '@mui/icons-material'
import UITextTitleSmall from '../../../../ui/UIText/UITextTitleSmall'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import UITextBodySmall from '../../../../ui/UIText/UITextBodySmall'
import ButtonSuccess from '../../../../ui/Buttons/ButtonSuccess'
import { editRVIPolicyInitialState, getRVIPolicyFormData } from './EditRVIPolicy.helpers'
import SimpleCheckbox from '../../../../ui/SimpleCheckbox'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { setCarMainData } from '../../../../store/features/addNewCarSlice/addNewCar.reducer'
import { toast } from 'react-toastify'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'

const EditRVIPolicy = () => {
  const isLoading = useSelector((state) => state.rviPolicy.isLoading)
  const policy = useSelector((state) => state.rviPolicy.data)

  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const [formData, setFormData] = useState(editRVIPolicyInitialState)

  const handleSubmit = async () => {
    const res = await dispatch(
      updateRviPolicy({
        policyId: params.id,
        data: getRVIPolicyFormData(formData),
      }),
    )
    if (updateRviPolicy.fulfilled.match(res)) {
      toast.success('Policy has been updated')
      navigate('/operator/rvi')
    }
  }

  useEffect(() => {
    if (policy) {
      setFormData({
        ...formData,
        ...policy.details,
      })
    }
  }, [policy])

  useEffect(() => {
    dispatch(getRviPolicy({ policyId: params.id }))
    return () => {
      dispatch(resetRviPolicy())
    }
  }, [])

  return (
    <Box sx={{ position: 'relative', minHeight: '300px' }}>
      {isLoading ? (
        <UIPreloader text="Loading the policy..." />
      ) : (
        <Stack gap="30px">
          <Stack gap="30px">
            <UIBreadcrumbs
              pages={[
                {
                  icon: <AssuredWorkloadRounded />,
                  name: 'RVI Policies',
                  link: '/operator/rviOperator',
                },
                {
                  name: policy?.details?.policyNumber
                    ? `Edit ${policy?.details?.policyNumber}`
                    : '-',
                },
              ]}
            />
          </Stack>

          <Grid container spacing="24px">
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Policy Number"
                value={formData?.policyNumber}
                disabled
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Internal customer ID"
                value={formData.internalCustomerId}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    internalCustomerId: e.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Dealer ID"
                value={formData?.dealerId}
                disabled
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Dealer name (from the file)"
                value={formData?.dealerName}
                disabled
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Model ID"
                value={formData?.modelId}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    modelId: e.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Customer contract number"
                value={formData.customerContractNumber}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    customerContractNumber: e.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Make"
                value={formData?.make}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    make: e.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Model"
                value={formData?.model}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    model: e.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Color"
                value={formData.color}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    color: e.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible fullWidth label="VIN" value={formData?.vin} disabled />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Mileage"
                value={formData.mileage}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    mileage: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>km</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Vehicle gross price (excluding options, including VAT)"
                value={formData.vehicleGrossPriceExcludingOptionsIncludingVAT}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    vehicleGrossPriceExcludingOptionsIncludingVAT: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Vehicle options (including VAT)"
                value={formData.vehicleOptionsIncludingVAT}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    vehicleOptionsIncludingVAT: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Vehicle gross price (including options, including VAT)"
                value={formData.vehicleGrossPriceIncludingOptionsIncludingVAT}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    vehicleGrossPriceIncludingOptionsIncludingVAT: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Discount (including VAT)"
                value={formData.discountIncludingVAT}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    discountIncludingVAT: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Vehicle net price (including VAT)"
                value={formData.vehicleNetPriceIncludingVAT}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    vehicleNetPriceIncludingVAT: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Annual mileage"
                value={formData.mileagePa}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    mileagePa: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>km</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Contract duration"
                value={formData.contractDurationInMonths}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    contractDurationInMonths: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>months</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Offer ID"
                value={formData.offerId}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    offerId: e.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="First registration date"
                  inputFormat="DD/MM/YYYY"
                  mask={'__/__/____'}
                  disableMaskedInput={false}
                  renderInput={(params) => (
                    <TextFieldSmallEdible
                      {...params}
                      fullWidth
                      required
                      value={formData.firstRegistrationDate}
                    />
                  )}
                  value={formData.firstRegistrationDate}
                  onChange={(newVal) => {
                    setFormData({
                      ...formData,
                      firstRegistrationDate: newVal,
                    })
                  }}
                  disableFuture
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="RVI Start Date"
                  inputFormat="DD/MM/YYYY"
                  mask={'__/__/____'}
                  disableMaskedInput={false}
                  renderInput={(params) => (
                    <TextFieldSmallEdible
                      {...params}
                      fullWidth
                      required
                      value={formData.RVIStartDate}
                    />
                  )}
                  value={formData.RVIStartDate}
                  onChange={(newVal) => {
                    setFormData({
                      ...formData,
                      RVIStartDate: newVal,
                    })
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="RVI End Date"
                  inputFormat="DD/MM/YYYY"
                  mask={'__/__/____'}
                  disableMaskedInput={false}
                  renderInput={(params) => (
                    <TextFieldSmallEdible
                      {...params}
                      fullWidth
                      required
                      value={formData.RVIEndDate}
                    />
                  )}
                  value={formData.RVIEndDate}
                  onChange={(newVal) => {
                    setFormData({
                      ...formData,
                      RVIEndDate: newVal,
                    })
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Sales period"
                  inputFormat="MM/YYYY"
                  mask={'__/____'}
                  disableMaskedInput={false}
                  renderInput={(params) => (
                    <TextFieldSmallEdible {...params} fullWidth value={formData.salesPeriod} />
                  )}
                  value={formData.salesPeriod}
                  onChange={(newVal) => {
                    setFormData({
                      ...formData,
                      salesPeriod: newVal,
                    })
                  }}
                  views={['year', 'month']}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Business partner ID"
                value={formData.businessPartnerId}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    businessPartnerId: e.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="RV warrant"
                value={formData.RVWarrant}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    RVWarrant: e.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Customer reference"
                value={formData.customerReference}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    customerReference: e.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="List price (including options, excluding VAT)"
                value={formData.listPriceIncludingOptionsExcludingVAT}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    listPriceIncludingOptionsExcludingVAT: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="RV premium (excluding VAT)"
                value={formData.RVPremiumExcludingVAT}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    RVPremiumExcludingVAT: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="RV premium"
                value={formData.RVPremiumPercent}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    RVPremiumPercent: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>%</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <UITextRegular text="RVI Premium Paid" />
              <SimpleCheckbox
                value={formData.RVPremiumPaid}
                setValue={(value) => {
                  setFormData({
                    ...formData,
                    RVPremiumPaid: value,
                  })
                }}
              />
            </Grid>
            {/*<Grid*/}
            {/*  item*/}
            {/*  xs={12}*/}
            {/*  md={4}*/}
            {/*  display="flex"*/}
            {/*  justifyContent="space-between"*/}
            {/*  alignItems="center"*/}
            {/*>*/}
            {/*  <UITextRegular text="Risk" />*/}
            {/*  <SimpleCheckbox*/}
            {/*    value={formData?.risk}*/}
            {/*    setValue={(value) => {*/}
            {/*      setFormData({*/}
            {/*        ...formData,*/}
            {/*        risk: value,*/}
            {/*      })*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Risk"
                value={formData.risk}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    risk: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Insercle handling fee"
                value={formData.handlingFee}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    handlingFee: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <UITextRegular text="In Risk" />
              <SimpleCheckbox
                value={formData?.inRisk}
                setValue={(value) => {
                  setFormData({
                    ...formData,
                    inRisk: value,
                  })
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <UITextRegular text="Reinsurance" />
              <SimpleCheckbox
                value={formData.reinsurance}
                setValue={(value) => {
                  setFormData({
                    ...formData,
                    reinsurance: value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Reinsurer"
                value={formData.reinsurer}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    reinsurer: e.target.value,
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Reinsurance Brutto"
                value={formData.reinsuranceBrutto}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    reinsuranceBrutto: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Insurance tax"
                value={formData.insuranceTax}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    insuranceTax: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Reinsurance Netto"
                value={formData.reinsuranceNetto}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    reinsuranceNetto: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="ReInsurance Risk"
                value={formData.reinsuranceRisk}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    reinsuranceRisk: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Reinsurance VK"
                value={formData.reinsuranceVK}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    reinsuranceVK: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Reserves"
                value={formData.reserves}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    reserves: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible fullWidth label="Status" value={formData?.status} disabled />
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Real end date"
                  inputFormat="DD/MM/YYYY"
                  mask={'__/__/____'}
                  disableMaskedInput={false}
                  renderInput={(params) => (
                    <TextFieldSmallEdible
                      {...params}
                      fullWidth
                      required
                      value={formData.realEndDate}
                    />
                  )}
                  value={formData.realEndDate}
                  onChange={(newVal) => {
                    setFormData({
                      ...formData,
                      realEndDate: newVal,
                    })
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Initial Residual Value"
                value={formData.initialRVPercent}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    initialRVPercent: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>%</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label="Initial Residual Value (including VAT)"
                value={formData.initialResidualValueIncludingVAT}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    initialResidualValueIncludingVAT: e.target.value,
                  })
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <UITextBodySmall>CHF</UITextBodySmall>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Stack direction="row" gap="10px" justifyContent="flex-end">
            <ButtonHollow onClick={() => navigate(-1)}>Cancel</ButtonHollow>
            <ButtonSuccess onClick={() => handleSubmit()}>Save changes</ButtonSuccess>
          </Stack>
        </Stack>
      )}
    </Box>
  )
}

export default EditRVIPolicy
