import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { Stack } from '@mui/material'

const UIMatrixRow = ({ children }) => {
  return (
    <Stack
      direction="row"
      sx={{
        borderBottom: '1px solid',
        borderColor: colorsConfig.buttonDisabled,
        '&:last-child': {
          border: 'none',
        },
      }}
    >
      {children}
    </Stack>
  )
}

export default UIMatrixRow
