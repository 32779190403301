import { adminRequest, financeRequest } from '../../utils/request'

class ResidualValueMatrixEntriesApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      entries: '/reference/residual-value-matrix-entries',
      entry: (id) => `/reference/residual-value-matrix-entries/${id}`,
    }
  }

  getEntries = async (params) => {
    const response = await this.#api.get(this.#endPoints.entries, { params })
    return response
  }

  getEntry = async (entryId) => {
    const response = await this.#api.get(this.#endPoints.entry(entryId))
    return response
  }

  createEntry = async (data) => {
    const response = await this.#api.post(this.#endPoints.entries, data)
    return response
  }

  updateEntry = async (entryId, data) => {
    const response = await this.#api.put(this.#endPoints.entry(entryId), data)
    return response
  }

  patchEntry = async (entryId, data) => {
    const response = await this.#api.patch(this.#endPoints.entry(entryId), data)
    return response
  }

  deleteEntry = async (entryId) => {
    const response = await this.#api.delete(this.#endPoints.entry(entryId))
    return response
  }
}

const residualValueMatrixEntriesApiService = new ResidualValueMatrixEntriesApiService({
  api: adminRequest,
})

export default residualValueMatrixEntriesApiService
