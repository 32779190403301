import React, { useEffect, useState } from 'react'
import { colorsConfig } from '../../../config/themeConfig'
import TextNormal from '../../../ui/Text/TextNormal'
import {
  Box,
  IconButton,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Gallery } from 'react-photoswipe-gallery'
import pdfIcon from '../../../assests/img/icons/pdf-icon.png'
import DamagePhoto from './DamagePhoto'
import { API_ENDPOINTS, CDN_BASE_URL, FILE_TYPES, HINT_TYPES } from '../../../config/default'
import IconDeleteRed from '../../../assests/img/icons/icon-delete-red.svg'
import { useSelector, useDispatch } from 'react-redux'
import IconAttentionRed from '../../../assests/img/icons/icon-attention-red.svg'
import IconEdit from '../../../assests/img/icons/icon-edit.svg'
import TextFieldSmall from '../../../ui/TextFieldSmall'
import IconUploadBlue from '../../../assests/img/icons/icon-upload-blue.svg'
import { FileUploader } from 'react-drag-drop-files'
import { CancelRounded, HighlightOff } from '@mui/icons-material'
import { addDamage, deleteCarDamagePhoto } from '../../../store/features/carSlice/carSlice'
import { patchRequest, privateMultipartRequest, privateRequest } from '../../../utils/request'
import { fixHint } from '../../../store/features/qualityCheckSlice'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'

const CarDamageItem = ({ index, carDamage, handleDeleteDamage, carParts }) => {
  const { t } = useTranslation()

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const role = useSelector((state) => state.auth.role)
  const carDamages = useSelector((state) => state.car.appraisal.damages)
  const qualityCheckDamages = useSelector((state) => state.qualityCheck.hints)

  const dispatch = useDispatch()
  const params = useParams()

  const [currentDamageHint, setCurrentDamageHint] = useState('')
  const [currentDamageHintIndex, setCurrentDamageHintIndex] = useState(null)
  const [showHint, setShowHint] = useState('none')
  const [editing, setEditing] = useState(false)
  const [carPart, setCarPart] = useState('')
  const [damageType, setDamageType] = useState('')
  const [files, setFiles] = useState([])

  const handleSetShowHint = () => {
    if (role !== 'COMPANY' && showHint) {
      setShowHint('none')
      return
    }
    const matchHint = qualityCheckDamages.filter(
      (item) => item.carDamage && item.carDamage === carDamage['@id'],
    )[0]
    if (matchHint && matchHint.fixed === false) {
      setShowHint('not_fixed')
      return
    }
    if (matchHint && matchHint.fixed) {
      setShowHint('fixed')
      return
    }
    setShowHint('none')
  }

  const handleSetCurrentDamageHint = () => {
    const matchHint = qualityCheckDamages.filter((item, index) => {
      if (item.carDamage && item.carDamage === carDamage['@id']) setCurrentDamageHintIndex(index)
      return item.carDamage && item.carDamage === carDamage['@id']
    })[0]
    if (matchHint) {
      setCurrentDamageHint(matchHint)
    }
  }

  const handleSetPhotos = (newFiles) => {
    if (files.length > 0) {
      const updatedFiles = files.concat(Array.from(newFiles))
      setFiles(updatedFiles)
    } else {
      setFiles(Array.from(newFiles))
    }
  }

  const removeFile = (fileIndex) => {
    const updatedFiles = files.filter((item, index) => {
      return index !== fileIndex
    })
    setFiles(updatedFiles)
  }

  const handleSaveCarDamage = async () => {
    const damageable = carPart.damages.filter((item) => item.name === damageType)[0]['@id']
    const newDamages = carDamages.map((item) => {
      if (item.id === carDamage.id) {
        return {
          ...item,
          damagePhotos:
            item.damagePhotos && item.damagePhotos.length > 0
              ? item.damagePhotos.concat(Array.from(files))
              : Array.from(files),
          damageable: damageable,
          carPartIri: carPart['@id'],
          damage: {
            name: damageType,
            part: {
              name: carPart.name,
              zone: carPart.zone,
            },
          },
          uploading: true,
        }
      }
      return item
    })
    dispatch(addDamage(newDamages))
    const filesArray = Array.from(files)
    setFiles([])
    const damagePhotos = filesArray.map((item) => {
      const formDataFile = new FormData()
      formDataFile.append('file', item)
      formDataFile.append('type', FILE_TYPES.damage)
      return privateMultipartRequest.post(API_ENDPOINTS.file, formDataFile)
    })
    const damagePhotosIris = await Promise.all(damagePhotos).then((result) => {
      return result.map((item) => {
        return { photo: item.data['@id'] }
      })
    })
    patchRequest
      .patch(carDamage['@id'], {
        damage: damageable,
        photos: damagePhotosIris,
      })
      .then(() => {
        const updatedDamages = newDamages.map((item) => {
          if (item.id === carDamage.id) {
            return {
              ...item,
              uploading: false,
            }
          }
          return item
        })
        dispatch(addDamage(updatedDamages))
        console.log(currentDamageHint)
        if (currentDamageHint) {
          privateRequest.put(
            `/cars/${params.id}/quality-check-hints/${currentDamageHint.id}/complete`,
            {},
          )
          console.log(currentDamageHintIndex)
          dispatch(fixHint({ index: currentDamageHintIndex }))
        }
      })
  }

  const handleSetEditing = () => {
    if (!editing) {
      const currentCarPart = carParts.filter((item) => item.name === carDamage.damage.part.name)[0]
      setCarPart(currentCarPart)
      setDamageType(
        currentCarPart.damages.filter((item) => item.name === carDamage.damage.name)[0].name,
      )
    }
    if (editing) handleSaveCarDamage()
    setEditing(!editing)
  }

  const handleDeleteDamagePhoto = (carDamagePhotoId) => {
    const newPhotos = carDamage.photos.filter((item) => item.id !== carDamagePhotoId)
    dispatch(
      deleteCarDamagePhoto({
        carDamageIndex: index,
        carDamagePhotos: newPhotos,
      }),
    )
    privateRequest.delete(`${carDamage['@id']}/photos/${carDamagePhotoId}`)
  }

  useEffect(() => {
    handleSetShowHint()
    handleSetCurrentDamageHint()
  }, [qualityCheckDamages])

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', carDamage.uploading ? 'preloader-overlay-lds' : ''].join(
        ' ',
      )}
      sx={{
        border: '1px solid',
        borderRadius: '6px',
        borderColor: colorsConfig.lightGray,
        paddingTop: '10px',
        paddingBottom: '10px',
        mb: '12px',
        position: 'relative',
        '&.xs-only': {
          px: '10px',
        },
      }}
    >
      {carDamage.uploading ? <div className="lds-dual-ring"></div> : null}
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          '&.xs-only': {
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            mb: '15px',
          },
        }}
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            width: '23%',
            paddingLeft: '17px',
            height: '52px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '&.xs-only': {
              width: 'calc(50% - 4px)',
              pl: 0,
              height: 'auto',
            },
          }}
        >
          {editing ? (
            <FormControl
              fullWidth
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                border: 'none',
              }}
              size={'small'}
            >
              <InputLabel
                id="label-select-car-part"
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  '&.Mui-focused': {
                    background: 'white',
                    color: colorsConfig.mainBlue,
                    boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                    overflow: 'visible',
                  },
                }}
              >
                {t('form_input_label_car_part')}
              </InputLabel>
              <Select
                labelId="label-select-car-part"
                id="demo-simple-select"
                value={carPart}
                label={t('form_input_label_car_part')}
                onChange={(e) => {
                  setCarPart(e.target.value)
                  setDamageType('')
                }}
                sx={{
                  borderRadius: '4px',
                  height: '38px',
                  fontSize: '14px',
                  border: 'none',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                  },
                }}
                size={'small'}
              >
                {carParts.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          ) : (
            <TextNormal>{carDamage.damage.part.name}</TextNormal>
          )}
        </Box>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            width: '21%',
            paddingLeft: '17px',
            height: '52px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '&.xs-only': {
              width: 'calc(50% - 4px)',
              pl: 0,
              height: 'auto',
            },
          }}
        >
          {editing ? (
            <Autocomplete
              required
              disablePortal
              id="damage-type"
              options={
                carPart
                  ? carPart.damages.map((item) => {
                      return item.name
                    })
                  : []
              }
              fullWidth
              value={damageType}
              onChange={(e, newVal) => {
                setDamageType(newVal)
              }}
              renderInput={(params) => (
                <TextFieldSmallEdible
                  {...params}
                  isAutocomplete
                  label="form_input_label_damage_type"
                  required
                />
              )}
            />
          ) : (
            <TextNormal>{carDamage.damage.name}</TextNormal>
          )}
        </Box>

        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            width: '41%',
            paddingLeft: '17px',
            paddingRight: '17px',
            '&.xs-only': {
              px: 0,
              width: '100%',
            },
          }}
        >
          <Gallery>
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: {
                  xs: 'flex-start',
                  md: 'flex-start',
                },
                alignItems: {
                  xs: 'center',
                  md: 'flex-start',
                },
                width: {
                  xs: '100%',
                  md: 'auto',
                },
                maxWidth: '100%',
                overflow: 'hidden',
                flexWrap: 'wrap',
                '&.xs-only': {
                  mt: '15px',
                  gap: '8px',
                },
              }}
            >
              {editing ? (
                <Box
                  sx={{
                    mr: '7px',
                    height: '52px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FileUploader
                    handleChange={handleSetPhotos}
                    name="file"
                    types={['JPG', 'PNG', 'jpeg', 'pdf']}
                    hoverTitle="Drop here"
                    label="Upload or drop all the photos here"
                    multiple
                    maxSize={10}
                    children={
                      <Button
                        sx={{
                          textTransform: 'none',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          px: '2px',
                        }}
                      >
                        <img src={IconUploadBlue} style={{ marginRight: '5px' }} alt="" />
                        <Typography
                          sx={{
                            fontFamily: '"Helvetica", sans-serif',
                            fontSize: '18px',
                            fontWeight: 700,
                            color: colorsConfig.mainBlue,
                          }}
                        >
                          {t('button_upload')}
                        </Typography>
                      </Button>
                    }
                  />
                </Box>
              ) : null}
              {carDamage.damagePhotos && carDamage.damagePhotos.length > 0 ? (
                <React.Fragment>
                  {carDamage.damagePhotos.map((damagePhoto, indexPhoto) => {
                    if (damagePhoto.type === 'application/pdf') {
                      return (
                        <Box
                          key={index}
                          sx={{
                            position: 'relative',
                            marginRight: '7px',
                            marginBottom: carDamage.damagePhotos.length > 4 ? '7px' : '0',
                          }}
                        >
                          <a
                            href={URL.createObjectURL(damagePhoto)}
                            download
                            className="link"
                            target="_blank"
                            style={{
                              width: '92px',
                              height: '52px',
                              borderRadius: '6px',
                              overflow: 'hidden',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img style={{ width: '50px' }} src={pdfIcon} alt="" />
                          </a>
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: '0px',
                              right: '0px',
                              width: '17px',
                              height: '17px',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              opacity: '1',
                              transition: '0.25s all ease-in-out',
                              '&:hover': {
                                transition: '0.25s all ease-in-out',
                                opacity: '1',
                              },
                            }}
                            color="error"
                            onClick={() => {
                              //removeFile(index)
                            }}
                          >
                            <Box
                              sx={{
                                width: '13px',
                                height: '13px',
                                borderRadius: '50%',
                                background: 'white',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <CancelRounded
                                sx={{
                                  fill: colorsConfig.mainRed,
                                  fontSize: '18px',
                                }}
                              />
                            </Box>
                          </IconButton>
                        </Box>
                      )
                    } else {
                      return (
                        <DamagePhoto key={indexPhoto} damagePhoto={damagePhoto} newDamage={true} />
                      )
                    }
                  })}
                </React.Fragment>
              ) : null}
              {carDamage.photos && carDamage.photos.length > 0 ? (
                <React.Fragment>
                  {carDamage.photos.map((damagePhoto, indexPhoto) => {
                    if (damagePhoto.photo.path?.includes('.pdf')) {
                      return (
                        <Box
                          sx={{
                            position: 'relative',
                            marginRight: '10px',
                          }}
                        >
                          <a
                            href={`${CDN_BASE_URL}${damagePhoto.photo.path}`}
                            download
                            className="link"
                            target="_blank"
                          >
                            <img style={{ width: '50px' }} src={pdfIcon} alt="" />
                          </a>
                        </Box>
                      )
                    } else {
                      return (
                        <DamagePhoto
                          key={indexPhoto}
                          damagePhoto={damagePhoto}
                          newDamage={false}
                          editing={editing}
                          handleDeleteDamagePhoto={handleDeleteDamagePhoto}
                        />
                      )
                    }
                  })}
                </React.Fragment>
              ) : null}
              {files.length > 0 ? (
                <>
                  {Array.from(files).map((photo, index) => {
                    if (photo.type === 'application/pdf') {
                      return (
                        <Box
                          key={index}
                          sx={{
                            position: 'relative',
                          }}
                        >
                          <a
                            href={URL.createObjectURL(photo)}
                            download
                            className="link"
                            target="_blank"
                          >
                            <img style={{ width: '100%' }} src={pdfIcon} alt="" />
                          </a>
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: '-7px',
                              right: '-7px',
                              width: '20px',
                              height: '20px',
                              opacity: '0.5',
                              transition: '0.25s all ease-in-out',
                              '&:hover': {
                                transition: '0.25s all ease-in-out',
                                opacity: '1',
                              },
                            }}
                            color="error"
                            onClick={() => {
                              removeFile(index)
                            }}
                          >
                            <HighlightOff />
                          </IconButton>
                        </Box>
                      )
                    } else {
                      return (
                        <Box
                          key={index}
                          sx={{
                            position: 'relative',
                            mr: '7px',
                            mb: '7px',
                          }}
                        >
                          <Box
                            sx={{
                              width: '92px',
                              height: '52px',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              overflow: 'hidden',
                              borderRadius: '6px',
                            }}
                          >
                            <img
                              src={URL.createObjectURL(photo)}
                              alt=""
                              style={{
                                width: '100%',
                                borderRadius: '6px',
                              }}
                            />
                          </Box>
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: '0px',
                              right: '0px',
                              width: '17px',
                              height: '17px',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              opacity: '1',
                              transition: '0.25s all ease-in-out',
                              '&:hover': {
                                transition: '0.25s all ease-in-out',
                                opacity: '1',
                              },
                            }}
                            color="error"
                            onClick={() => {
                              removeFile(index)
                            }}
                          >
                            <Box
                              sx={{
                                width: '13px',
                                height: '13px',
                                borderRadius: '50%',
                                background: 'white',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <CancelRounded
                                sx={{
                                  fill: colorsConfig.mainRed,
                                  fontSize: '18px',
                                }}
                              />
                            </Box>
                          </IconButton>
                        </Box>
                      )
                    }
                  })}
                </>
              ) : null}
            </Box>
          </Gallery>
        </Box>
      </Box>
      {showHint && showHint !== 'none' ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            px: '17px',
            mt: '15px',
            opacity: showHint === 'fixed' ? '0.6' : 1,
          }}
        >
          <img
            src={IconAttentionRed}
            style={{
              marginRight: '10px',
              filter: showHint === 'fixed' ? 'grayscale(200%)' : 'none',
            }}
            alt=""
          />
          <TextNormal>{currentDamageHint.hint}</TextNormal>
        </Box>
      ) : null}
      {carDamage.uploading ? null : (
        <>
          <IconButton
            sx={{
              width: '36px',
              height: '36px',
              position: 'absolute',
              bottom: 0,
              right: 0,
            }}
            onClick={() => {
              setEditing(false)
              setCurrentDamageHint('')
              setShowHint(false)
              handleDeleteDamage(index)
            }}
          >
            <img src={IconDeleteRed} alt="" />
          </IconButton>
          <Button
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              textTransform: 'none',
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={() => {
              handleSetEditing()
            }}
          >
            <img src={IconEdit} alt="" />
            <span
              style={{
                color: colorsConfig.mainBlue,
                fontSize: '14px',
                fontWeight: 400,
                fontFamily: '"Helvetica", sans-serif',
                marginLeft: '6px',
                position: 'relative',
                marginBottom: '-3px',
              }}
            >
              {editing ? t('button_save') : t('button_edit')}
            </span>
          </Button>
        </>
      )}
    </Box>
  )
}

export default CarDamageItem
