import React from 'react'
import { Box, Button, Typography, styled } from '@mui/material'
import { colorsConfig } from '../config/themeConfig'
import {
  menuConfig,
  operatorMenuConfig,
  expertMenuConfig,
  adminMenuConfig,
} from '../config/menuConfig'
import logo from '../assests/img/logo-white.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Sidebar = () => {
  const { t } = useTranslation()

  const MenuButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    '&::after': {
      content: '" "',
      position: 'absolute',
      display: 'block',
      height: '2px',
      width: 'calc(100% - 12px)',
      bottom: '4px',
      left: '6px',
      background: colorsConfig.mainGreen,
      opacity: 0,
      transformOrigin: '0% 50%',
      transition: '0.3s all ease-in-out',
      transform: 'scaleX(0)',
    },
    '&:hover': {
      '&::after': {
        opacity: 1,
        transition: '0.3s all ease-in-out',
        transform: 'scaleX(1)',
      },
    },
    '&.active': {
      '&::after': {
        opacity: 1,
        transform: 'scaleX(1)',
      },
    },
  }))

  const auctionCarsAmount = useSelector((state) => state.auth.auctionCarsAmount)
  const role = useSelector((state) => state.auth.role)

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Box
      sx={{
        width: '270px',
        minWidth: '270px',
        background: colorsConfig.buttonHover,
        paddingLeft: '30px',
        paddingTop: '18px',
        paddingBottom: '100px',
        display: {
          xs: 'none',
          lg: 'block',
        },
      }}
    >
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate('/virtual-garage')
        }}
      >
        <img style={{ width: '132px' }} src={logo} alt="Fleatify" />
      </Box>
      <Box
        sx={{
          mt: '44px',
          position: 'relative',
          ml: '-8px',
        }}
      >
        {role === 'COMPANY' ? (
          <>
            {menuConfig.map((menuItem, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    mb: '8px',
                    '&:last-child': {
                      mb: 0,
                    },
                  }}
                >
                  <Button
                    className={location.pathname === menuItem.link ? 'active' : ''}
                    sx={{
                      textTransform: 'none',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      textAlign: 'left',
                      opacity: menuItem?.comingSoon ? 0.7 : 1,
                      position: 'relative',
                      '&::after': {
                        content: '" "',
                        position: 'absolute',
                        display: 'block',
                        height: '2px',
                        width: 'calc(100% - 12px)',
                        bottom: '4px',
                        left: '6px',
                        background: menuItem?.comingSoon
                          ? colorsConfig.buttonYellow
                          : colorsConfig.mainGreen,
                        opacity: 0,
                        transformOrigin: '0% 50%',
                        transition: '0.3s all ease-in-out',
                        transform: 'scaleX(0)',
                      },
                      '&:hover': {
                        '&::after': {
                          opacity: 1,
                          transition: '0.3s all ease-in-out',
                          transform: 'scaleX(1)',
                        },
                      },
                      '&.active': {
                        '&::after': {
                          opacity: 1,
                          transform: 'scaleX(1)',
                        },
                      },
                    }}
                    onClick={() => {
                      navigate(menuItem.link)
                    }}
                  >
                    {menuItem?.icon ? (
                      <img
                        src={menuItem.icon}
                        style={{
                          verticalAlign: 'middle',
                          marginRight: '8px',
                          width: '14px',
                          height: '14px',
                        }}
                        alt={menuItem?.title}
                      />
                    ) : menuItem?.muiIcon ? (
                      menuItem?.muiIcon
                    ) : null}

                    <Typography
                      variant="body1"
                      sx={{
                        color: 'white',
                        fontWeight: 700,
                        fontSize: '16px',
                        fontFamily: '"Helvetica", sans-serif',
                        textAlign: 'left',
                      }}
                    >
                      {menuItem.trans ? t(menuItem.trans) : menuItem.title}
                    </Typography>
                    {menuItem.addons && menuItem.addons === 'auctionCarsAmount' ? (
                      <Typography
                        sx={{
                          ml: '3px',
                          minWidth: '28px',
                          height: '11px',
                          textAlign: 'center',
                          fontFamily: '"Helvetica", sans-serif',
                          fontSize: '10px',
                          fontWeight: 400,
                          background: colorsConfig.mainGreen,
                          borderRadius: '100px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        +{auctionCarsAmount !== null ? auctionCarsAmount : 0}
                      </Typography>
                    ) : null}
                  </Button>
                </Box>
              )
            })}
          </>
        ) : null}
        {role === 'OPERATOR' ? (
          <>
            {operatorMenuConfig.map((menuItem, index) => {
              if (menuItem.children) {
                return (
                  <Box
                    key={index}
                    className={
                      Boolean(
                        menuItem.children.filter(
                          (item) =>
                            item.link === location.pathname ||
                            location.pathname.includes(item.link),
                        )[0],
                      )
                        ? 'submenu-open'
                        : ''
                    }
                    sx={{
                      mb: '8px',
                      '&:last-child': {
                        mb: 0,
                      },
                      transition: '0.25s all ease-in-out',
                      '&:hover': {
                        transition: '0.25s all ease-in-out',
                        '& .submenu': {
                          mt: 0,
                          opacity: 1,
                          transition: '0.25s all ease-in-out',
                        },
                      },
                      '&.submenu-open': {
                        transition: '0.25s all ease-in-out',
                        '& .submenu': {
                          mt: 0,
                          transform: 'scaleY(1) translateY(0%)',
                          opacity: 1,
                          transition: '0.25s all ease-in-out',
                        },
                      },
                    }}
                  >
                    <Button
                      sx={{
                        textTransform: 'none',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        position: 'relative',
                        '&::after': {
                          content: '" "',
                          position: 'absolute',
                          display: 'block',
                          height: '2px',
                          width: 'calc(100% - 12px)',
                          bottom: '4px',
                          left: '6px',
                          background: colorsConfig.mainGreen,
                          opacity: 0,
                          transformOrigin: '0% 50%',
                          transition: '0.3s all ease-in-out',
                          transform: 'scaleX(0)',
                        },
                      }}
                    >
                      {menuItem?.icon ? (
                        <img
                          src={menuItem.icon}
                          style={{
                            verticalAlign: 'middle',
                            marginRight: '8px',
                            width: '14px',
                            height: '14px',
                          }}
                          alt={menuItem?.title}
                        />
                      ) : menuItem?.muiIcon ? (
                        menuItem?.muiIcon
                      ) : null}
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'white',
                          fontWeight: 700,
                          fontSize: '16px',
                          fontFamily: '"Helvetica", sans-serif',
                        }}
                      >
                        {menuItem.title}
                      </Typography>
                    </Button>
                    <Box
                      sx={{
                        overflow: 'hidden',
                        position: 'relative',
                      }}
                    >
                      <Box
                        className={'submenu'}
                        sx={{
                          opacity: '0.2',
                          pl: '15px',
                          position: 'relative',
                          mt: '-100%',
                          transition: '0.25s all ease-in-out',
                          '&::after': {
                            content: '" "',
                            display: 'block',
                            position: 'absolute',
                            height: 'calc(100% - 22px)',
                            width: '1px',
                            top: '11px',
                            left: '13px',
                            background: 'white',
                          },
                        }}
                      >
                        {menuItem.children.map((menuItemChild, index) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                mb: '6px',
                                '&:last-child': {
                                  mb: 0,
                                },
                              }}
                            >
                              <Button
                                className={
                                  location.pathname === menuItemChild.link ||
                                  location.pathname.includes(menuItemChild.link)
                                    ? 'active'
                                    : ''
                                }
                                sx={{
                                  textTransform: 'none',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  position: 'relative',
                                  '&::after': {
                                    content: '" "',
                                    position: 'absolute',
                                    display: 'block',
                                    height: '2px',
                                    width: 'calc(100% - 12px)',
                                    bottom: '4px',
                                    left: '6px',
                                    background: colorsConfig.mainGreen,
                                    opacity: 0,
                                    transformOrigin: '0% 50%',
                                    transition: '0.3s all ease-in-out',
                                    transform: 'scaleX(0)',
                                  },
                                  '&:hover': {
                                    '&::after': {
                                      opacity: 1,
                                      transition: '0.3s all ease-in-out',
                                      transform: 'scaleX(1)',
                                    },
                                  },
                                  '&.active': {
                                    '&::after': {
                                      opacity: 1,
                                      transform: 'scaleX(1)',
                                    },
                                  },
                                }}
                                onClick={() => {
                                  navigate(menuItemChild.link)
                                }}
                              >
                                <img
                                  src={menuItemChild.icon}
                                  style={{
                                    verticalAlign: 'middle',
                                    marginRight: '8px',
                                    width: '14px',
                                    height: '14px',
                                  }}
                                  alt=""
                                />
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: 'white',
                                    fontWeight: 400,
                                    fontSize: '15px',
                                    fontFamily: '"Helvetica", sans-serif',
                                  }}
                                >
                                  {menuItemChild.title}
                                </Typography>
                              </Button>
                            </Box>
                          )
                        })}
                      </Box>
                    </Box>
                  </Box>
                )
              }

              return (
                <Box
                  key={index}
                  sx={{
                    mb: '8px',
                    '&:last-child': {
                      mb: 0,
                    },
                  }}
                >
                  <Button
                    className={location.pathname === menuItem.link ? 'active' : ''}
                    sx={{
                      textTransform: 'none',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      position: 'relative',
                      '&::after': {
                        content: '" "',
                        position: 'absolute',
                        display: 'block',
                        height: '2px',
                        width: 'calc(100% - 12px)',
                        bottom: '4px',
                        left: '6px',
                        background: colorsConfig.mainGreen,
                        opacity: 0,
                        transformOrigin: '0% 50%',
                        transition: '0.3s all ease-in-out',
                        transform: 'scaleX(0)',
                      },
                      '&:hover': {
                        '&::after': {
                          opacity: 1,
                          transition: '0.3s all ease-in-out',
                          transform: 'scaleX(1)',
                        },
                      },
                      '&.active': {
                        '&::after': {
                          opacity: 1,
                          transform: 'scaleX(1)',
                        },
                      },
                    }}
                    onClick={() => {
                      navigate(menuItem.link)
                    }}
                  >
                    {menuItem?.icon ? (
                      <img
                        src={menuItem.icon}
                        style={{
                          verticalAlign: 'middle',
                          marginRight: '8px',
                          width: '14px',
                          height: '14px',
                        }}
                        alt={menuItem?.title}
                      />
                    ) : menuItem?.muiIcon ? (
                      menuItem?.muiIcon
                    ) : null}
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'white',
                        fontWeight: 700,
                        fontSize: '16px',
                        fontFamily: '"Helvetica", sans-serif',
                      }}
                    >
                      {menuItem.title}
                    </Typography>
                    {menuItem.addons && menuItem.addons === 'auctionCarsAmount' ? (
                      <Typography
                        sx={{
                          ml: '3px',
                          minWidth: '28px',
                          height: '11px',
                          textAlign: 'center',
                          fontFamily: '"Helvetica", sans-serif',
                          fontSize: '10px',
                          fontWeight: 400,
                          background: colorsConfig.mainGreen,
                          borderRadius: '100px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        +{auctionCarsAmount !== null ? auctionCarsAmount : 0}
                      </Typography>
                    ) : null}
                  </Button>
                </Box>
              )
            })}
          </>
        ) : null}
        {role === 'EXPERT' ? (
          <>
            {expertMenuConfig.map((menuItem, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    mb: '8px',
                    '&:last-child': {
                      mb: 0,
                    },
                  }}
                >
                  <Button
                    className={location.pathname === menuItem.link ? 'active' : ''}
                    sx={{
                      textTransform: 'none',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      position: 'relative',
                      '&::after': {
                        content: '" "',
                        position: 'absolute',
                        display: 'block',
                        height: '2px',
                        width: 'calc(100% - 12px)',
                        bottom: '4px',
                        left: '6px',
                        background: colorsConfig.mainGreen,
                        opacity: 0,
                        transformOrigin: '0% 50%',
                        transition: '0.3s all ease-in-out',
                        transform: 'scaleX(0)',
                      },
                      '&:hover': {
                        '&::after': {
                          opacity: 1,
                          transition: '0.3s all ease-in-out',
                          transform: 'scaleX(1)',
                        },
                      },
                      '&.active': {
                        '&::after': {
                          opacity: 1,
                          transform: 'scaleX(1)',
                        },
                      },
                    }}
                    onClick={() => {
                      navigate(menuItem.link)
                    }}
                  >
                    <img
                      src={menuItem.icon}
                      style={{
                        verticalAlign: 'middle',
                        marginRight: '8px',
                        width: '14px',
                        height: '14px',
                      }}
                      alt=""
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'white',
                        fontWeight: 700,
                        fontSize: '16px',
                        fontFamily: '"Helvetica", sans-serif',
                      }}
                    >
                      {menuItem.title}
                    </Typography>
                    {menuItem.addons && menuItem.addons === 'auctionCarsAmount' ? (
                      <Typography
                        sx={{
                          ml: '3px',
                          minWidth: '28px',
                          height: '11px',
                          textAlign: 'center',
                          fontFamily: '"Helvetica", sans-serif',
                          fontSize: '10px',
                          fontWeight: 400,
                          background: colorsConfig.mainGreen,
                          borderRadius: '100px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        +{auctionCarsAmount !== null ? auctionCarsAmount : 0}
                      </Typography>
                    ) : null}
                  </Button>
                </Box>
              )
            })}
          </>
        ) : null}
        {role === 'ADMIN' ? (
          <>
            {adminMenuConfig.map((menuItem, index) => {
              if (menuItem.children) {
                return (
                  <Box
                    key={index}
                    className={
                      Boolean(
                        menuItem.children.filter(
                          (item) =>
                            item.link === location.pathname ||
                            location.pathname.includes(item.link),
                        )[0],
                      )
                        ? 'submenu-open'
                        : ''
                    }
                    sx={{
                      mb: '8px',
                      '&:last-child': {
                        mb: 0,
                      },
                      transition: '0.25s all ease-in-out',
                      '&:hover': {
                        transition: '0.25s all ease-in-out',
                        '& .submenu': {
                          mt: 0,
                          opacity: 1,
                          transition: '0.25s all ease-in-out',
                        },
                      },
                      '&.submenu-open': {
                        transition: '0.25s all ease-in-out',
                        '& .submenu': {
                          mt: 0,
                          transform: 'scaleY(1) translateY(0%)',
                          opacity: 1,
                          transition: '0.25s all ease-in-out',
                        },
                      },
                    }}
                  >
                    <Button
                      sx={{
                        textTransform: 'none',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        position: 'relative',
                        '&::after': {
                          content: '" "',
                          position: 'absolute',
                          display: 'block',
                          height: '2px',
                          width: 'calc(100% - 12px)',
                          bottom: '4px',
                          left: '6px',
                          background: colorsConfig.mainGreen,
                          opacity: 0,
                          transformOrigin: '0% 50%',
                          transition: '0.3s all ease-in-out',
                          transform: 'scaleX(0)',
                        },
                      }}
                    >
                      {menuItem.icon ? (
                        <img
                          src={menuItem.icon}
                          style={{
                            verticalAlign: 'middle',
                            marginRight: '8px',
                            width: '14px',
                            height: '14px',
                          }}
                          alt=""
                        />
                      ) : menuItem.muiIcon ? (
                        <>{menuItem.muiIcon}</>
                      ) : null}
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'white',
                          fontWeight: 700,
                          fontSize: '16px',
                          fontFamily: '"Helvetica", sans-serif',
                        }}
                      >
                        {menuItem.title}
                      </Typography>
                    </Button>
                    <Box
                      sx={{
                        overflow: 'hidden',
                        position: 'relative',
                      }}
                    >
                      <Box
                        className={'submenu'}
                        sx={{
                          opacity: '0.2',
                          pl: '15px',
                          position: 'relative',
                          mt: '-110%',
                          transition: '0.25s all ease-in-out',
                          '&::after': {
                            content: '" "',
                            display: 'block',
                            position: 'absolute',
                            height: 'calc(100% - 22px)',
                            width: '1px',
                            top: '11px',
                            left: '13px',
                            background: 'white',
                          },
                        }}
                      >
                        {menuItem.children.map((menuItemChild, index) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                mb: '6px',
                                '&:last-child': {
                                  mb: 0,
                                },
                              }}
                            >
                              <Button
                                className={
                                  location.pathname === menuItemChild.link ||
                                  location.pathname.includes(menuItemChild.link)
                                    ? 'active'
                                    : ''
                                }
                                sx={{
                                  textTransform: 'none',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  position: 'relative',
                                  '&::after': {
                                    content: '" "',
                                    position: 'absolute',
                                    display: 'block',
                                    height: '2px',
                                    width: 'calc(100% - 12px)',
                                    bottom: '4px',
                                    left: '6px',
                                    background: colorsConfig.mainGreen,
                                    opacity: 0,
                                    transformOrigin: '0% 50%',
                                    transition: '0.3s all ease-in-out',
                                    transform: 'scaleX(0)',
                                  },
                                  '&:hover': {
                                    '&::after': {
                                      opacity: 1,
                                      transition: '0.3s all ease-in-out',
                                      transform: 'scaleX(1)',
                                    },
                                  },
                                  '&.active': {
                                    '&::after': {
                                      opacity: 1,
                                      transform: 'scaleX(1)',
                                    },
                                  },
                                }}
                                onClick={() => {
                                  navigate(menuItemChild.link)
                                }}
                              >
                                {menuItemChild.icon ? (
                                  <img
                                    src={menuItem.icon}
                                    style={{
                                      verticalAlign: 'middle',
                                      marginRight: '8px',
                                      width: '14px',
                                      height: '14px',
                                    }}
                                    alt=""
                                  />
                                ) : menuItemChild.muiIcon ? (
                                  <>{menuItemChild.muiIcon}</>
                                ) : null}
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: 'white',
                                    fontWeight: 400,
                                    fontSize: '15px',
                                    fontFamily: '"Helvetica", sans-serif',
                                  }}
                                >
                                  {menuItemChild.title}
                                </Typography>
                              </Button>
                            </Box>
                          )
                        })}
                      </Box>
                    </Box>
                  </Box>
                )
              }

              return (
                <Box
                  key={index}
                  sx={{
                    mb: '8px',
                    '&:last-child': {
                      mb: 0,
                    },
                  }}
                >
                  <Button
                    className={location.pathname === menuItem.link ? 'active' : ''}
                    sx={{
                      textTransform: 'none',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      position: 'relative',
                      '&::after': {
                        content: '" "',
                        position: 'absolute',
                        display: 'block',
                        height: '2px',
                        width: 'calc(100% - 12px)',
                        bottom: '4px',
                        left: '6px',
                        background: colorsConfig.mainGreen,
                        opacity: 0,
                        transformOrigin: '0% 50%',
                        transition: '0.3s all ease-in-out',
                        transform: 'scaleX(0)',
                      },
                      '&:hover': {
                        '&::after': {
                          opacity: 1,
                          transition: '0.3s all ease-in-out',
                          transform: 'scaleX(1)',
                        },
                      },
                      '&.active': {
                        '&::after': {
                          opacity: 1,
                          transform: 'scaleX(1)',
                        },
                      },
                    }}
                    onClick={() => {
                      navigate(menuItem.link)
                    }}
                  >
                    {menuItem.icon ? (
                      <img
                        src={menuItem.icon}
                        style={{
                          verticalAlign: 'middle',
                          marginRight: '8px',
                          width: '14px',
                          height: '14px',
                        }}
                        alt=""
                      />
                    ) : menuItem.muiIcon ? (
                      <>{menuItem.muiIcon}</>
                    ) : null}
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'white',
                        fontWeight: 700,
                        fontSize: '16px',
                        fontFamily: '"Helvetica", sans-serif',
                      }}
                    >
                      {menuItem.title}
                    </Typography>
                    {menuItem.addons && menuItem.addons === 'auctionCarsAmount' ? (
                      <Typography
                        sx={{
                          ml: '3px',
                          minWidth: '28px',
                          height: '11px',
                          textAlign: 'center',
                          fontFamily: '"Helvetica", sans-serif',
                          fontSize: '10px',
                          fontWeight: 400,
                          background: colorsConfig.mainGreen,
                          borderRadius: '100px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        +{auctionCarsAmount !== null ? auctionCarsAmount : 0}
                      </Typography>
                    ) : null}
                  </Button>
                </Box>
              )
            })}
          </>
        ) : null}
      </Box>
      {role === 'COMPANY' ? (
        <Box
          sx={{
            mt: '60px',
            position: 'relative',
            ml: '-8px',
          }}
        >
          {/*<MenuButton*/}
          {/*  className={location.pathname === '/profile-management' ? 'active' : ''}*/}
          {/*  onClick={() => {*/}
          {/*    navigate('/profile-management')*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Typography*/}
          {/*    variant="body1"*/}
          {/*    sx={{*/}
          {/*      color: 'white',*/}
          {/*      fontWeight: 700,*/}
          {/*      fontSize: '16px',*/}
          {/*      fontFamily: '"Helvetica", sans-serif',*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {t('sidebar.account_settings')}*/}
          {/*  </Typography>*/}
          {/*</MenuButton>*/}
          <MenuButton
            className={location.pathname === '/help' ? 'active' : ''}
            onClick={() => {
              navigate('/help')
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: 'white',
                fontWeight: 700,
                fontSize: '16px',
                fontFamily: '"Helvetica", sans-serif',
              }}
            >
              {t('sidebar_fleatify_help')}
            </Typography>
          </MenuButton>
        </Box>
      ) : null}
    </Box>
  )
}

export default Sidebar
