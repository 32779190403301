import React, { useState } from 'react'
import { Box, Tabs, Tab, useMediaQuery } from '@mui/material'
import './Damages.scss'
import CarDamagesZone from './CarDamagesZone'
import carDummy1 from '../../../assests/img/damages/front-right-2.png'
import carDummy2 from '../../../assests/img/damages/back-left-2.png'
import carDummy4 from '../../../assests/img/damages/car-damages-3.jpg'
import { carPartsInterior, carPartsFrontRight, carPartsBackLeft } from './CarParts'
import { colorsConfig } from '../../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const AppraisalDamages = ({ carId }) => {
  const { t } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const steps = [
    {
      id: 1,
      label: xsOnly ? t('tab_front') : t('tab_front_right_view'),
      zone: 'exterior_front',
      carDummy: carDummy1,
      imgWidth: 1092,
      imgHeight: 615,
      carPartsCoords: carPartsFrontRight,
    },
    {
      id: 2,
      label: xsOnly ? t('tab_back') : t('tab_back_left_view'),
      zone: 'exterior_back',
      carDummy: carDummy2,
      imgWidth: 1092,
      imgHeight: 630,
      carPartsCoords: carPartsBackLeft,
    },
    {
      id: 3,
      label: t('tab_interior'),
      zone: 'interior',
      carDummy: carDummy4,
      imgWidth: 800,
      imgHeight: 800,
      carPartsCoords: carPartsInterior,
    },
  ]

  const [step, setStep] = useState(steps[0])
  const [tab, setTab] = useState(0)

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
    setStep(steps[newValue])
  }

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
        mt: '60px',
        borderRadius: '6px',
        padding: '22px',
        '&.xs-only': {
          px: '21px',
          pt: '15px',
          pb: '26px',
          mt: '45px',
        },
      }}
    >
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        variant="scrollable"
        sx={{
          '& .MuiTabs-indicator': {
            background: colorsConfig.mainGreen,
          },
          '& .MuiTab-textColorPrimary': {
            color: colorsConfig.mainBlack,
            textTransform: 'none',
            fontFamily: '"HelveticaNeue", sans-serif',
            fontSize: '16px',
            fontWeight: 500,
            px: {
              xs: '10px',
              md: '30px',
            },
          },
          '& .MuiButtonBase-root': {
            background: colorsConfig.bgLightBlue,
          },
          '& .Mui-selected': {
            color: colorsConfig.mainBlack,
          },
        }}
      >
        {steps.map((stepItem, index) => {
          return (
            <Tab
              key={index}
              label={stepItem.label}
              sx={{
                flex: '1',
                px: '15px',
              }}
            />
          )
        })}
      </Tabs>
      {steps.map((stepItem, index) => {
        if (stepItem.id === step.id) {
          return (
            <CarDamagesZone
              key={index}
              zone={stepItem.zone}
              carDummy={stepItem.carDummy}
              imgWidth={stepItem.imgWidth}
              imgHeight={stepItem.imgHeight}
              carPartsCoords={stepItem.carPartsCoords}
            />
          )
        }
      })}
    </Box>
  )
}

export default AppraisalDamages
