import React, { useState } from 'react'
import UITableActionsList from '../../../../ui/UITable/UITableActionsList'
import ApproveRestitutionRequestModal from '../Modals/ApproveRestitutionRequestModal'
import { useDispatch } from 'react-redux'
import {
  archiveRviPolicy,
  getClosedPolicies,
  getLinkedPolicies,
  getPoliciesInRestitution,
  getPoliciesStatusCount,
  getUnlinkedPolicies,
  rviApproveRestitution,
  rviCloseRestitution,
} from '../../../../store/features/rviOperator/rviOperator.actions'
import CloseRestitutionModal from '../Modals/CloseRestitutionModal'
import { toast } from 'react-toastify'
import CancelPolicyModal from '../Modals/CancelPolicyModal'
import CloseDueToEndOfContractModal from '../Modals/CloseDueToEndOfContractModal'
import { closeDueToEndOfContract } from '../../../../store/features/rviPolicy/rviPolicy.actions'
import CloseRunningContractModal from '../Modals/CloseRunningContractModal'

const RestitutionInProgressActionItems = ({ data }) => {
  const policy = data?.initialData

  const dispatch = useDispatch()

  const [approveRestitutionModal, setApproveRestitutionModal] = useState(false)
  const [closeRestitutionModal, setCloseRestitutionModal] = useState(false)
  const [cancelPolicyModal, setCancelPolicyModal] = useState(false)
  const [closeDueToEndOfContractModal, setCloseDueToEndOfContractModal] = useState(false)
  const [closeRunningContractModal, setCloseRunningContractModal] = useState(false)

  const handleApproveRestitution = async () => {
    const res = await dispatch(rviApproveRestitution({ policyId: policy?.id }))
    if (rviApproveRestitution.fulfilled.match(res)) {
      toast.success('Restitution process has started')
      setApproveRestitutionModal(false)
      dispatch(getPoliciesInRestitution())
    }
  }

  const handleCloseRestitution = async () => {
    const res = await dispatch(rviCloseRestitution({ policyId: policy?.id }))
    if (rviCloseRestitution.fulfilled.match(res)) {
      toast.success('Restitution process has been closed, policy has been moved to archive')
      setCloseRestitutionModal(false)
      dispatch(getPoliciesStatusCount())
      dispatch(getPoliciesInRestitution())
      dispatch(getClosedPolicies())
    }
  }

  const onCancelPolicy = () => {
    dispatch(getPoliciesInRestitution())
    setCancelPolicyModal(false)
  }

  const handleClosePolicyDueToEndOfContract = async () => {
    console.log(data?.initialData?.id)
    const res = await dispatch(closeDueToEndOfContract({ policyId: data?.initialData?.id }))
    if (closeDueToEndOfContract.fulfilled.match(res)) {
      toast.success('Policy has been closed successfully')
      setCloseDueToEndOfContractModal(false)
      dispatch(getPoliciesInRestitution())
    }
  }

  const onClosePolicy = () => {
    dispatch(getLinkedPolicies())
    dispatch(getPoliciesStatusCount())
    setCloseRunningContractModal(false)
  }

  const actions = [
    {
      name: 'Start restitution',
      handleAction: () => {
        setApproveRestitutionModal(true)
      },
      workflowStates: ['restitution_started'],
    },
    {
      name: 'Close restitution',
      handleAction: () => {
        setCloseRestitutionModal(true)
      },
      workflowStates: ['restitution_in_progress'],
    },
    {
      name: 'Close running contract',
      handleAction: () => setCloseRunningContractModal(true),
      workflowStates: ['restitution_started', 'restitution_in_progress'],
    },
    {
      name: 'Cancel',
      handleAction: () => {
        setCancelPolicyModal(true)
      },
      workflowStates: ['restitution_started', 'restitution_in_progress'],
    },
  ]

  const filteredActions = actions.filter((action) =>
    action.workflowStates.find((item) => item === policy?.currentWorkflowState),
  )

  return (
    <>
      <UITableActionsList actions={filteredActions} />
      <ApproveRestitutionRequestModal
        open={approveRestitutionModal}
        setOpen={setApproveRestitutionModal}
        handleAction={handleApproveRestitution}
      />
      <CloseRestitutionModal
        open={closeRestitutionModal}
        setOpen={setCloseRestitutionModal}
        handleAction={handleCloseRestitution}
      />
      {cancelPolicyModal && (
        <CancelPolicyModal
          open={cancelPolicyModal}
          setOpen={setCancelPolicyModal}
          data={data?.initialData}
          onClose={onCancelPolicy}
        />
      )}
      {closeDueToEndOfContractModal && (
        <CloseDueToEndOfContractModal
          open={closeDueToEndOfContractModal}
          setOpen={setCloseDueToEndOfContractModal}
          data={data?.initialData}
          handleAction={handleClosePolicyDueToEndOfContract}
        />
      )}
      {closeRunningContractModal && (
        <CloseRunningContractModal
          open={closeRunningContractModal}
          setOpen={setCloseRunningContractModal}
          data={data?.initialData}
          onClose={onClosePolicy}
        />
      )}
    </>
  )
}

export default RestitutionInProgressActionItems
