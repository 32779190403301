import React, { useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Typography,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  useMediaQuery,
} from '@mui/material'
import { CancelRounded } from '@mui/icons-material'
import { FileUploader } from 'react-drag-drop-files'
import { useSelector, useDispatch } from 'react-redux'
import { addDamage, setNewDamageId } from '../../../store/features/carSlice/carSlice'
import { privateMultipartRequest, privateRequest } from '../../../utils/request'
import { API_ENDPOINTS, FILE_TYPES } from '../../../config/default'
import { useParams } from 'react-router-dom'
import pdfIcon from '../../../assests/img/icons/pdf-icon.png'
import TextFieldSmall from '../../../ui/TextFieldSmall'
import { colorsConfig } from '../../../config/themeConfig'
import IconUploadBlue from '../../../assests/img/icons/icon-upload-blue.svg'
import { toast } from 'react-toastify'
import { generateUID } from '../../../utils/generateUID'
import { useTranslation } from 'react-i18next'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'

const AddCarDamage = ({
  setCarPart,
  carPart,
  carParts,
  zone,
  clearArea,
  setImgDimensions,
  setMapperKey,
}) => {
  const { t } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const carId = useSelector((state) => state.car.carData.id)

  const carDamages = useSelector((state) => state.car.appraisal.damages)

  const dispatch = useDispatch()

  const params = {
    id: carId,
  }

  const [damageType, setDamageType] = useState('')
  const [files, setFiles] = useState([])

  const handleSetPhotos = (newFiles) => {
    if (files.length > 0) {
      const updatedFiles = files.concat(Array.from(newFiles))
      setFiles(updatedFiles)
    } else {
      setFiles(Array.from(newFiles))
    }
  }

  const removeFile = (fileIndex) => {
    const updatedFiles = files.filter((item, index) => {
      return index !== fileIndex
    })
    setFiles(updatedFiles)
  }

  const handleSaveDamage = async () => {
    const carPartId = carPart['@id']
    const damageable = carPart.damages.filter((item) => item.name === damageType)[0]['@id']
    const newDamage = {
      deleted: false,
      newDamage: true,
      carPartIri: carPartId,
      damageable: damageable,
      damageType: 'inherit',
      damagePhotos: files,
      damageDescription: 'string',
      damage: {
        name: damageType,
        part: {
          name: carPart?.name,
          zone: zone,
        },
      },
      updated: true,
      uploading: true,
    }
    const newDamages = Array.from(carDamages)
    const index = newDamages.length
    newDamages.push(newDamage)
    dispatch(addDamage(newDamages))
    const damagePhotos = Array.from(files).map((item) => {
      const formDataFile = new FormData()
      formDataFile.append('file', item)
      formDataFile.append('type', FILE_TYPES.damage)
      return privateMultipartRequest.post(API_ENDPOINTS.file, formDataFile)
    })
    const damagePhotosIris = await Promise.all(damagePhotos).then((result) => {
      return result.map((item) => {
        return { photo: item.data['@id'] }
      })
    })
    const formData = {
      description: 'none',
      photos: damagePhotosIris,
      damage: damageable,
    }
    privateRequest.post(API_ENDPOINTS.carDamages(params.id), formData).then((response) => {
      dispatch(
        setNewDamageId({
          '@id': response.data['@id'],
          id: response.data.id,
          index: index,
          photos: response.data.photos,
          damage: {
            name: damageType,
            part: {
              name: carPart?.name,
              zone: zone,
            },
          },
        }),
      )
      setCarPart('')
      setDamageType('')
      setFiles([])
      clearArea()
      setMapperKey(generateUID())
    })
  }

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        py: '17px',
        borderRadius: '6px',
        border: '1px solid',
        borderColor: colorsConfig.lightGray,
        '&.xs-only': {
          pt: '15px',
          pb: '11px',
          px: '8px',
        },
      }}
    >
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          '&.xs-only': {
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          },
        }}
      >
        <Box
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            width: '23%',
            pl: '17px',
            '&.xs-only': {
              width: 'calc(50% - 4px)',
              pl: 0,
            },
            '&.tablet-only': {
              width: '35%',
            },
          }}
        >
          <FormControl
            fullWidth
            sx={{
              color: colorsConfig.iconGray,
              fontWeight: 400,
              fontSize: '14px',
              border: 'none',
            }}
            size={'small'}
          >
            <InputLabel
              id="label-select-car-part"
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                '&.Mui-focused': {
                  background: 'white',
                  color: colorsConfig.mainBlue,
                  boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                  overflow: 'visible',
                },
              }}
              onClick={() => setImgDimensions()}
            >
              {t('form_input_label_car_part')}
            </InputLabel>
            <Select
              labelId="label-select-car-part"
              id="demo-simple-select"
              value={carPart}
              label="Car Part"
              onChange={(e) => {
                setCarPart(e.target.value)
                if (!xsOnly) clearArea()
                setDamageType(null)
              }}
              sx={{
                borderRadius: '4px',
                height: '38px',
                fontSize: '14px',
                border: 'none',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                },
              }}
              size={'small'}
            >
              {carParts.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item?.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
        <Box
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            paddingLeft: '17px',
            width: '21%',
            '&.xs-only': {
              width: 'calc(50% - 4px)',
              pl: 0,
            },
            '&.tablet-only': {
              width: '35%',
            },
          }}
        >
          <Autocomplete
            required
            disablePortal
            id="damage-type"
            options={
              carPart
                ? carPart?.damages.map((item) => {
                    return item?.name
                  })
                : []
            }
            fullWidth
            value={damageType}
            onChange={(e, newVal) => {
              setDamageType(newVal)
            }}
            renderInput={(params) => (
              <TextFieldSmallEdible
                {...params}
                isAutocomplete
                label="form_input_label_damage_type"
                required
              />
            )}
          />
        </Box>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            paddingLeft: '17px',
            width: '41%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            '&.xs-only': {
              width: '100%',
              pl: 0,
              mt: '15px',
            },
          }}
        >
          <FileUploader
            handleChange={handleSetPhotos}
            name="file"
            types={[
              'jpg',
              'jpeg',
              'png',
              'gif',
              'bmp',
              'tiff',
              'tif',
              'webp',
              'svg',
              'heic',
              'pdf',
            ]}
            onTypeError={(e) => toast.error('Unsupported image type')}
            hoverTitle="Drop here"
            label="Upload or drop all the photos here"
            multiple
            maxSize={10}
            children={
              <Button
                sx={{
                  textTransform: 'none',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={IconUploadBlue} style={{ marginRight: '5px' }} alt="" />
                <Typography
                  sx={{
                    fontFamily: '"Helvetica", sans-serif',
                    fontSize: '18px',
                    fontWeight: 700,
                    color: colorsConfig.mainBlue,
                  }}
                >
                  {t('button_upload')}
                </Typography>
              </Button>
            }
          />
        </Box>
        {!xsOnly ? (
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              width: '15%',
              minWidth: '140px',
              pr: '17px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              '&.xs-only': {
                width: '100%',
              },
            }}
          >
            <Button
              sx={{
                textTransform: 'none',
                color: colorsConfig.mainBlue,
                fontSize: '16px',
                fontWeight: 700,
                whiteSpace: 'nowrap',
                position: 'relative',
                '&::after': {
                  content: '" "',
                  display: 'block',
                  position: 'absolute',
                  width: '88%',
                  left: '6%',
                  bottom: '10px',
                  height: '1px',
                  background: colorsConfig.mainBlue,
                },
                '&.Mui-disabled': {
                  '&::after': {
                    background: colorsConfig.lightGray,
                  },
                },
              }}
              onClick={() => {
                handleSaveDamage()
              }}
              disabled={files.length === 0 || !carPart || !damageType}
            >
              {t('button_save_damage')}
            </Button>
          </Box>
        ) : null}
      </Box>
      {files.length > 0 ? (
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            px: '17px',
            mt: '25px',
            '&.xs-only': {
              flexWrap: 'wrap',
              mt: '15px',
              px: 0,
              gap: '8px',
            },
          }}
        >
          {Array.from(files).map((photo, index) => {
            if (photo.type === 'application/pdf') {
              return (
                <Box
                  className={xsOnly ? 'xs-only' : ''}
                  key={index}
                  sx={{
                    position: 'relative',
                    height: '52px',
                    mr: '15px',
                    '&:last-child': {
                      mr: 0,
                    },
                    '&.xs-only': {
                      mr: 0,
                      width: 'calc(50% - 4px)',
                    },
                  }}
                >
                  <a href={URL.createObjectURL(photo)} download className="link" target="_blank">
                    <img
                      style={{
                        height: '52px',
                        width: 'auto',
                      }}
                      src={pdfIcon}
                      alt=""
                    />
                  </a>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: '-7px',
                      right: '-7px',
                      width: '17px',
                      height: '17px',
                      opacity: '1',
                      transition: '0.25s all ease-in-out',
                      '&:hover': {
                        transition: '0.25s all ease-in-out',
                        opacity: '1',
                      },
                    }}
                    color="error"
                    onClick={() => {
                      removeFile(index)
                    }}
                  >
                    <Box
                      sx={{
                        width: '13px',
                        height: '13px',
                        borderRadius: '50%',
                        background: 'white',
                      }}
                    >
                      <CancelRounded />
                    </Box>
                  </IconButton>
                </Box>
              )
            } else {
              return (
                <Box
                  className={xsOnly ? 'xs-only' : ''}
                  key={index}
                  sx={{
                    position: 'relative',
                    mr: '15px',
                    '&.xs-only': {
                      mr: 0,
                      width: 'calc(50% - 4px)',
                    },
                  }}
                >
                  <Box
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      width: '92px',
                      height: '52px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                      borderRadius: '6px',
                      '&.xs-only': {
                        width: '100%',
                        height: '80px',
                      },
                    }}
                  >
                    <img
                      src={URL.createObjectURL(photo)}
                      alt=""
                      style={{
                        width: '100%',
                        borderRadius: '6px',
                      }}
                    />
                  </Box>
                  <IconButton
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      position: 'absolute',
                      top: '0px',
                      right: '0px',
                      width: '17px',
                      height: '17px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      opacity: '1',
                      transition: '0.25s all ease-in-out',
                      '&:hover': {
                        transition: '0.25s all ease-in-out',
                        opacity: '1',
                      },
                      '&.xs-only': {
                        width: '25px',
                        height: '25px',
                        top: '-7px',
                        right: '-8px',
                      },
                    }}
                    color="error"
                    onClick={() => {
                      removeFile(index)
                    }}
                  >
                    <Box
                      className={xsOnly ? 'xs-only' : ''}
                      sx={{
                        width: '13px',
                        height: '13px',
                        borderRadius: '50%',
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&.xs-only': {
                          width: '18px',
                          height: '18px',
                        },
                      }}
                    >
                      <CancelRounded
                        className={xsOnly ? 'xs-only' : ''}
                        sx={{
                          fill: colorsConfig.mainRed,
                          fontSize: '18px',
                          '&.xs-only': {
                            fontSize: '22px',
                          },
                        }}
                      />
                    </Box>
                  </IconButton>
                </Box>
              )
            }
          })}
        </Box>
      ) : null}
      {xsOnly ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              textTransform: 'none',
              color: colorsConfig.mainBlue,
              fontSize: '16px',
              fontWeight: 700,
              whiteSpace: 'nowrap',
              position: 'relative',
              '&::after': {
                content: '" "',
                display: 'block',
                position: 'absolute',
                width: '88%',
                left: '6%',
                bottom: '10px',
                height: '1px',
                background: colorsConfig.mainBlue,
              },
              '&.Mui-disabled': {
                '&::after': {
                  background: colorsConfig.lightGray,
                },
              },
            }}
            onClick={() => {
              handleSaveDamage()
            }}
            disabled={files.length === 0 || !carPart || !damageType}
          >
            {t('button_save_damage')}
          </Button>
        </Box>
      ) : null}
    </Box>
  )
}

export default AddCarDamage
