import React, { useEffect, useState } from 'react'
import CollapseBlock from '../../../../ui/Accordions/CollapseBlock'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLoanCalculatorOpened,
  updateEmploymentStatus,
} from '../../../../store/features/financeCar'
import { FormControl, FormControlLabel, Grid, RadioGroup, Stack } from '@mui/material'
import CustomRadio from '../../../../ui/CustomRadio'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import IconVinAutofill from '../../../../assests/img/icons/icon-car-autofilled.svg'
import ButtonTransparent from '../../../../ui/Buttons/ButtonTransparent'
import ButtonPlain from '../../../../ui/Buttons/ButtonPlain'
import UIButtonPlainGreen from '../../../../ui/UIButtons/UIButtonPlainGreen'
import FormCarSlider from '../../../../components/Forms/FormComponents/FormCarSlider'
import FormMoneySlider from '../../../../components/Forms/FormComponents/FormMoneySlider'
import UITextBodySmall from '../../../../ui/UIText/UITextBodySmall'
import SimpleCheckbox from '../../../../ui/SimpleCheckbox'
import FormTermsSelect from '../../../../components/Forms/FormComponents/FormTermsSelect/FormTermsSelect'
import FormPackages from '../../../../components/Forms/FormComponents/FormPackages/FormPackages'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import UIFormInputSelect from '../../../../ui/UIFormComponents/UIFormInputSelect/UIFormInputSelect'
import UIInputAdornmentText from '../../../../ui/UIFormComponents/UIInputAdornmentText'
import FormDurationSlider from '../../../../components/Forms/FormComponents/FormDurationSlider'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import { CALCULATION_METHOD } from '../../../../utils/constants/enums.constants'
import { calculateRV } from '../../../../store/features/financing/financing.actions'

const getDurationOptions = () => {
  const options = []
  for (let i = 12; i < 61; i++) {
    options.push({
      label: `${i} months`,
      value: i,
    })
  }
  return options
}

const LoanCalculator = () => {
  const selectedTab = useSelector((state) => state.carFinance.selectedTab)
  const loanCalculatorParams = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanCalculatorParams,
  )
  const expanded = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanCalculatorOpened,
  )
  const saved = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanCalculatorSaved,
  )
  const car = useSelector((state) => state.car.car)

  const [formData, setFormData] = useState({
    sellingPrice: '',
    downPayment: '',
    annualMileage: 2,
    rvi: 0,
    customCoverage: false,
    selectedPackage: 'fastest',
    customOptions: {
      rvi: false,
      warranty: false,
      casco: false,
      ppi: false,
    },
    customTerm: false,
    selectedTerm: 12,
    duration: 24,
  })

  const slides = [
    {
      price: '10000',
      term: 12,
    },
    {
      price: '10000',
      term: 24,
    },
    {
      price: '10000',
      term: 36,
    },
    {
      price: '10000',
      term: 48,
    },
    {
      price: '10000',
      term: 60,
    },
  ]

  const dispatch = useDispatch()

  const handleCalculateRV = async () => {
    const hasGrossPrice = car?.grossPriceWithoutOptions
    const data = {
      term: parseInt(formData.duration),
      carAge: car?.carAge,
      mileage: car?.mileage,
      annualMileage: parseInt(formData.annualMileage) * 5000,
      carPrice: {
        units: hasGrossPrice
          ? parseInt(car?.grossPriceWithOptions)
          : parseInt(formData.sellingPrice),
        nanos: 0,
      },
      calculationPriceMethod: !hasGrossPrice
        ? CALCULATION_METHOD.CALCULATION_PRICE_METHOD_GROSS
        : CALCULATION_METHOD.CALCULATION_PRICE_METHOD_NET,
    }
    await dispatch(calculateRV({ data }))
  }

  useEffect(() => {
    if (
      parseInt(formData.duration) > 0 &&
      parseInt(formData.annualMileage) * 5000 > 0 &&
      (car?.grossPriceWithoutOptions ||
        (!car?.grossPriceWithoutOptions && parseInt(formData.sellingPrice) > 0))
    ) {
      handleCalculateRV()
    }
  }, [formData])

  return (
    <CollapseBlock
      title="Calculator"
      saved={saved}
      collapsed={!expanded}
      onCollapseChange={(value) =>
        dispatch(
          setLoanCalculatorOpened({
            expanded: value,
            selectedTab: selectedTab,
          }),
        )
      }
    >
      <Grid container gap="24px">
        <Grid item xs={12} sx={{ mb: '30px' }}>
          <Stack direction="row" gap="27px" alignItems="center">
            <UITextRegular sx={{ fontWeight: 700 }} text="Type of contractor" />
            <FormControl>
              <RadioGroup
                row
                value={'personal'}
                onChange={(e) => {
                  //  dispatch(updateEmploymentStatus(e.target.value))
                }}
                sx={{
                  gap: '30px',
                }}
              >
                <FormControlLabel control={<CustomRadio />} value={'personal'} label={'Personal'} />
                <FormControlLabel control={<CustomRadio />} value={'company'} label={'Company'} />
                <FormControlLabel
                  control={<CustomRadio />}
                  value={'soleCompany'}
                  label={'Sole company'}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            required
            fullWidth
            label="Selling Price"
            InputProps={{
              endAdornment: <UIInputAdornmentText text="CHF" />,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            required
            fullWidth
            label="Down Payment"
            InputProps={{
              endAdornment: <UIInputAdornmentText text="CHF" />,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: '30px' }}>
          <Stack direction="row" gap="15px" alignItems="center">
            <UITextRegular sx={{ fontWeight: 700 }} text="Gross price" />
            <UIButtonPlainGreen
              text="Use VIN Autofill"
              prefixIcon={<img src={IconVinAutofill} alt="" />}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            fullWidth
            label="Gross price without options"
            value={car?.grossPriceWithoutOptions}
            inputProps={{ readOnly: true }}
            InputProps={{
              endAdornment: <UIInputAdornmentText text="CHF" />,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            fullWidth
            label="Options price"
            value={car?.optionsPrice}
            inputProps={{ readOnly: true }}
            InputProps={{
              endAdornment: <UIInputAdornmentText text="CHF" />,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            fullWidth
            label="Gross price with options"
            value={car?.grossPriceWithOptions}
            inputProps={{ readOnly: true }}
            InputProps={{
              endAdornment: <UIInputAdornmentText text="CHF" />,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ height: '30px' }} />
        <Grid item xs={12}>
          <FormDurationSlider
            value={formData.duration}
            onChange={(val) => setFormData({ ...formData, duration: val })}
          />
        </Grid>
        <Grid item md={3} sx={{ mt: '10px' }}>
          <UIFormInputSelect
            label="Duration"
            options={getDurationOptions()}
            value={formData.duration}
            onChange={(e) => setFormData({ ...formData, duration: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sx={{ height: '30px' }} />
        <Grid item xs={12}>
          <FormCarSlider
            label="Annual mileage"
            value={formData.annualMileage}
            onChange={(val) => setFormData({ ...formData, annualMileage: val })}
          />
        </Grid>
        <Grid item xs={12} sx={{ height: '30px' }} />
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" gap="15px" sx={{ mb: '10px' }}>
            <UITextRegular fontWeight="700" text="Residual Value Insurace" />
            <Stack direction="row" alignItems="center" gap="5px">
              <SimpleCheckbox
                value={formData.customCoverage}
                setValue={(val) => setFormData({ ...formData, customCoverage: val })}
              />
              <UITextRegular text="No RV coverage by Insercle" />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: '5px', position: 'relative' }}
          >
            <UITextBodySmall
              text={`${sumDelimiter(10 * 1000).replace("'", ' ')} CHF`}
              sx={{
                position: 'absolute',
                top: 0,
                left: `calc(${(10 / 18) * 100}% - 25px)`,
              }}
            />
            <UITextBodySmall text="0 CHF" />
            <UITextBodySmall text="18 000 CHF" />
          </Stack>
          <FormMoneySlider
            min={0}
            max={18}
            covered={10}
            disabled={formData.customCoverage}
            value={formData.rvi}
            onChange={(val) => setFormData({ ...formData, rvi: val })}
          />
        </Grid>
        {formData.customCoverage && (
          <Grid item md={3} sx={{ mt: '20px' }}>
            <TextFieldSmallEdible fullWidth label="Dealer Risk Input (RVI)" />
          </Grid>
        )}

        <Grid item xs={12} sx={{ height: '30px' }} />
        <Grid item xs={12}>
          <FormTermsSelect
            disabled={formData.customTerm}
            selectedTerm={formData.selectedTerm}
            slides={slides}
            onChange={(val) => setFormData({ ...formData, selectedTerm: val })}
          />
        </Grid>
        <Grid item xs={12} sx={{ height: '30px' }} />
        <Grid item xs={12}>
          <FormPackages formData={formData} setFormData={setFormData} />
        </Grid>
        <Grid item xs={12} sx={{ height: '20px' }} />
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <ButtonLG>Save and continue</ButtonLG>
        </Grid>
      </Grid>
    </CollapseBlock>
  )
}

export default LoanCalculator
