import React from 'react'
import { colorsConfig } from '../../../../config/themeConfig'
import { Box } from '@mui/material'

const PackageItemWrapper = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '10px',
        border: '1px solid',
        borderColor: colorsConfig.lightGray,
        background: 'white',
        px: '40px',
        pt: '35px',
        pb: '20px',
        display: 'flex',
        minHeight: '100%',
        boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
        transition: '0.25s all ease-in-out',
        '&:hover': {
          borderColor: colorsConfig.mainGreen,
          background: colorsConfig.mainGreen,
          transition: '0.25s all ease-in-out',
          '& button.select-button': {
            background: 'white',
            color: colorsConfig.mainBlack,
            transition: '0.25s all ease-in-out',
          },
        },
        '&.active': {
          borderColor: colorsConfig.mainGreen,
          background: colorsConfig.mainGreen,
          '& button.select-button': {
            background: 'white',
            color: colorsConfig.mainBlack,
          },
        },
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default PackageItemWrapper
