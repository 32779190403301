import React, { useEffect } from 'react'
import ModalWrap from '../../../../../components/Modals/ModalWrap'
import UITextHeadlineLarge from '../../../../../ui/UIText/UITextHeadlineLarge'
import { Stack } from '@mui/material'
import UITable from '../../../../../ui/UITable/UITable'
import { policyImportsTableHead } from '../../../../../utils/constants/table.constants'
import { useDispatch, useSelector } from 'react-redux'
import { getPolicyImports } from '../../../../../store/features/rviOperator/rviOperator.actions'
import UIPreloader from '../../../../../ui/UIPreloader/UIPreloader'
import { getPolicyImportsTableData } from '../../../../../utils/helpers/table.helpers'
import ButtonBlueSmall from '../../../../../ui/Buttons/ButtonBlueSmall'
import filesApiService from '../../../../../api/filesService/filesApiService'
import { downloadFileFromPrivateBucket } from '../../../../../utils/downloadFile'
import { toast } from 'react-toastify'
import {
  setPolicyImportsParams,
  setUnlinkedPoliciesParams,
} from '../../../../../store/features/rviOperator/rviOperator.reducer'
import UITablePagination from '../../../../../ui/UITable/UITablePagination'
import UITextHeadlineSmall from '../../../../../ui/UIText/UITextHeadlineSmall'

const UploadHistoryModal = ({ open, setOpen }) => {
  const isLoading = useSelector((state) => state.rviOperator.policyImports.isLoading)
  const policyImports = useSelector((state) => state.rviOperator.policyImports.data)
  const params = useSelector((state) => state.rviOperator.policyImports.params)
  const total = useSelector((state) => state.rviOperator.policyImports.total)
  const dispatch = useDispatch()

  const handleDownloadFile = async (fileId) => {
    try {
      const res = await filesApiService.getPrivateFile(fileId)
      const url = res.data?.signedUrl
      downloadFileFromPrivateBucket(url, 'Policies upload')
    } catch (e) {
      toast.error(e)
    }
  }

  useEffect(() => {
    dispatch(getPolicyImports())
  }, [params])

  return (
    <ModalWrap open={open} setOpen={setOpen} wrapperStyles={{ width: '1150px', maxHeight: '90vh' }}>
      {isLoading && <UIPreloader />}
      <Stack gap="16px">
        <UITextHeadlineLarge sx={{ fontSize: '32px' }} text="Upload history" />
        <UITable
          head={policyImportsTableHead}
          content={getPolicyImportsTableData(policyImports)}
          emptyText="No uploads found."
          renderActionItems={(data) => {
            return (
              <ButtonBlueSmall
                onClick={() =>
                  handleDownloadFile(data?.initialData?.importFile?.['@id']?.replace('/files/', ''))
                }
              >
                Download
              </ButtonBlueSmall>
            )
          }}
        />
        <UITablePagination
          count={total}
          page={params.page}
          rowsPerPage={params.itemsPerPage}
          onPageChange={(e, newValue) => {
            dispatch(
              setPolicyImportsParams({
                ...params,
                page: newValue,
              }),
            )
          }}
          onRowsPerPageChange={(e) => {
            dispatch(
              setPolicyImportsParams({
                ...params,
                page: 1,
                itemsPerPage: e.target.value,
              }),
            )
          }}
        />
      </Stack>
    </ModalWrap>
  )
}

export default UploadHistoryModal
