import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { Box } from '@mui/material'
import UIMatrixRow from './UIMatrixRow'
import UIMatrixCell from './UIMatrixCell'

const UIMatrix = ({ children }) => {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: colorsConfig.buttonDisabled,
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  )
}

export default UIMatrix
