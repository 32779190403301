import { createAsyncThunk } from '@reduxjs/toolkit'
import filesService from '../../../api/filesService/filesApiService'
import { downloadFileFromPrivateBucket } from '../../../utils/downloadFile'
import { toast } from 'react-toastify'

export const downloadPrivateFile = createAsyncThunk(
  'files/downloadFile',
  async ({ fileId, filename = null }, { rejectWithValue }) => {
    try {
      const response = await filesService.getPrivateFileByIdUrl(fileId)
      const fileData = response.data
      await downloadFileFromPrivateBucket(
        fileData?.signedUrl,
        filename ?? fileData?.originalName ?? fileData?.name,
      )
      return response.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)
