import React from 'react'
import { colorsConfig } from '../../../config/themeConfig'
import { Slider } from '@mui/material'
import { sumDelimiter } from '../../../utils/sumDelimiter'

const FormMoneySlider = ({ value, onChange, disabled, min, max, covered, ...props }) => {
  const getValueLabelColor = () => (value > covered ? colorsConfig.darkRed : colorsConfig.mainBlue)

  const getTrackColor = () => {
    if (value > covered) {
      return `linear-gradient(90deg, ${colorsConfig.mainBlue} 0%, ${colorsConfig.mainBlue} ${(covered / value) * 100}%, ${colorsConfig.mainRed} ${(covered / value) * 100 + 0.01}%, ${colorsConfig.darkRed}) 100%`
    } else {
      return colorsConfig.mainBlue
    }
  }

  const getValueLabelFormat = (val) => {
    if (val > covered) {
      const coveredValue = sumDelimiter(covered * 1000).replace("'", ' ')
      const extraValue = sumDelimiter((val - covered) * 1000).replace("'", ' ')
      return `${coveredValue} CHF (${extraValue} CHF deductable)`
    } else {
      return `${sumDelimiter(val * 1000).replace("'", ' ')} CHF`
    }
  }

  return (
    <Slider
      marks
      min={min}
      step={1}
      max={max}
      value={value}
      disabled={disabled}
      valueLabelDisplay="on"
      valueLabelFormat={getValueLabelFormat}
      onChange={(e, newVal) => {
        onChange(newVal)
      }}
      className={disabled ? 'disabled' : ''}
      sx={{
        height: '10px',
        '&.disabled': {
          opacity: 0.5,
          pointerEvents: 'none',
        },
        '& .MuiSlider-valueLabel': {
          top: '44px',
          left: value > max / 2 ? 'auto' : '-26px',
          right: value <= max / 2 ? 'auto' : '-30px',
          backgroundColor: getValueLabelColor(),
          borderRadius: '8px',
          '&::before': {
            transform: 'translate(-50%, 50%) rotate(135deg)',
            bottom: '100%',
            left: value > max / 2 ? 'auto' : '24px',
            right: value <= max / 2 ? 'auto' : '24px',
          },
        },
        '& .MuiSlider-track': {
          background: getTrackColor(),
          border: 'none',
        },
        '& .MuiSlider-rail': {
          background: colorsConfig.bgLightBlue,
          border: 'none',
          boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.10) inset',
        },
        '& .MuiSlider-thumb': {
          width: 0,
          height: 0,
          boxShadow: 'none !important',
          '&:hover': {
            boxShadow: 'none',
          },
          '&::before': {
            display: 'none',
          },
        },
        '& .MuiSlider-mark': {
          opacity: 0,
          width: '20px',
          height: '20px',
          borderRadius: '50%',
          background: '#ECEFF0',
          transform: 'translate(-5px, -50%)',
          // '&.MuiSlider-markActive': {
          //   background: getColor(),
          // },
        },
      }}
      {...props}
    />
  )
}

export default FormMoneySlider
