import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { getEntries } from '../../../store/features/residualValueMatrixEntries/residualValueMatrixEntries.actions'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import UIMatrix from '../../../ui/UIMatrix/UIMatrix'
import UIMatrixRow from '../../../ui/UIMatrix/UIMatrixRow'
import UIMatrixCell from '../../../ui/UIMatrix/UIMatrixCell'
import UIMatrixHeadCell from '../../../ui/UIMatrix/UIMatrixHeadCell'

const RVMatrix = () => {
  const entries = useSelector((state) => state.residualValueMatrixEntries.data)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEntries())
    dispatch(setCurrentPageTitle('RV Matrix'))
  }, [])

  return (
    <BoxShadowed>
      <UIMatrix>
        <UIMatrixRow>
          <UIMatrixHeadCell text="Duration" />
          <UIMatrixHeadCell text="Annual mileage (min)" />
          <UIMatrixHeadCell text="Annual mileage (max)" />
          <UIMatrixHeadCell text="Total mileage (min)" />
          <UIMatrixHeadCell text="Total mileage (max)" />
          <UIMatrixHeadCell text="RV from Gross price" />
          <UIMatrixHeadCell text="RV from Net price" />
        </UIMatrixRow>
        {entries.map((item) => {
          return (
            <UIMatrixRow key={item?.id}>
              <UIMatrixCell value={item?.term} />
              <UIMatrixCell value={item?.term} />
              <UIMatrixCell value={item?.term} />
              <UIMatrixCell value={item?.term} />
              <UIMatrixCell value={item?.term} />
              <UIMatrixCell value={item?.term} />
              <UIMatrixCell value={item?.term} />
            </UIMatrixRow>
          )
        })}
      </UIMatrix>
    </BoxShadowed>
  )
}

export default RVMatrix
