import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import Logo from '../../assests/img/logo-blue.svg'

const LayoutPublicMini = ({ children }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Box>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          maxWidth: '1176px',
          mx: 'auto',
          display: 'flex',
          alignItems: 'center',
          height: '90px',
          '&.xs-only': {
            px: '16px',
          },
        }}
      >
        <Box>
          <img src={Logo} alt="" />
        </Box>
      </Box>
      <Box
        sx={{
          width: '1200px',
          maxWidth: '90vw',
          ml: 'auto',
          mr: 'auto',
          pb: '50px',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default LayoutPublicMini
