import { getCarDetailsFromVINProvider } from '../addNewCar.actions'
import { getFieldsAutofilled, initialCarFirstData } from '../addNewCar.helpers'

export default (builder) => {
  builder
    .addCase(getCarDetailsFromVINProvider.pending, (state) => {
      state.vinProvider.isLoading = true
    })
    .addCase(getCarDetailsFromVINProvider.fulfilled, (state, action) => {
      state.vinProvider.isLoading = false
      state.vinProvider.error = null
      const carDetails = action.payload
      state.vinProvider.data = carDetails
      console.log({ carDetails })
      state.carFirstData.make = carDetails?.make
        ? {
            ...carDetails?.make,
            id: carDetails?.make?.['@id'],
          }
        : initialCarFirstData.make
      state.carFirstData.model = carDetails?.model
        ? {
            ...carDetails?.model,
            id: carDetails?.model?.['@id'],
          }
        : initialCarFirstData.model
      state.carMainData = {
        ...state.carMainData,
        ...getFieldsAutofilled(carDetails),
      }
    })
    .addCase(getCarDetailsFromVINProvider.rejected, (state, action) => {
      state.vinProvider.isLoading = false
      state.vinProvider.error = action.payload
    })
}
