import { createSlice } from '@reduxjs/toolkit'
import calculateRVBuilder from './builders/calculateRV.builder'

const initialState = {
  rvCalculation: {
    isLoading: false,
    error: null,
    data: null,
  },
}

const financingSlice = createSlice({
  name: 'financingReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    calculateRVBuilder(builder)
  },
})

export default financingSlice.reducer
