import React, { useState } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Modal,
  Typography,
  useMediaQuery,
} from '@mui/material'
import modalImg from '../../assests/img/fleetify-notifications-modal-img.png'
import { colorsConfig } from '../../config/themeConfig'
import useBreakpoints from '../../hooks/useBreakpoints'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import IconNotificationMail from '../../assests/img/icons/icon-notification-mail.svg'
import LogoWhite from '../../assests/img/logo-white.svg'
import TextNormal from '../../ui/Text/TextNormal'
import UITextRegular from '../../ui/UIText/UITextRegular'

const menuCloseIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M10.8995 1L5.94975 5.94975M5.94975 5.94975L1 10.8995M5.94975 5.94975L1 1M5.94975 5.94975L10.8995 10.8995"
      stroke="#AC0000"
      strokeWidth="2"
    />
  </svg>
)

const InboxNotificationItem = (props) => {
  const { notification, markNotificationAsRead } = props

  const { createdAt, readAt, id, imageUrl, actionUrl, initiator } = notification

  const { title, description } = notification.translationContext

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const company = useSelector((state) => state.auth.company)

  const { isMinWidth1440, isMaxWidth1440 } = useBreakpoints()
  const navigate = useNavigate()

  const [modal, setModal] = useState(false)

  const linkDataArray = [
    {
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z"
            stroke="#4DED6D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      path: `https://sb-auction.insercle.com/virtual-garage`,
    },
    {
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
            stroke="#4DED6D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.9997 11.3703C16.1231 12.2025 15.981 13.0525 15.5935 13.7993C15.206 14.5461 14.5929 15.1517 13.8413 15.53C13.0898 15.9082 12.2382 16.0399 11.4075 15.9062C10.5768 15.7726 9.80947 15.3804 9.21455 14.7855C8.61962 14.1905 8.22744 13.4232 8.09377 12.5925C7.96011 11.7619 8.09177 10.9102 8.47003 10.1587C8.84829 9.40716 9.45389 8.79404 10.2007 8.40654C10.9475 8.01904 11.7975 7.87689 12.6297 8.0003C13.4786 8.12619 14.2646 8.52176 14.8714 9.12861C15.4782 9.73545 15.8738 10.5214 15.9997 11.3703Z"
            stroke="#4DED6D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.5 6.5H17.51"
            stroke="#4DED6D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      path: `https://sb-auction.insercle.com/virtual-garage`,
    },
    {
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M23 2.99834C22.0424 3.67381 20.9821 4.19045 19.86 4.52834C19.2577 3.83585 18.4573 3.34503 17.567 3.12226C16.6767 2.8995 15.7395 2.95553 14.8821 3.28279C14.0247 3.61005 13.2884 4.19274 12.773 4.95205C12.2575 5.71137 11.9877 6.61067 12 7.52834V8.52834C10.2426 8.5739 8.50127 8.18415 6.93101 7.39378C5.36074 6.60342 4.01032 5.43697 3 3.99834C3 3.99834 -1 12.9983 8 16.9983C5.94053 18.3963 3.48716 19.0973 1 18.9983C10 23.9983 21 18.9983 21 7.49834C20.9991 7.21979 20.9723 6.94193 20.92 6.66834C21.9406 5.66183 22.6608 4.39105 23 2.99834Z"
            stroke="#4DED6D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      path: `https://sb-auction.insercle.com/virtual-garage`,
    },
    {
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath="url(#clip0_1817_75372)">
            <path
              d="M22.5406 6.42C22.4218 5.94541 22.1799 5.51057 21.8392 5.15941C21.4986 4.80824 21.0713 4.55318 20.6006 4.42C18.8806 4 12.0006 4 12.0006 4C12.0006 4 5.12057 4 3.40057 4.46C2.92982 4.59318 2.50255 4.84824 2.16192 5.19941C1.82129 5.55057 1.57936 5.98541 1.46057 6.46C1.14579 8.20556 0.991808 9.97631 1.00057 11.75C0.989351 13.537 1.14334 15.3213 1.46057 17.08C1.59153 17.5398 1.83888 17.9581 2.17872 18.2945C2.51855 18.6308 2.93939 18.8738 3.40057 19C5.12057 19.46 12.0006 19.46 12.0006 19.46C12.0006 19.46 18.8806 19.46 20.6006 19C21.0713 18.8668 21.4986 18.6118 21.8392 18.2606C22.1799 17.9094 22.4218 17.4746 22.5406 17C22.8529 15.2676 23.0069 13.5103 23.0006 11.75C23.0118 9.96295 22.8578 8.1787 22.5406 6.42V6.42Z"
              stroke="#4DED6D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.75 15.0205L15.5 11.7505L9.75 8.48047V15.0205Z"
              stroke="#4DED6D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1817_75372">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      path: `https://sb-auction.insercle.com/virtual-garage`,
    },
    {
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8V8Z"
            stroke="#4DED6D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 9H2V21H6V9Z"
            stroke="#4DED6D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z"
            stroke="#4DED6D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      path: `https://sb-auction.insercle.com/virtual-garage`,
    },
  ]

  const createLinkWithSVG = (svgIcon, path) => {
    return (
      <Link href={path} target="_blank" rel="noopener noreferrer">
        <IconButton>{svgIcon}</IconButton>
      </Link>
    )
  }

  const handleExtractDate = (datetimeString) => {
    const dateObj = new Date(datetimeString)

    // Get the month and day from the date object in English
    const month = dateObj.toLocaleString('en-US', { month: 'short' })
    const day = dateObj.getDate()

    // Create the desired format "Mon DD"
    const formattedDate = `${month} ${day}`

    return formattedDate
  }

  return (
    <Box
      sx={{
        width: 1,
      }}
    >
      <Grid
        item
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          position: 'relative',
          height: '50px',
          background: colorsConfig.bgLightBlue,
          display: 'flex',
          alignItems: 'center',
          padding: '0 15px',
          cursor: 'pointer',
          '&.xs-only': {
            height: 'auto',
            py: '11px',
            pl: '18px',
            pr: '12px',
            display: 'block',
            border: '1px solid',
            borderColor: colorsConfig.buttonDisabled,
          },
        }}
        onClick={() => {
          setModal(true)
          !readAt && markNotificationAsRead(id, notification)
        }}
      >
        {xsOnly ? (
          <Box
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <img src={IconNotificationMail} alt="" />
                <TextNormal
                  sx={{
                    ml: '9px',
                    fontWeight: readAt ? 400 : 700,
                  }}
                >
                  {initiator === 'system' ? 'Fleatify' : ''}
                  {initiator === 'operator' ? 'Operator' : ''}
                </TextNormal>
                {!readAt ? (
                  <TextNormal
                    sx={{
                      fontSize: '10px',
                      px: '5px',
                      py: '1px',
                      lineHeight: 'normal',
                      borderRadius: '100px',
                      background: colorsConfig.mainGreen,
                      ml: '3px',
                    }}
                  >
                    NEW
                  </TextNormal>
                ) : null}
              </Box>
              <TextNormal
                sx={{
                  fontWeight: readAt ? 400 : 700,
                }}
              >
                {handleExtractDate(createdAt)}
              </TextNormal>
            </Box>
            <TextNormal
              sx={{
                mt: '10px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontWeight: readAt ? 400 : 700,
              }}
            >
              {title}
            </TextNormal>
            <TextNormal
              sx={{
                color: colorsConfig.iconGray,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {description}
            </TextNormal>
          </Box>
        ) : (
          <>
            {!readAt && (
              <Typography
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '114px',
                  transform: 'translate(0, -50%)',
                  width: '34px',
                  height: '13px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '9px',
                  textTransform: 'uppercase',
                  background: colorsConfig.mainGreen,
                  borderRadius: '100px',
                  leadingTrim: 'both',
                  textEdge: 'cap',
                  lineHeight: 'normal',
                }}
              >
                New
              </Typography>
            )}
            <Box sx={{ marginRight: '10px' }}>
              <img src={IconNotificationMail} alt="" />
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: readAt ? 400 : 700, marginRight: '80px' }}
            >
              {initiator === 'system' ? 'Fleatify' : ''}
              {initiator === 'operator' ? 'Operator' : ''}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
                width: 1,
                alignItems: 'center',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                marginRight: isMinWidth1440 ? '30px' : isMaxWidth1440 ? '55px' : null,
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: readAt ? 400 : 700,
                  width: 'max-content',
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  color: colorsConfig.iconGray,
                  width: 'auto',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {`– ${description}`}
              </Typography>
            </Box>
            <Box sx={{ width: '90px', textAlign: 'right' }}>
              <Typography sx={{ fontSize: '16px', fontWeight: readAt ? 400 : 700 }}>
                {handleExtractDate(createdAt)}
              </Typography>
            </Box>
          </>
        )}
      </Grid>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false)
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            border: 'none',
          },
          '& > .MuiBox-root': {
            padding: 0,
            boxShadow: 'none',
            border: 'none',
          },
        }}
      >
        <Box
          className={['disable-scrollbar', xsOnly ? 'xs-only' : ''].join(' ')}
          sx={{
            padding: 0,
            width: isMinWidth1440 ? '1030px' : isMaxWidth1440 ? '910px' : null,
            position: 'absolute',
            top: '15px',
            left: '50%',
            transform: isMinWidth1440
              ? 'translate(calc(-50% + 100px), 0)'
              : isMaxWidth1440
                ? 'translate(calc(-50% + 130px), 0)'
                : null,
            background: 'white',
            boxShadow: 24,
            overflowY: 'auto',
            p: 4,
            maxHeight: 'calc(100vh - 30px)',
            '&.xs-only': {
              width: 'calc(100vw - 32px)',
              transform: 'none',
              left: '16px',
              top: '15px',
            },
          }}
        >
          <Box
            sx={{
              height: '52px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '0 12px 0 20px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <rect width="40" height="40" rx="20" fill="#007DFF" />
                <path
                  d="M14.7129 28.7674C14.3359 28.7674 14.1474 28.7674 14.0033 28.694C13.8767 28.6294 13.7737 28.5264 13.7091 28.3998C13.6357 28.2557 13.6357 28.0672 13.6357 27.6902V16.4758C13.6357 14.5905 13.6357 13.6479 14.0026 12.9278C14.3254 12.2944 14.8403 11.7795 15.4737 11.4567C16.1938 11.0898 17.1364 11.0898 19.0217 11.0898H25.2928C25.6698 11.0898 25.8584 11.0898 26.0024 11.1632C26.129 11.2278 26.232 11.3308 26.2966 11.4574C26.37 11.6015 26.37 11.79 26.37 12.167V14.0067C26.37 14.3838 26.37 14.5723 26.2966 14.7163C26.232 14.843 26.129 14.946 26.0024 15.0105C25.8584 15.0839 25.6698 15.0839 25.2928 15.0839H19.7548C19.3778 15.0839 19.1893 15.0839 19.0453 15.1573C18.9186 15.2219 18.8156 15.3248 18.751 15.4515C18.6777 15.5955 18.6777 15.7841 18.6777 16.1611V16.9407C18.6777 17.3177 18.6777 17.5062 18.751 17.6503C18.8156 17.7769 18.9186 17.8799 19.0453 17.9445C19.1893 18.0179 19.3778 18.0179 19.7548 18.0179H24.5162C24.8932 18.0179 25.0817 18.0179 25.2257 18.0912C25.3524 18.1558 25.4554 18.2588 25.52 18.3854C25.5933 18.5295 25.5933 18.718 25.5933 19.095V20.9224C25.5933 21.2995 25.5933 21.488 25.52 21.632C25.4554 21.7587 25.3524 21.8617 25.2257 21.9262C25.0817 21.9996 24.8932 21.9996 24.5162 21.9996H19.7672C19.3901 21.9996 19.2016 21.9996 19.0576 22.073C18.9309 22.1375 18.8279 22.2405 18.7634 22.3672C18.69 22.5112 18.69 22.6997 18.69 23.0768V27.6902C18.69 28.0672 18.69 28.2557 18.6166 28.3998C18.5521 28.5264 18.4491 28.6294 18.3224 28.694C18.1784 28.7674 17.9898 28.7674 17.6128 28.7674H14.7129Z"
                  fill="white"
                />
                <circle cx="23.6604" cy="26.7365" r="2.0295" fill="#4DED6D" />
              </svg>
              <Typography sx={{ fontSize: '16px', fontWeight: 700 }}> Fleatify </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '33px' }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                {handleExtractDate(createdAt)}
              </Typography>
              <IconButton
                onClick={() => {
                  setModal(false)
                }}
              >
                {' '}
                {menuCloseIcon}{' '}
              </IconButton>
            </Box>
          </Box>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              background: '#F2F2F2',
              padding: '16px 0',
              '&.xs-only': {
                pt: 0,
              },
            }}
          >
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                width: '640px',
                background: 'white',
                margin: '0 auto',
                '&.xs-only': {
                  width: '100%',
                },
              }}
            >
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  width: 1,
                  height: '10px',
                  background: 'linear-gradient(90deg, #007DFF 0%, #4DED6D 100%)',
                  '&.xs-only': {
                    height: '5px',
                  },
                }}
              />
              <Box
                sx={{
                  height: '88px',
                  background: colorsConfig.mainBlue,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  marginBottom: xsOnly ? '20px' : '40px',
                  '& img': {
                    width: '215px',
                  },
                }}
              >
                <img src={LogoWhite} alt="" />
                <UITextRegular
                  sx={{
                    fontWeight: 700,
                    fontFamily: '"Inter", sans-serif',
                    color: colorsConfig.white,
                    fontSize: '13px',
                  }}
                >
                  Turn the used Car Market{' '}
                  <span style={{ color: colorsConfig.mainGreen }}>GreenER</span>
                </UITextRegular>
              </Box>
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  paddingLeft: '25px',
                  '& .MuiButtonBase-root:hover': {
                    color: colorsConfig.mainBlue,
                  },
                  '&.xs-only': {
                    px: '8px',
                  },
                }}
              >
                <Box
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    paddingRight: '90px',
                    marginBottom: '35px',
                    '&.xs-only': {
                      pr: 0,
                      mb: '25px',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 700,
                      color: '#363636',
                      lineHeight: 'normal',
                      marginBottom: '5px',
                    }}
                  >{`Dear ${company ? company.name : ''}`}</Typography>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: '#363636',
                      marginBottom: '13px',
                      lineHeight: 'normal',
                    }}
                  >
                    {description}
                  </Typography>
                  <Typography sx={{ fontSize: '16px', color: '#363636', lineHeight: 'normal' }}>
                    {' '}
                    Thank you for choosing Fleatify services!
                  </Typography>
                  <Typography sx={{ fontSize: '16px', color: '#363636', lineHeight: 'normal' }}>
                    {' '}
                    Best regards,{' '}
                  </Typography>
                  <Typography sx={{ fontSize: '16px', color: '#363636', lineHeight: 'normal' }}>
                    {' '}
                    Fleatify Team{' '}
                  </Typography>
                </Box>
                <Button
                  className={xsOnly ? 'xs-only' : ''}
                  variant="outlined"
                  sx={{
                    background: colorsConfig.mainBlue,
                    borderRadius: '4px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: 'white',
                    textTransform: 'capitalize',
                    px: '75px',
                    py: '10px',
                    marginBottom: '60px',
                    '&.xs-only': {
                      mb: '30px',
                      width: '100%',
                    },
                  }}
                  onClick={() => {
                    navigate(actionUrl.replace('https://sb-auction.insercle.com', ''))
                  }}
                >
                  Learn more
                </Button>
              </Box>
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  background: colorsConfig.bgLightBlue,
                  margin: '0 20px',
                  padding: '30px 0 16px',
                  textAlign: 'center',
                  '&.xs-only': {
                    mx: 0,
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: '#363636',
                    lineHeight: 'normal',
                    marginBottom: '7px',
                  }}
                >
                  {' '}
                  Follow us on{' '}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    gap: '32px',
                    justifyContent: 'center',
                    marginBottom: '19px',
                  }}
                >
                  {linkDataArray.map((linkItem, index) => {
                    return <div key={index}>{createLinkWithSVG(linkItem.svg, linkItem.path)}</div>
                  })}
                </Box>

                <Typography sx={{ color: '#363636', lineHeight: '24px', letterSpacing: '0.1px' }}>
                  www.fleatify.pro
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', color: '#A7A7A7', lineHeight: '22px', marginTop: '10px' }}
                >
                  Copyright &copy; 2024 Website
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default InboxNotificationItem
