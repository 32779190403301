import { calculateRV } from '../financing.actions'

export default (builder) => {
  builder
    .addCase(calculateRV.pending, (state) => {
      state.rvCalculation.isLoading = true
    })
    .addCase(calculateRV.fulfilled, (state, action) => {
      state.rvCalculation.isLoading = false
      state.rvCalculation.error = null
      state.rvCalculation.data = action.payload
    })
    .addCase(calculateRV.rejected, (state, action) => {
      state.rvCalculation.isLoading = false
      state.rvCalculation.error = action.payload
    })
}
