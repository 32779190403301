import formDate from '../../../../components/Forms/FormComponents/FormDate'

export const editRVIPolicyInitialState = {
  policyNumber: '',
  internalCustomerId: '',
  dealerId: '',
  modelId: '',
  customerContractNumber: '',
  color: '',
  mileage: '',
  vehicleGrossPriceExcludingOptionsIncludingVAT: '',
  vehicleOptionsIncludingVAT: '',
  discountIncludingVAT: '',
  vehicleGrossPriceIncludingOptionsIncludingVAT: '',
  vehicleNetPriceIncludingVAT: '',
  mileagePa: '',
  contractDurationInMonths: '',
  offerId: '',
  firstRegistrationDate: '',
  salesPeriod: '',
  businessPartnerId: '',
  RVWarrant: '',
  customerReference: '',
  listPriceIncludingOptionsExcludingVAT: '',
  RVPremiumExcludingVAT: '',
  RVPremiumPercent: '',
  risk: '',
  handlingFee: '',
  inRisk: '',
  reinsurance: '',
  reinsurer: '',
  reinsuranceBrutto: '',
  insuranceTax: '',
  reinsuranceNetto: '',
  reinsuranceRisk: '',
  reinsuranceVK: '',
  reserves: '',
  status: '',
  realEndDate: '',
  initialRVPercent: '',
  initialResidualValueIncludingVAT: '',
  RVPremiumPaid: false,
  RVIStartDate: null,
  RVIEndDate: null,
}

export const getRVIPolicyFormData = (formData) => {
  return {
    details: {
      ...formData,
      risk: formData.risk !== '' ? formData.risk : '0',
      mileage: formData.mileage !== '' ? parseInt(formData.mileage) : 0,
      RVPremiumPercent:
        formData.RVPremiumPercent !== '' ? formData.RVPremiumPercent.toString() : '0',
      initialRVPercent:
        formData.initialRVPercent !== '' ? formData.initialRVPercent.toString() : '0',
      initialResidualValueIncludingVAT:
        formData.initialResidualValueIncludingVAT !== ''
          ? formData.initialResidualValueIncludingVAT.toString()
          : '0',
    },
  }
}
