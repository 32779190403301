import React from 'react'
import { Box, Container } from '@mui/material'
import Logo from '../../assests/img/logo-blue.svg'
import LanguageSelect from '../../components/LanguageSelect'
import ButtonPlainSmall from '../../ui/Buttons/ButtonPlainSmall'
import { useNavigate } from 'react-router-dom'

const GreenPageHeader = () => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        width: '100vw',
        height: '84px',
        background: 'white',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        zIndex: 100,
      }}
    >
      <Container maxWidth={false} sx={{ maxWidth: '1240px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/')
            }}
          >
            <img src={Logo} alt="" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '30px',
            }}
          >
            <LanguageSelect variant={'standard'} />
            <ButtonPlainSmall
              onClick={() => {
                navigate('/')
              }}
            >
              Back to Fleatify
            </ButtonPlainSmall>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default GreenPageHeader
