import React from 'react'
import { TextField } from '@mui/material'
import { colorsConfig } from '../config/themeConfig'
import { useTranslation } from 'react-i18next'

const TextFieldSmallEdible = ({
  InputProps,
  InputLabelProps,
  inputProps,
  sx,
  label,
  isAutocomplete,
  helperText,
  required,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <TextField
      required={required}
      label={t(label)}
      size={'small'}
      sx={{
        borderRadius: '4px',
        fontSize: '14px',
        border: 'none',
        '& .MuiAutocomplete-inputRoot': {
          py: '1.5px',
        },
        '& .MuiFormLabel-asterisk': {
          color: colorsConfig.mainRed,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
        },
        '&.attention': {
          '& .MuiOutlinedInput-notchedOutline': {
            boxShadow: `0 2px 8px 2px ${colorsConfig.shadowedYellow}`,
          },
        },
        ...(isAutocomplete && {
          '& .MuiFormLabel-root': {
            top: '-6px',
            '&.MuiInputLabel-shrink': {
              top: '0',
            },
          },
        }),
        ...sx,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        sx: {
          color: colorsConfig.iconGray,
          fontWeight: 400,
          fontSize: '14px',
          '&.Mui-focused': {
            background: 'white',
            color: colorsConfig.mainBlue,
            boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
            overflow: 'visible',
          },
          '&.attention': {
            '&.Mui-focused': {
              boxShadow: `0 2px 8px 2px ${colorsConfig.shadowedYellow}`,
            },
          },
        },
      }}
      inputProps={{
        ...inputProps,
        sx: {
          color: colorsConfig.iconGray,
          fontWeight: 400,
          fontSize: '14px',
          border: 'none',
        },
      }}
      InputProps={{
        ...InputProps,
        sx: {
          color: colorsConfig.iconGray,
          fontWeight: 400,
          fontSize: '14px',
          border: 'none',
        },
      }}
      helperText={t(helperText)}
      {...props}
    />
  )
}

export default TextFieldSmallEdible
