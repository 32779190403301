import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { privateRequest } from '../../../utils/request'
import { useParams } from 'react-router-dom'
import { API_ENDPOINTS, FILE_TYPES } from '../../../config/default'
import { useDispatch, useSelector } from 'react-redux'
import IconUpload from '../../../assests/img/icons/icon-upload.svg'
import { addCarPhoto } from '../../../store/features/carSlice/carSlice'
import { uploadFile } from '../../../utils/uploadFile'
import { colorsConfig } from '../../../config/themeConfig'
import { FileUploader } from 'react-drag-drop-files'
import { useTranslation } from 'react-i18next'

const CarPhotoItem = ({ image, carView, index, status, refresh }) => {
  const { t } = useTranslation()
  const params = useParams()
  const carPhotos = useSelector((state) => state.car.appraisal.foreshortenings)
  const dispatch = useDispatch()

  const handleAddPhoto = async (file) => {
    dispatch(
      addCarPhoto(
        carPhotos.map((item) => {
          if (item.foreshortening === carView) {
            return {
              ...item,
              direct: index,
              status: 'loading',
              newCarPhoto: file,
            }
          }
          return item
        }),
      ),
    )
    const matchForeshortening = carPhotos.filter(
      (item) => item.foreshortening === carView && item.carPhoto,
    )[0]
    if (matchForeshortening) {
      await privateRequest.delete(matchForeshortening.carForeshortening)
    }
    const fileId = await uploadFile(file, FILE_TYPES.foreshortening)
    const formData = {
      foreshortenings: [
        {
          foreshortening: carView,
          photo: fileId,
          direct: index + 1,
        },
      ],
    }
    await privateRequest
      .post(`/cars/${params.id}${API_ENDPOINTS.carPhotosBatch}`, formData)
      .then((response) => {
        refresh()
        // dispatch(addCarPhoto(carPhotos.map(item => {
        //     if (item.foreshortening === carView) {
        //         return {
        //             ...item,
        //             status: 'idle',
        //             newCarPhoto: null,
        //             carForeshortening: response.data['@id'],
        //             carPhotos: response.data.photos
        //         }
        //     }
        //     return item
        // })))
      })
  }

  return (
    <FileUploader
      handleChange={handleAddPhoto}
      name="file"
      types={['JPG', 'PNG', 'jpeg']}
      hoverTitle="Drop here"
      label="Upload or drop all the photos here"
      classes="upload-picture"
      maxSize={10}
      children={
        <Box
          sx={{
            height: '160px',
            borderRadius: '6px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Button
            component="label"
            sx={{
              width: '62px',
              height: '75px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              mt: '-37.5px',
              ml: '-31px',
              zIndex: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              textTransform: 'none',
            }}
          >
            {status === 'loading' ? (
              <div className="lds-dual-ring"></div>
            ) : (
              <React.Fragment>
                <img style={{ marginBottom: '5px' }} src={IconUpload} alt="" />
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    fontFamily: '"Helvetica", sans-serif',
                    color: 'white',
                  }}
                >
                  {t('button_upload')}
                </Typography>
              </React.Fragment>
            )}
          </Button>
          <div className="overlay-box" style={{ overflow: 'hidden', maxHeight: '100%' }}>
            <div className="overlay-box-inner">
              <img
                src={image}
                alt=""
                style={{
                  width: '100%',
                }}
              />
            </div>
          </div>
        </Box>
      }
    />
  )
}

export default CarPhotoItem
