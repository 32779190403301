import { createAsyncThunk } from '@reduxjs/toolkit'
import filesApiService from '../../../api/filesService/filesApiService'
import { fileTypeEnums } from '../../../utils/constants/enums.constants'
import rviApiService from '../../../api/rviService/rviApiService'
import { toast } from 'react-toastify'
import { apiConstants } from '../../../api/api'
import { getMultiQueryParam } from '../../../utils/helpers/request.helpers'
import { policyWorkflowStates } from '../../../utils/constants/workflows.constants'

export const importPolicies = createAsyncThunk(
  'rviOperator/importPolicies',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await filesApiService.createFile(data, fileTypeEnums.rvi_policy_import)
      const fileId = response.data?.['@id']
      const importResponse = await rviApiService.importPolicies({
        policyImportFile: fileId,
      })
      return importResponse.data
    } catch (e) {
      toast.error(e?.errorMessage)
      return rejectWithValue(e)
    }
  },
)

export const getUnlinkedPolicies = createAsyncThunk(
  'rviOperator/getUnlinkedPolicies',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().rviOperator.unlinkedPolicies.params
      const extraQuery = getMultiQueryParam('currentWorkflowState', [policyWorkflowStates.new])
      const response = await rviApiService.getPolicies(params, extraQuery, 'getUnlinkedPolicies')
      return response.data
    } catch (e) {
      toast.error(e?.errorMessage)
      return rejectWithValue(e)
    }
  },
)

export const getPoliciesInRestitution = createAsyncThunk(
  'rviOperator/getPoliciesInRestitution',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().rviOperator.policiesInRestitution.params
      const extraQuery = getMultiQueryParam('currentWorkflowState', [
        policyWorkflowStates.restitution_started,
        policyWorkflowStates.restitution_in_progress,
      ])
      const response = await rviApiService.getPolicies(
        params,
        extraQuery,
        'getPoliciesInRestitution',
      )
      return response.data
    } catch (e) {
      toast.error(e?.errorMessage)
      return rejectWithValue(e)
    }
  },
)

export const getClosedPolicies = createAsyncThunk(
  'rviOperator/getClosedPolicies',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().rviOperator.closedPolicies.params
      const filters = getState().rviOperator.closedPolicies.currentWorkflowStates
      const extraQuery = getMultiQueryParam('currentWorkflowState', filters)
      const response = await rviApiService.getPolicies(params, extraQuery, 'getClosedPolicies')
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getCancelledPolicies = createAsyncThunk(
  'rviOperator/getCancelledPolicies',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().rviOperator.cancelledPolicies.params
      const filters = getState().rviOperator.cancelledPolicies.currentWorkflowStates
      const extraQuery = getMultiQueryParam('currentWorkflowState', filters)
      const response = await rviApiService.getPolicies(params, extraQuery, 'getCancelledPolicies')
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getLinkedPolicies = createAsyncThunk(
  'rviOperator/getLinkedPolicies',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().rviOperator.linkedPolicies.params
      const extraQuery = getMultiQueryParam('currentWorkflowState', [
        policyWorkflowStates.linked_to_dealer,
      ])
      const response = await rviApiService.getPolicies(params, extraQuery, 'getLinkedPolicies')
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getPoliciesStatusCount = createAsyncThunk(
  'rviOperator/getPoliciesStatusCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await rviApiService.getPoliciesStateCounts()
      const statusCounts = response.data?.[apiConstants.hydraMember]
      const restitutionStarted =
        statusCounts.find(
          (item) => item?.workflowState === policyWorkflowStates.restitution_started,
        )?.count ?? 0
      const restitutionInProgress =
        statusCounts.find(
          (item) => item?.workflowState === policyWorkflowStates.restitution_in_progress,
        )?.count ?? 0
      return {
        unlinkedPolicies:
          statusCounts.find((item) => item?.workflowState === policyWorkflowStates.new)?.count ?? 0,
        linkedPolicies:
          statusCounts.find((item) => item?.workflowState === policyWorkflowStates.linked_to_dealer)
            ?.count ?? 0,
        policiesInRestitution: restitutionStarted + restitutionInProgress,
        closedPolicies:
          statusCounts.find((item) => item?.workflowState === policyWorkflowStates.closed)?.count ??
          0,
        cancelledPolicies:
          statusCounts.find((item) => item?.workflowState === policyWorkflowStates.cancelled)
            ?.count ?? 0,
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const archiveRviPolicy = createAsyncThunk(
  'rviOperator/archiveRviPolicy',
  async ({ policyId }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.archivePolicy(policyId)
      toast.success('The policy has been archived')
      return response.data
    } catch (e) {
      toast.error(e?.errorMessage)
      return rejectWithValue(e)
    }
  },
)

export const attachNewPolicies = createAsyncThunk(
  'rviOperator/attachNewPolicies',
  async ({ policyImportId }, { rejectWithValue, getState }) => {
    try {
      const policyImportRes = await rviApiService.getPolicyImport(policyImportId)
      const params = {
        'order[createdAt]': 'desc',
        itemsPerPage: policyImportRes.data?.policyCount,
        page: 1,
      }
      const policiesRes = await rviApiService.getPolicies(params)
      const newPolicies = policiesRes.data?.[apiConstants.hydraMember]
      const companies = getState().companies.allCompanies.data
      const requests = []
      companies.forEach((company) => {
        const matchingPolicies = newPolicies
          .filter((policy) => policy?.details?.dealerId.toString() === company?.id.toString())
          .map((policy) => policy?.['@id'])

        if (matchingPolicies.length > 0) {
          requests.push({
            company: company['@id'],
            policies: matchingPolicies,
          })
        }
      })
      await Promise.all(requests.map((reqData) => rviApiService.attachPoliciesToCompany(reqData)))
      return true
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)

export const linkPoliciesToDealers = createAsyncThunk(
  'rviOperator/linkPoliciesToDealers',
  async ({ data }, { rejectWithValue, getState }) => {
    try {
      const requestsData = data.map((item) => ({
        company: item?.company?.['@id'],
        policies: item?.policies.map((policy) => policy?.['@id']),
      }))
      await Promise.all(requestsData.map((item) => rviApiService.attachPoliciesToCompany(item)))
      return true
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)

export const attachPolicyToDealer = createAsyncThunk(
  'rviOperator/attachPolicyToDealer',
  async ({ data }, { rejectWithValue, getState }) => {
    try {
      const response = await rviApiService.attachPoliciesToCompany(data)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)

export const rviApproveRestitution = createAsyncThunk(
  'rviOperator/rviApproveRestitution',
  async ({ policyId }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.confirmToRestitutionInProgress(policyId)
      return response.data
    } catch (e) {
      toast.error(e?.response?.data?.errorMessage)
      return rejectWithValue(e)
    }
  },
)

export const rviCloseRestitution = createAsyncThunk(
  'rviOperator/rviCloseRestitution',
  async ({ policyId }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.closeDueToRestitution(policyId)
      return response.data
    } catch (e) {
      toast.error(e?.response?.data?.errorMessage)
      return rejectWithValue(e)
    }
  },
)

export const getPolicyImports = createAsyncThunk(
  'rviOperator/getPolicyImports',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().rviOperator.policyImports.params
      const response = await rviApiService.getPolicyImports(params)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getImportedPolicies = createAsyncThunk(
  'rviOperator/getImportedPolicies',
  async ({ policyImportId }, { rejectWithValue, getState }) => {
    try {
      const companies = getState().companies.allCompanies.data
      const response = await rviApiService.getPolicyImport(policyImportId)
      const policyImport = response.data
      const policyImportWithCompanies = {
        ...policyImport,
        policies: policyImport?.policies?.map((item) => {
          const matchedCompany = companies.find(
            (company) => company?.id.toString() === item?.details?.dealerId,
          )
          return {
            ...item,
            matchedCompany: matchedCompany
              ? {
                  label: matchedCompany?.name,
                  value: matchedCompany,
                }
              : null,
          }
        }),
      }
      return policyImportWithCompanies
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)
