import { closeRunningContract } from '../rviPolicy.actions'

export default (builder) => {
  builder
    .addCase(closeRunningContract.pending, (state) => {
      state.closeRunningContract.isLoading = true
    })
    .addCase(closeRunningContract.fulfilled, (state) => {
      state.closeRunningContract.isLoading = false
      state.closeRunningContract.error = null
    })
    .addCase(closeRunningContract.rejected, (state, action) => {
      state.closeRunningContract.isLoading = false
      state.closeRunningContract.error = action.payload
    })
}
