import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { Box } from '@mui/material'
import UITextRegular from '../UIText/UITextRegular'

const UIMatrixHeadCell = ({ text }) => {
  return (
    <Box
      sx={{
        width: 'calc(100% / 7)',
        borderRight: '1px solid',
        p: '10px',
        borderColor: colorsConfig.buttonDisabled,
        '&:last-child': {
          border: 'none',
        },
      }}
    >
      <UITextRegular fontWeight="700" text={text} />
    </Box>
  )
}

export default UIMatrixHeadCell
