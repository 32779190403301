import React, { useEffect, useState } from 'react'
import { Box, Typography, useMediaQuery, Button } from '@mui/material'
import { useSelector } from 'react-redux'
import GrayTable from '../../../ui/GrayTable'
import IconFileWithCounter from '../../../assests/img/icons/icon-file-with-counter.svg'
import { colorsConfig } from '../../../config/themeConfig'
import TextNormal from '../../../ui/Text/TextNormal'
import { downloadFileFromPrivateBucket } from '../../../utils/downloadFile'
import { useTranslation } from 'react-i18next'

const DocumentsSection = () => {
  const { t } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const initialDocs = useSelector((state) => state.car.appraisal.documents)

  const docs = initialDocs?.filter((item) => item?.sysName !== 'uae_custom_cardpossession_card')

  const [docsPoints, setDocsPoints] = useState([])

  useEffect(() => {
    setDocsTable()
  }, [docs])

  const handleDownloadFiles = (files, name) => {
    files.forEach((item, index) => {
      if (files.length > 1) {
        downloadFileFromPrivateBucket(item.file.signedUrl, `${name} (page ${index + 1})`)
        return
      }
      downloadFileFromPrivateBucket(item.file.signedUrl, name)
    })
  }

  const renderDocumentLink = (files, name) => {
    return (
      <Button
        sx={{
          textTransform: 'none',
        }}
        onClick={() => {
          handleDownloadFiles(files, name)
        }}
      >
        <Box
          sx={{
            verticalAlign: 'top',
            position: 'relative',
          }}
        >
          <img
            src={IconFileWithCounter}
            alt=""
            style={{
              verticalAlign: 'top',
            }}
          />
          <TextNormal
            sx={{
              width: '15px',
              height: '15px',
              fontSize: '10px',
              color: colorsConfig.mainBlue,
              position: 'absolute',
              bottom: '0.5px',
              right: '1.5px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {files.length}
          </TextNormal>
        </Box>
        <Typography
          sx={{
            fontFamily: '"HelveticaNeue", sans-serif',
            fontSize: '16px',
            fontWeight: 500,
            color: colorsConfig.mainBlack,
            lineHeight: 'normal',
            ml: '6px',
          }}
        >
          {t('button_download')}
        </Typography>
      </Button>
    )
  }

  const setDocsTable = () => {
    setDocsPoints(
      docs.map((item) => {
        return {
          title: item.name.replace('(if exists)', ''),
          value: renderDocumentLink(item.carControlPoint.files, item.name),
          fixedHeight: true,
        }
      }),
    )
  }

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        px: '32px',
        pt: 0,
        borderRadius: '6px',
        // boxShadow: '0 0 24px 0 rgba(0, 0, 0, .1)',
        '&.xs-only': {
          px: '8px',
          py: 0,
          overflow: 'hidden',
        },
      }}
    >
      {docs.length > 0 ? (
        <GrayTable content={docsPoints} />
      ) : (
        <Typography>{t('empty_car_documents')}</Typography>
      )}
    </Box>
  )
}

export default DocumentsSection
