import React, { useState } from 'react'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import ButtonTransparent from '../../ui/Buttons/ButtonTransparent'
import { Box, CircularProgress, useMediaQuery } from '@mui/material'
import ButtonAttention from '../../ui/Buttons/ButtonAttention'
import IconHint from '../../assests/img/icons/icon-hint.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { privateRequest } from '../../utils/request'
import { toast } from 'react-toastify'
import { setWorkflowState } from '../../store/features/carSlice/carSlice'
import ButtonDanger from '../../ui/Buttons/ButtonDanger'
import ButtonSuccess from '../../ui/Buttons/ButtonSuccess'
import ButtonHollow from '../../ui/Buttons/ButtonHollow'
import ButtonHollowRegular from '../../ui/Buttons/ButtonHollowRegular'
import { colorsConfig } from '../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const CarActionButtons = ({ block, position, currentAuction, downloadingPDF }) => {
  const { t } = useTranslation()

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)
  const carAppraisalChecksResource = useSelector((state) => state.car.carAppraisalChecksResource)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleActions = (actions) => {
    if (actions) {
      actions.forEach((item) => {
        switch (item.type) {
          case 'workflowTransition':
            privateRequest.post(item.link, {}).then(() => {
              if (item.successMessage) {
                toast.success(item.successMessage)
              }
            })
            break
          case 'workflowTransitionMulti':
            privateRequest.post(item.link, {}).then(() => {
              privateRequest.post(item.link2, {}).then(() => {
                if (item.successMessage) {
                  toast.success(item.successMessage)
                }
                dispatch(setWorkflowState(item.localTransition))
              })
            })
            break
          case 'localWorkflowTransition':
            dispatch(setWorkflowState(item.link))
            break
          case 'navigate':
            navigate(item.link)
            break
          case 'modal':
            item.openModal()
            break
          case 'pdf':
            item.handler()
            break
          case 'workflowChange':
            privateRequest.post(item.link, {}).then(() => {
              if (item.successMessage) {
                toast.success(item.successMessage)
              }
              if (item.localChange) {
                dispatch(setWorkflowState(item.localChange))
              }
            })
        }
      })
    }
  }

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        mb: position && position === 'right' ? '20px' : '',
        ml: position && position === 'right' ? '21px' : '',
        '&.xs-only': {
          display: 'block',
          ml: 0,
          '& button': {
            width: '100% !important',
            mb: '10px',
            '&:last-child': {
              mb: 0,
            },
          },
        },
        '&.tablet-only': {
          gap: '10px',
          '& button': {
            m: 0,
          },
        },
      }}
    >
      {block.buttons.map((item, index) => {
        if (item.workflows.filter((item) => item === combinedStatus)[0]) {
          if (item.specialConditions && item.specialConditions === 'bids') {
            if (currentAuction && !currentAuction.maxBid) {
              return null
            }
          }
          if (item.specialConditions && item.specialConditions === 'no-bids') {
            if (currentAuction && currentAuction.maxBid) {
              return null
            }
          }
          if (item.specialConditions && item.specialConditions === 'carAppraisalChecksResource') {
            if (!carAppraisalChecksResource.vehicleDetailsCheck) return null
            if (!carAppraisalChecksResource.documentsCheck) return null
            if (!carAppraisalChecksResource.photosCheck) return null
            if (!carAppraisalChecksResource.damagesCheck) return null
            if (!carAppraisalChecksResource.warningLightsCheck) return null
            if (!carAppraisalChecksResource.interiorConditionsCheck) return null
            if (!carAppraisalChecksResource.mechanicalConditionsCheck) return null
          }
          if (item.type === 'pdf' && combinedStatus === 'car_workflow.appraisal_on_site') {
            if (!carAppraisalChecksResource.vehicleDetailsCheck) return null
            if (!carAppraisalChecksResource.documentsCheck) return null
            if (!carAppraisalChecksResource.photosCheck) return null
            if (!carAppraisalChecksResource.damagesCheck) return null
            if (!carAppraisalChecksResource.warningLightsCheck) return null
            if (!carAppraisalChecksResource.interiorConditionsCheck) return null
            if (!carAppraisalChecksResource.mechanicalConditionsCheck) return null
          }
          if (item.type === 'standard') {
            return (
              <ButtonLG
                key={index}
                sx={{
                  marginRight: '10px',
                }}
                onClick={() => {
                  handleActions(item.actions)
                }}
              >
                {t(item.name)}
              </ButtonLG>
            )
          }
          if (item.type === 'transparent') {
            return (
              <ButtonTransparent
                key={index}
                sx={{
                  marginRight: '10px',
                }}
                onClick={() => {
                  handleActions(item.actions)
                }}
              >
                {t(item.name)}
              </ButtonTransparent>
            )
          }
          if (item.type === 'attention') {
            return (
              <Box
                className={xsOnly ? 'xs-only' : ''}
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  marginRight: '10px',
                  '&.xs-only': {
                    mr: 0,
                    display: 'block',
                  },
                }}
              >
                <ButtonAttention
                  onClick={() => {
                    handleActions(item.actions)
                  }}
                >
                  {t(item.name)}
                </ButtonAttention>
                {item.infoBlock ? (
                  <Box
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      position: 'relative',
                      '&.xs-only': {
                        display: 'none',
                      },
                    }}
                  >
                    <Box
                      className={xsOnly ? 'xs-only' : ''}
                      sx={{
                        width: '17px',
                        height: '17px',
                        marginLeft: '20px',
                        '&:hover': {
                          '& .info-block': {
                            opacity: 1,
                            transition: '0.3s all ease-in-out',
                            transform: 'scale(1) translateY(0)',
                          },
                        },
                        '&.xs-only': {
                          pointerEvents: 'none',
                        },
                      }}
                    >
                      <img src={IconHint} alt="" />
                      <Box
                        className={'info-block'}
                        sx={{
                          position: 'absolute',
                          bottom: 'calc(100% + 15px)',
                          right: '-30px',
                          width: '380px',
                          opacity: 0,
                          transition: '0.3s all ease-in-out',
                          transform: 'scale(0.5) translateY(-20px)',
                          transformOrigin: '90% 100%',
                        }}
                      >
                        {item.infoBlock}
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            )
          }
          if (item.type === 'attention-disabled') {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  marginRight: '10px',
                }}
              >
                <ButtonAttention
                  sx={{
                    '&.Mui-disabled': {
                      background: '#F2AB4C',
                      color: '#3D3D3D',
                    },
                  }}
                  disabled
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="18"
                    viewBox="0 0 21 18"
                    fill="none"
                    style={{ paddingBottom: '2px', marginRight: '8px' }}
                  >
                    <path
                      d="M10.9793 16.7443C10.4279 17.4914 9.56924 17.9505 8.64082 17.9962C7.7124 18.0423 6.81319 17.6687 6.1911 16.979L0.616313 10.8031C-0.269696 9.82218 -0.190992 8.31007 0.79163 7.42558C1.77519 6.54109 3.28895 6.62013 4.17496 7.60154L8.11911 11.9713C8.17755 12.0362 8.26238 12.071 8.34957 12.0668C8.43723 12.0626 8.51734 12.0188 8.56966 11.9492L16.6766 0.972569C17.4613 -0.0907017 18.9609 -0.316999 20.0265 0.466809C21.0911 1.25062 21.3173 2.74767 20.5322 3.81046L10.9793 16.7443Z"
                      fill="#3D3D3D"
                    />
                  </svg>
                  {t(item.name)}
                </ButtonAttention>
              </Box>
            )
          }
          if (item.type === 'danger') {
            return (
              <ButtonDanger
                key={index}
                sx={{
                  mr: '10px',
                }}
                onClick={() => {
                  handleActions(item.actions)
                }}
              >
                {t(item.name)}
              </ButtonDanger>
            )
          }
          if (item.type === 'success') {
            return (
              <ButtonSuccess
                key={index}
                onClick={() => {
                  handleActions(item.actions)
                }}
              >
                {t(item.name)}
              </ButtonSuccess>
            )
          }
          if (item.type === 'pdf') {
            return (
              <ButtonHollow
                key={index}
                onClick={() => {
                  handleActions(item.actions)
                }}
                sx={{
                  mr: '21px',
                }}
              >
                <span
                  style={{
                    marginRight: '5px',
                    fontFamily: '"Helvetica", sans-serif',
                    fontWeight: 700,
                  }}
                >
                  {downloadingPDF ? 'Generating PDF...' : t(item.name)}
                </span>
                {downloadingPDF ? (
                  <CircularProgress
                    sx={{
                      '.css-176wh8e-MuiCircularProgress-circle': {
                        color: colorsConfig.mainBlue,
                      },
                      margin: '0 8px',
                      transform: 'scale(0.7)',
                    }}
                  />
                ) : null}
              </ButtonHollow>
            )
          }
          if (item.type === 'hollow') {
            return (
              <ButtonHollow
                key={index}
                onClick={() => {
                  handleActions(item.actions)
                }}
                sx={{
                  mr: '21px',
                }}
              >
                {t(item.name)}
              </ButtonHollow>
            )
          }
          if (item.type === 'hollow-regular') {
            return (
              <ButtonHollowRegular
                key={index}
                onClick={() => {
                  handleActions(item.actions)
                }}
                sx={{
                  mr: '21px',
                }}
              >
                {t(item.name)}
              </ButtonHollowRegular>
            )
          }
        }
      })}
    </Box>
  )
}

export default CarActionButtons
