import React from 'react'
import { Box, Stack } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import UITextLabelExtraLarge from '../../../ui/UIText/UITextLabelExtraLarge'
import { colorsConfig } from '../../../config/themeConfig'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import UITextLabelLarge from '../../../ui/UIText/UITextLabelLarge'
import UINoDataIcon from '../../../ui/UIIcons/UINoDataIcon'
import { getFormattedDate } from '../../../utils/date.helpers'
import { LOCAL_ROLE } from '../../../utils/constants/global.constants'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import UIIconEdit from '../../../ui/LocalIcons/UIIconEdit'

const RVIPolicySummary = () => {
  const role = useSelector((state) => state.auth.role)
  const policy = useSelector((state) => state.rviPolicy.data)

  const navigate = useNavigate()
  const params = useParams()

  const carTitle = policy?.details
    ? `${policy?.details?.make} ${policy?.details?.model}`
    : 'No data'
  const vin = policy?.details ? `VIN: ${policy?.details?.vin}` : 'VIN: -'
  const isPaid =
    policy?.details?.RVPremiumPaid === true ? (
      'Yes'
    ) : policy?.details?.RVPremiumPaid === false ? (
      'No'
    ) : (
      <UINoDataIcon isNormalized />
    )

  const isDealer = role === LOCAL_ROLE.COMPANY

  return (
    <Box sx={{ position: 'relative' }}>
      {!isDealer && (
        <ButtonHollow
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
          onClick={() => navigate(`/operator/rvi/policies/${params?.id}/edit`)}
        >
          <UIIconEdit width={24} height={24} />
          Edit policy
        </ButtonHollow>
      )}
      <Stack gap="28px">
        <Stack>
          <UITextHeadlineLarge text={carTitle} />
          <UITextLabelExtraLarge text={vin} sx={{ color: colorsConfig.darkGray }} />
        </Stack>
        <Stack direction="row" gap="40px" flexWrap="nowrap">
          {!isDealer && (
            <>
              <Stack gap="4px">
                <UITextRegular text="Linked dealer" />
                <UITextLabelLarge>
                  {policy?.company?.name ?? <UINoDataIcon isNormalized />}
                </UITextLabelLarge>
              </Stack>
            </>
          )}
          <Stack gap="4px">
            <UITextRegular text="RVI Start Date" />
            <UITextLabelLarge>
              {getFormattedDate(policy?.details?.RVIStartDate) ?? <UINoDataIcon isNormalized />}
            </UITextLabelLarge>
          </Stack>
          <Stack gap="4px">
            <UITextRegular text="RVI End Date" />
            <UITextLabelLarge>
              {getFormattedDate(policy?.details?.RVIEndDate) ?? <UINoDataIcon isNormalized />}
            </UITextLabelLarge>
          </Stack>
          {!isDealer && (
            <Stack gap="4px">
              <UITextRegular text="RVI Premium Paid" />
              <UITextLabelLarge>{isPaid}</UITextLabelLarge>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

export default RVIPolicySummary
