import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton, useMediaQuery } from '@mui/material'
import IconDelete from '../../../assests/img/icons/icon-delete.svg'
import IconArrow from '../../../assests/img/icons/icon-arrow.svg'
import { useSelector } from 'react-redux'
import TextNormal from '../../../ui/Text/TextNormal'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import { PriorityHighRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const CarPhotoSorted = ({
  image,
  setOrder,
  handleDeletePhoto,
  foreshorteningId,
  index,
  lastPhoto,
}) => {
  const { t } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const role = useSelector((state) => state.auth.role)
  const qualityCheckHints = useSelector((state) => state.qualityCheck.hints)

  const [currentPhotoHint, setCurrentPhotoHint] = useState('')
  const [showHint, setShowHint] = useState(false)

  const handleSetShowHint = () => {
    if (role !== 'COMPANY') {
      setShowHint(false)
      return
    }
    const matchHint = qualityCheckHints.filter(
      (item) => item.carForeshortening === foreshorteningId,
    )[0]
    if (matchHint && !matchHint.fixed) {
      setShowHint(true)
    } else {
      setShowHint(false)
    }
  }

  const handleSetCurrentPhotoHint = () => {
    const matchHint = qualityCheckHints.filter(
      (item) => item.carForeshortening === foreshorteningId,
    )[0]
    if (matchHint) {
      setCurrentPhotoHint(matchHint.hint)
    }
  }

  useEffect(() => {
    handleSetShowHint()
  }, [qualityCheckHints])

  useEffect(() => {
    handleSetCurrentPhotoHint()
  }, [])

  return (
    <Grid item xs={6} md={3}>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {index === 0 ? (
          <TextBoldNormal
            sx={{
              position: 'absolute',
              bottom: 'calc(100% + 5px)',
              right: 0,
            }}
          >
            {t('common_main_image')}
          </TextBoldNormal>
        ) : null}
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            height: '160px',
            overflow: 'hidden',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            '&::after': {
              content: '" "',
              position: 'absolute',
              display: 'block',
              background: 'rgba(0,0,0,.15)',
              top: 0,
              left: 0,
              borderRadius: '6px',
              width: '100%',
              height: '100%',
            },
            '&.xs-only': {
              height: '111px',
            },
          }}
        >
          <img
            src={image}
            alt=""
            style={{
              width: '100%',
              verticalAlign: 'middle',
              borderRadius: '6px',
            }}
          />
          <IconButton
            sx={{
              position: 'absolute',
              bottom: '5px',
              right: '5px',
              zIndex: 3,
              width: '35px',
              height: '35px',
            }}
            onClick={() => {
              handleDeletePhoto(foreshorteningId)
            }}
          >
            <img src={IconDelete} alt="" />
          </IconButton>
          {!lastPhoto ? (
            <IconButton
              sx={{
                position: 'absolute',
                zIndex: 3,
                height: '32px',
                width: '32px',
                left: 52,
                bottom: 4.5,
              }}
              onClick={() => {
                setOrder(index, 'down')
              }}
            >
              <img src={IconArrow} alt="" />
            </IconButton>
          ) : null}
          {index !== 0 ? (
            <IconButton
              sx={{
                position: 'absolute',
                height: '32px',
                width: '32px',
                left: 5,
                bottom: 5,
                zIndex: 3,
              }}
              onClick={() => {
                setOrder(index, 'up')
              }}
            >
              <img
                src={IconArrow}
                alt=""
                style={{
                  transform: 'rotate(180deg)',
                  transformOrigin: '50% 50%',
                }}
              />
            </IconButton>
          ) : null}
        </Box>
        {showHint ? (
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              width: '25px',
              height: '25px',
              zIndex: 6,
              '& .show-hint': {
                opacity: 0,
                transform: 'scale(0)',
                transformOrigin: '85% 100%',
                pointerEvents: 'none',
                transition: '0.25s all ease-in-out',
              },
              '&:hover': {
                '& .show-hint': {
                  pointerEvents: 'auto',
                  opacity: 1,
                  transform: 'scale(1)',
                  transition: '0.25s all ease-in-out',
                },
              },
            }}
          >
            <IconButton
              sx={{
                borderRadius: '50%',
                width: '25px',
                height: '25px',
                background: colorsConfig.mainRed,
                '&:hover': {
                  background: colorsConfig.mainRed,
                },
              }}
            >
              <PriorityHighRounded
                sx={{
                  fill: 'white',
                  fontSize: '15px',
                }}
              />
            </IconButton>
            <TextNormal
              className={'show-hint'}
              sx={{
                px: '12px',
                py: '6px',
                minWidth: '150px',
                borderRadius: '6px',
                background: 'white',
                position: 'absolute',
                bottom: 'calc(100% + 10px)',
                right: '-5px',
                boxShadow: '0 0 12px 0 rgba(0,0,0,.1)',
                '&::after': {
                  content: '" "',
                  display: 'block',
                  position: 'absolute',
                  top: 'calc(100% - 2px)',
                  right: '10px',
                  width: 0,
                  height: 0,
                  borderStyle: 'solid',
                  borderWidth: '10px 7.5px 0 7.5px',
                  borderColor: `white transparent transparent transparent`,
                },
              }}
            >
              {currentPhotoHint}
            </TextNormal>
          </Box>
        ) : null}
      </Box>
    </Grid>
  )
}

export default CarPhotoSorted
