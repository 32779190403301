import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import { useMediaQuery, StepConnector } from '@mui/material'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CoreDataStep from './Steps/CoreDataStep'
import MainDataStep from './Steps/MainDataStep'
import AdditionalInfoStep from './Steps/AdditionalInfoStep'
import BreadcrumbsComponent from '../../components/BreadcrumbsComponent'
import { API_ENDPOINTS } from '../../config/default'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { privateRequest } from '../../utils/request'
import { resetCarData } from '../../store/features/addNewCarSlice/addNewCar.reducer'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import IconHome from '../../assests/img/icons/icon-virtual-garage-home.svg'
import IconCheckmark from '../../assests/img/icons/icon-checkmark.svg'
import { colorsConfig } from '../../config/themeConfig'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import ButtonHollow from '../../ui/Buttons/ButtonHollow'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { getEnumDetails } from '../../store/features/references/references.actions'
import { getCoreValuesFormData, getMainValuesFormData } from './addNewVehicleForm.helpers'
import {
  createCar,
  createCarOperator,
  updateCar,
} from '../../store/features/addNewCarSlice/addNewCar.actions'
import AppraisalStep from './Steps/AppraisalStep'
import { LOCAL_ROLE } from '../../utils/constants/global.constants'
import carApiService from '../../api/carService/carApiService'

const AddNewVehicle = () => {
  const { t } = useTranslation()

  const role = useSelector((state) => state.auth?.role)
  const carFirstData = useSelector((state) => state.addCar.carFirstData)
  const carMainData = useSelector((state) => state.addCar.carMainData)
  const carCreatedId = useSelector((state) => state.addCar.carCreatedId)
  const createdCarData = useSelector((state) => state.addCar.createdCarData)

  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = useState(0)
  const [carSpecsBlocks, setCarSpecsBlocks] = useState([])

  const [showFirstStepErrors, setShowFirstStepErrors] = useState(false)
  const [showSecondStepErrors, setShowSecondStepErrors] = useState(false)

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const navigate = useNavigate()

  const changeCarSpec = (id, value) => {
    setCarSpecsBlocks(
      carSpecsBlocks.map((item) => {
        const newSpecs = item.specs.map((spec) => {
          if (spec['@id'] === id) {
            return {
              ...spec,
              value: value,
            }
          }
          return spec
        })
        return {
          ...item,
          specs: newSpecs,
        }
      }),
    )
  }

  const getCarSpecs = () => {
    privateRequest.get(API_ENDPOINTS.carSpecsOptionsBlocks).then((response) => {
      setCarSpecsBlocks(
        response.data['hydra:member'].map((item) => {
          const newSpecs = item.specs.map((spec) => {
            return {
              ...spec,
              value: null,
            }
          })
          return {
            ...item,
            specs: newSpecs,
          }
        }),
      )
    })
  }

  useEffect(() => {
    getCarSpecs()
    dispatch(setCurrentPageTitle(t('page_title_new_vehicle')))
  }, [i18next.language])

  const handleNext = async () => {
    if (activeStep === 0) {
      if (!carFirstData.valid) {
        setShowFirstStepErrors(true)
        return
      }
      if (role === LOCAL_ROLE.OPERATOR) {
        if (!carFirstData.company) return
        await handleSubmitFirstStepOperator()
      }
    }

    if (activeStep === 1) {
      if (handleSetMainSpecsHasErrors()) {
        window.scrollTo(0, 0)
        setShowSecondStepErrors(true)
        return
      } else {
        if (role === LOCAL_ROLE.COMPANY) {
          await handleSubmitCarFirstData()
        }
        if (role === LOCAL_ROLE.OPERATOR) {
          handleSubmitSecondStepOperator()
        }
      }
    }

    window.scrollTo(0, 0)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)

    if (activeStep === 2) {
      if (role === LOCAL_ROLE.COMPANY) {
        addAdditionalInfo()
        navigate('/virtual-garage')
      }
    }
    if (activeStep === 3) {
      if (role === LOCAL_ROLE.OPERATOR) {
        addAdditionalInfo()
        await carApiService.changeWorkflowSendToDraftCompany(createdCarData?.id)
        navigate('/operator/rvi')
      }
    }
  }

  const handleBack = () => {
    window.scrollTo(0, 0)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const userSteps = [
    {
      label: 'VIN check',
      component: (
        <CoreDataStep
          showErrors={showFirstStepErrors}
          handleNext={() => setActiveStep(activeStep + 1)}
        />
      ),
      nextButtonText: t('button_next_step'),
    },
    {
      label: 'Car Information',
      component: (
        <MainDataStep
          carSpecsBlocks={carSpecsBlocks}
          changeCarSpec={changeCarSpec}
          showErrors={showSecondStepErrors}
        />
      ),
      nextButtonText: t('button_next_step'),
    },
    {
      label: 'Additional Information',
      component: <AdditionalInfoStep />,
      nextButtonText: t('button_park_car_to_virtual_garage'),
      nextButtonTextMobile: t('button_add_car'),
    },
  ]

  const operatorSteps = [
    {
      label: 'VIN check',
      component: (
        <CoreDataStep
          showErrors={showFirstStepErrors}
          handleNext={() => setActiveStep(activeStep + 1)}
        />
      ),
      nextButtonText: t('button_next_step'),
    },
    {
      label: 'Car details',
      component: (
        <MainDataStep
          carSpecsBlocks={carSpecsBlocks}
          changeCarSpec={changeCarSpec}
          showErrors={showSecondStepErrors}
        />
      ),
      nextButtonText: t('button_next_step'),
    },
    {
      label: 'Appraisal',
      component: <AppraisalStep />,
      nextButtonText: t('button_next_step'),
      nextButtonTextMobile: t('button_next_step'),
    },
    {
      label: 'Additional information',
      component: <AdditionalInfoStep />,
      nextButtonText: 'Add car to dealer',
      nextButtonTextMobile: t('button_add_car'),
    },
  ]

  const steps = role === LOCAL_ROLE.OPERATOR ? operatorSteps : userSteps

  const handleSubmitCarFirstData = async () => {
    if (carCreatedId) {
      await dispatch(
        updateCar({
          coreValuesFormData: getCoreValuesFormData(carFirstData),
          mainValuesFormData: getMainValuesFormData(carMainData),
        }),
      )
    } else {
      await dispatch(
        createCar({
          coreValuesFormData: getCoreValuesFormData(carFirstData),
          mainValuesFormData: getMainValuesFormData(carMainData),
        }),
      )
    }
  }

  const handleUpdateCar = () => {
    dispatch(
      updateCar({
        coreValuesFormData: getCoreValuesFormData(carFirstData),
        mainValuesFormData: getMainValuesFormData(carMainData),
      }),
    )
  }

  const handleSubmitFirstStepOperator = async () => {
    const carData = {
      vin: carFirstData.vin.value,
      make: carFirstData.make.id,
      model: carFirstData.model.id,
    }
    const companyData = {
      draftCompany: carFirstData.company?.value?.['@id'],
    }
    await dispatch(createCarOperator({ carData, companyData }))
  }

  const handleSubmitSecondStepOperator = () => {
    dispatch(
      updateCar({
        coreValuesFormData: getCoreValuesFormData(carFirstData),
        mainValuesFormData: getMainValuesFormData(carMainData),
      }),
    )
  }

  const addAdditionalInfo = () => {
    const formData = {
      additionalInfo: carMainData.additionalInfo,
    }
    privateRequest.put(carCreatedId, formData).then((response) => {
      toast.success(t('notification_add_vehicle_success'))
    })
  }

  const handleSetMainSpecsHasErrors = () => {
    if (!carMainData.firstRegistrationDate) return true
    if (!carMainData.mileage) return true
    if (!carMainData.engineType) return true
    if (!carMainData.bodyType) return true
    if (!carMainData.seatsNumber) return true
    if (!carMainData.doorsNumber) return true
    if (!carMainData.driveType) return true
    if (!carMainData.gearbox) return true
    if (!carMainData.origin) return true
    if (!carMainData.engineSize && carMainData.engineType.name !== 'Electric') return true
    return false
  }

  useEffect(() => {
    dispatch(getEnumDetails())
    return () => {
      dispatch(resetCarData())
    }
  }, [])

  return (
    <Box
      sx={{
        px: {
          xs: '16px',
          lg: 0,
        },
        pt: {
          xs: '20px',
          lg: 0,
        },
        pb: {
          xs: '95px',
          lg: 0,
        },
      }}
    >
      <Box sx={{ mb: '20px' }}>
        {role === LOCAL_ROLE.OPERATOR ? (
          <BreadcrumbsComponent
            currentPage={{ name: 'Add car to a dealer' }}
            parentPages={[
              {
                name: 'RVI policies',
                icon: <img src={IconHome} alt="" />,
                link: '/operator/rvi',
              },
            ]}
          />
        ) : (
          <BreadcrumbsComponent
            currentPage={{ name: t('page_title_add_new_vehicle') }}
            parentPages={[
              {
                name: t('page_title_virtual_garage'),
                icon: <img src={IconHome} alt="" />,
                link: '/virtual-garage',
              },
            ]}
          />
        )}
      </Box>

      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            mb: {
              xs: '30px',
              sm: '60px',
            },
          }}
        >
          <Stepper activeStep={activeStep} connector={<StepConnector />}>
            {steps.map((stepItem, index) => {
              const stepProps = {}
              return (
                <Step key={index} {...stepProps}>
                  <Box
                    className={
                      activeStep === index ? 'active' : activeStep > index ? 'finished' : ''
                    }
                    sx={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '6px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: colorsConfig.buttonDisabled,
                      color: colorsConfig.mainBlack,
                      '&.active': {
                        background: colorsConfig.mainBlue,
                        color: 'white',
                      },
                      '&.finished': {
                        background: colorsConfig.mainBlue,
                      },
                    }}
                  >
                    {activeStep > index ? <img src={IconCheckmark} alt="" /> : index + 1}
                  </Box>
                </Step>
              )
              // }
            })}
          </Stepper>
        </Box>
        {activeStep > 0 ? (
          <Box
            sx={{
              display: {
                xs: 'block',
                sm: 'flex',
              },
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '24px',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: '24px',
                fontWeight: 700,
                color: colorsConfig.mainBlack,
              }}
            >
              {carFirstData.make?.name} {carFirstData.model?.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: '16px',
              }}
            >
              VIN: {carFirstData.vin?.value}
            </Typography>
          </Box>
        ) : null}
        <Box>
          {activeStep < steps.length ? steps[activeStep].component : null}
          {activeStep < steps.length ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                pt: 2,
                mt: {
                  xs: '20px',
                  sm: '40px',
                },
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {activeStep !== 0 ? (
                <ButtonHollow
                  color="inherit"
                  onClick={handleBack}
                  sx={{
                    mr: '17px',
                    width: {
                      xs: 'calc(50% - 6px)',
                      sm: '176px',
                      lg: 'auto',
                    },
                  }}
                >
                  {t('button_back')}
                </ButtonHollow>
              ) : null}
              <ButtonLG
                onClick={handleNext}
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {xsOnly && steps[activeStep].nextButtonTextMobile
                  ? steps[activeStep].nextButtonTextMobile
                  : steps[activeStep].nextButtonText}
              </ButtonLG>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default AddNewVehicle
