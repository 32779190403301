import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  download: {
    isLoading: false,
    error: null,
  },
}

const filesSlice = createSlice({
  name: 'filesReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
})

export default filesSlice.reducer
