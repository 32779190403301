import { patchRequest, privateRequest } from '../../utils/request'
import { filterParams, putHeaders } from '../api'

class RviApiService {
  #api

  #patchReq

  #endPoints

  #abortControllers

  constructor({ api, patchReq }) {
    this.#api = api
    this.#patchReq = patchReq
    this.#endPoints = {
      importPolicies: '/rvi/policies/import',
      policyImports: '/rvi/policy-imports',
      policyImport: (id) => `/rvi/policy-imports/${id}`,
      policies: '/rvi/policies',
      policyById: (id) => `/rvi/policies/${id}`,
      archive: (id) => `/rvi/policies/${id}/archive`,
      attachPoliciesToCompany: '/rvi/policies/attach-company-to-policies',
      statusCount: '/workflow-state-counts/policies',
      workflows: {
        confirmToRestitutionStarted: (id) =>
          `/rvi/policies/${id}/workflow/confirm-to-restitution-started`,
        confirmToCancelled: (id) => `/rvi/policies/${id}/workflow/confirm-to-cancelled`,
        confirmToRestitutionInProgress: (id) =>
          `/rvi/policies/${id}/workflow/confirm-to-restitution-in-progress`,
        close: {
          dueToCustomerBuyout: (id) => `/rvi/policies/${id}/workflow/close-due-to-customer-buyout`,
          dueToEarlyContractTermination: (id) =>
            `/rvi/policies/${id}/workflow/close-due-to-early-contract-termination`,
          dueToEndOfContract: (id) => `/rvi/policies/${id}/workflow/close-due-to-end-of-contract`,
          dueToOtherReason: (id) => `/rvi/policies/${id}/workflow/close-due-to-other-reason`,
          dueToRestitution: (id) => `/rvi/policies/${id}/workflow/close-due-to-restitution`,
          dueToTotalDamage: (id) => `/rvi/policies/${id}/workflow/close-due-to-total-damage`,
          dueToVehicleSold: (id) => `/rvi/policies/${id}/workflow/close-due-to-vehicle-sold`,
        },
      },
    }
    this.#abortControllers = {}
  }

  importPolicies = async (data) => {
    const response = await this.#api.post(this.#endPoints.importPolicies, data)
    return response
  }

  getPolicyImports = async (params) => {
    const response = await this.#api.get(this.#endPoints.policyImports, {
      params,
    })
    return response
  }

  getPolicies = async (params, extraQuery, code = 'getPolicies') => {
    this.#abortControllers?.[code]?.abort()
    this.#abortControllers[code] = new AbortController()
    const query = extraQuery || ''
    const response = await this.#api.get(`${this.#endPoints.policies}${query}`, {
      params: filterParams(params),
      signal: this.#abortControllers?.[code]?.signal,
    })
    return response
  }

  getPolicyById = async (policyId) => {
    const response = await this.#api.get(this.#endPoints.policyById(policyId))
    return response
  }

  patchPolicy = async (policyId, data) => {
    const response = await this.#patchReq.patch(this.#endPoints.policyById(policyId), data)
    return response
  }

  getPolicyImport = async (id) => {
    const response = await this.#api.get(this.#endPoints.policyImport(id))
    return response
  }

  attachPoliciesToCompany = async (data) => {
    const response = this.#api.post(this.#endPoints.attachPoliciesToCompany, data)
    return response
  }

  archivePolicy = async (policyId) => {
    const response = await this.#api.put(this.#endPoints.archive(policyId), {
      headers: putHeaders,
    })
    return response
  }

  confirmToRestitutionStarted = async (policyId, data) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.confirmToRestitutionStarted(policyId),
      data,
    )
    return response
  }

  confirmToRestitutionInProgress = async (policyId) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.confirmToRestitutionInProgress(policyId),
      {},
    )
    return response
  }

  confirmToCancel = async (policyId, data) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.confirmToCancelled(policyId),
      data,
    )
    return response
  }

  getPoliciesStateCounts = async () => {
    const response = await this.#api.get(this.#endPoints.statusCount)
    return response
  }

  closeDueToRestitution = async (policyId) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.close.dueToRestitution(policyId),
      {},
    )
    return response
  }

  closeDueToCustomerBuyout = async (policyId) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.close.dueToCustomerBuyout(policyId),
      {},
    )
    return response
  }

  closeDueToEarlyContractTermination = async (policyId) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.close.dueToEarlyContractTermination(policyId),
      {},
    )
    return response
  }

  closeDueToTotalDamage = async (policyId) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.close.dueToTotalDamage(policyId),
      {},
    )
    return response
  }

  closeDueToVehicleSold = async (policyId) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.close.dueToVehicleSold(policyId),
      {},
    )
    return response
  }

  closeDueToEndOfContract = async (policyId) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.close.dueToEndOfContract(policyId),
      {},
    )
    return response
  }

  closeDueToOtherReason = async (policyId, data) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.close.dueToOtherReason(policyId),
      data,
    )
    return response
  }
}

const rviApiService = new RviApiService({ api: privateRequest, patchReq: patchRequest })

export default rviApiService
