import React from 'react'
import { Box, Stack } from '@mui/material'
import { SettingsRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../../config/themeConfig'
import HeaderProfileMenuItem from './HeaderProfileMenuItem'
import iconLogOut from '../../../../assests/img/icons/icon-log-out-black.svg'
import { logOut } from '../../../../store/features/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const HeaderProfileMenu = () => {
  const role = useSelector((state) => state.auth.role)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <Box
      className="header-profile-menu"
      sx={{
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        pt: '60px',
        borderRadius: '8px',
        background: colorsConfig.bgLightBlue,
        minWidth: '220px',
      }}
    >
      <Stack>
        {role === 'COMPANY' ? (
          <HeaderProfileMenuItem
            icon={<SettingsRounded />}
            text="sidebar_account_settings"
            onClick={() => {
              navigate('/profile-management')
            }}
          />
        ) : null}
        <HeaderProfileMenuItem
          icon={<img src={iconLogOut} alt="" />}
          text="sidebar_log_out"
          onClick={() => {
            dispatch(logOut())
          }}
        />
      </Stack>
    </Box>
  )
}

export default HeaderProfileMenu
