import React from 'react'
import { Navigate } from 'react-router-dom'
import SignIn from '../pages/SignIn'
import SignUp from '../pages/SignUp'
import AddNewVehicle from '../pages/AddNewVehicle'
import SendToCompound from '../pages/SendToCompound'
import Appraisal from '../pages/Appraisal'
import EditVehicle from '../pages/EditVehicle'
import Garage from '../pages/VirtualGarage/Garage'
import LandingPage from '../pages/Promo/LandingPage'
import ProfileManagement from '../pages/ProfileManagement/ProfileManagement'
import PasswordRecovery from '../pages/PasswordRecovery'
import VirtualGarage3 from '../pages/VirtualGarage/VirtualGarage3'
import Dashboard from '../pages/Dashboard/Dashboard'
import Auctions from '../pages/Auctions/Auctions'
import Financing from '../pages/Financing/Financing'
import Warranty from '../pages/Warranty/Warranty'
import CarSubscription from '../pages/CarSubscription/CarSubscription'
import StockFinancing from '../pages/StockFinancing/StockFinancing'
import Help from '../pages/Help/Help'
import CarPage from '../pages/CarPage/CarPage'
import AppraisalService from '../pages/AppraisalService/AppraisalService'
import AuctionCars from '../pages/Auctions/AuctionCars'
import AuctionCarPage from '../pages/Auctions/AuctionCarPage'
import OperatorDashboard from '../pages/OperatorPanel/OperatorDashboard'
import OperatorFinancing from '../pages/OperatorPanel/OperatorFinancing/OperatorFinancing'
import OperatorSignUpRequests from '../pages/OperatorPanel/OperatorSignUpRequests'
import OperatorCoinsRequests from '../pages/OperatorPanel/CoinsRequests/OperatorCoinsRequests'
import OperatorDealPage from '../pages/OperatorPanel/Deals/OperatorDealPage/OperatorDealPage'
import PurchasedCarPage from '../pages/DealPage/PurchasedCarPage'
import OperatorFinancingRequest from '../pages/OperatorPanel/OperatorFinancing/LoanApplicationPage/OperatorFinancingRequest'
import InboxNotifications from '../pages/InboxNotifications/InboxNotifications'
import ExpertDashboard from '../pages/ExpertPanel/ExpertDashboard/ExpertDashboard'
import ExpertAppraisal from '../pages/ExpertPanel/ExpertAppraisal'
import CustomerBankResponses from '../pages/FinanceCustomer/CustomerBankResponses'
import Banks from '../pages/OperatorPanel/OperatorFinancing/Banks/Banks'
import LoanPrograms from '../pages/OperatorPanel/OperatorFinancing/LoanPrograms/LoanPrograms'
import ProductTypes from '../pages/OperatorPanel/OperatorFinancing/ProductTypes/ProductTypes'
import Products from '../pages/OperatorPanel/OperatorFinancing/Products/Products'
import AddBank from '../pages/OperatorPanel/OperatorFinancing/Banks/AddBank'
import EditBank from '../pages/OperatorPanel/OperatorFinancing/Banks/EditBank'
import AddProductType from '../pages/OperatorPanel/OperatorFinancing/ProductTypes/AddProductType'
import EditProductType from '../pages/OperatorPanel/OperatorFinancing/ProductTypes/EditProductType'
import AddProduct from '../pages/OperatorPanel/OperatorFinancing/Products/AddProduct'
import EditProduct from '../pages/OperatorPanel/OperatorFinancing/Products/EditProduct'
import AddLoanProgram from '../pages/OperatorPanel/OperatorFinancing/LoanPrograms/AddLoanProgram'
import EditLoanProgram from '../pages/OperatorPanel/OperatorFinancing/LoanPrograms/EditLoanProgram'
import AdminPanelUsers from '../pages/AdminPanel/AdminPanelUsers/AdminPanelUsers'
import CoinPricings from '../pages/AdminPanel/CoinPricings/CoinPricings'
import AdminPanelInteriorConditions from '../pages/AdminPanel/AdminPanelAppraisal/AdminPanelInteriorConditions'
import AdminPanelDocuments from '../pages/AdminPanel/AdminPanelAppraisal/AdminPanelDocuments'
import AdminPanelMechanicalConditions from '../pages/AdminPanel/AdminPanelAppraisal/AdminPanelMechanicalConditions'
import AdminPanelCarParts from '../pages/AdminPanel/AdminPanelAppraisal/AdminPanelCarParts'
import TestMessageWa from '../components/TestMessageWA'
import OperatorAppraisals from '../pages/OperatorPanel/OperatorAppraisals'
import OperatorDeals from '../pages/OperatorPanel/Deals/OperatorDeals'
import AdminPanelUser from '../pages/AdminPanel/AdminPanelUsers/AdminPanelUser'
import GreenPage from '../pages/GreenPage/GreenPage'
import AdminPanelInsurance from '../pages/AdminPanel/AdminPanelInsurance/AdminPanelInsurance'
import AddRisk from '../pages/AdminPanel/AdminPanelInsurance/Risks/AddRisk'
import EditRisk from '../pages/AdminPanel/AdminPanelInsurance/Risks/EditRisk'
import InsuranceRequests from '../pages/OperatorPanel/Insurance/InsuranceRequests/InsuranceRequests'
import InsuranceRequest from '../pages/OperatorPanel/Insurance/InsuranceRequest/InsuranceRequest'
import InsuranceService from '../pages/Insurance/InsuranceService/InsuranceService'
import AdminPanelInsuranceRisks from '../pages/AdminPanel/AdminPanelInsurance/AdminPanelInsuranceRisks'
import AdminPanelInsuranceCompanies from '../pages/AdminPanel/AdminPanelInsurance/Companies/AdminPanelInsuranceCompanies'
import AdminPanelInsuranceCompaniesAdd from '../pages/AdminPanel/AdminPanelInsurance/Companies/AdminPanelInsuranceCompaniesAdd'
import AdminPanelInsuranceCompaniesEdit from '../pages/AdminPanel/AdminPanelInsurance/Companies/AdminPanelInsuranceCompaniesEdit'
import CustomerInsuranceResponses from '../pages/Insurance/CustomerInsurance/CustomerInsuranceResponses'
import Marketplace from '../pages/Marketplace/Marketplace'
import WarrantyProducts from '../pages/AdminPanel/WarrantyProducts/WarrantyProducts'
import WarrantyApplicationsPage from '../pages/Warranty/WarrantyApplications/WarrantyApplicationsPage'
import WarrantyCoveredComponents from '../pages/AdminPanel/WarrantyCoveredComponents/WarrantyCoveredComponents'
import EditWarrantyProduct from '../pages/AdminPanel/WarrantyProducts/EditWarrantyProduct'
import AddCoveredComponent from '../pages/AdminPanel/WarrantyCoveredComponents/AddCoveredComponent'
import EditCoveredComponent from '../pages/AdminPanel/WarrantyCoveredComponents/EditCoveredComponent'
import AuthMobile from '../pages/AuthMobile/AuthMobile'
import RVI from '../pages/RVI/RVI'
import OperatorRVIPolicies from '../pages/RVI/Operator/OperatorRVIPolicies'
import RVIPolicies from '../pages/RVI/Dealer/RVIPolicies'
import RVIPolicy from '../pages/RVI/RVIPolicy/RVIPolicy'
import EditRVIPolicy from '../pages/RVI/Operator/EditRVIPolicy/EditRVIPolicy'
import AddCarToDealer from '../pages/AddNewVehicle/Operator/AddCarToDealer'
import Localization from '../pages/AdminPanel/Localization/Localization'
import Companies from '../pages/Companies/Companies'
import RestituteVehicle from '../pages/RVI/Dealer/RestituteVehicle'
import RVMatrix from '../pages/AdminPanel/RVMatrix/RVMatrix'

export const privateRoutes = [
  { path: '/add-new-vehicle', component: <AddNewVehicle /> },
  {
    path: '/virtual-garage',
    component: <VirtualGarage3 />,
    exact: true,
    operatorPath: '/operator-panel',
    expertPath: '/expert-panel',
  },
  { path: '/cars/:id', component: <CarPage /> },
  { path: '/purchased-cars/:id', component: <PurchasedCarPage /> },
  { path: '/cars/:id/send-to-compound', component: <SendToCompound /> },
  { path: '/cars/:id/appraisal', component: <Appraisal /> },
  { path: '/cars/:id/edit', component: <EditVehicle /> },
  { path: '/cars-on-auction', component: <AuctionCars /> },
  { path: '/cars-on-auction/:id', component: <AuctionCarPage /> },
  { path: '/my-garage', component: <Garage /> },
  { path: '/profile-management', component: <ProfileManagement /> },
  { path: '/dashboard', component: <Dashboard /> },
  { path: '/appraisal', component: <AppraisalService /> },
  { path: '/auctions', component: <Auctions /> },
  { path: '/financing', component: <Financing /> },
  { path: '/insurance', component: <InsuranceService /> },
  { path: '/car-subscription', component: <CarSubscription /> },
  { path: '/stock-financing', component: <StockFinancing /> },
  { path: '/help', component: <Help /> },
  { path: '/notifications', component: <InboxNotifications /> },
  { path: '/test-messaging-with-whatsapp', component: <TestMessageWa /> },
  { path: '/go-greener', component: <GreenPage /> },
  { path: '/marketplace', component: <Marketplace /> },
  { path: '/car-warranty', component: <WarrantyApplicationsPage /> },
  { path: '/rvi', component: <RVIPolicies /> },
  { path: '/rvi/policies/:id/restitute-vehicle', component: <RestituteVehicle /> },
  { path: '/rvi/policies/:id', component: <RVIPolicy /> },
  { path: '/admin/localization', component: <Localization /> },
  {
    path: '*',
    component: <Navigate to="/virtual-garage" replace />,
    operatorComponent: <Navigate to="/operator/dashboard" replace />,
    expertComponent: <Navigate to="/expert-panel" replace />,
    index: true,
  },
]

export const operatorRoutes = [
  { path: '/operator/appraisals', component: <OperatorAppraisals /> },
  { path: '/operator/dashboard', component: <OperatorDashboard /> },
  { path: '/operator/auctions', component: <OperatorDeals /> },
  { path: '/operator/finance-requests', component: <OperatorFinancing /> },
  { path: '/operator/finance-requests/:id', component: <OperatorFinancingRequest /> },
  { path: '/operator/insurance-requests', component: <InsuranceRequests /> },
  { path: '/operator/insurance-requests/:id', component: <InsuranceRequest /> },
  { path: '/operator/sign-up-requests', component: <OperatorSignUpRequests /> },
  { path: '/operator/sign-up-requests/:id', component: <OperatorSignUpRequests /> },
  { path: '/operator/coins-requests', component: <OperatorCoinsRequests /> },
  { path: '/operator/auctions/:id', component: <OperatorDealPage /> },
  { path: '/operator/rvi', component: <OperatorRVIPolicies /> },
  { path: '/operator/rvi/policies/:id', component: <RVIPolicy /> },
  { path: '/operator/rvi/policies/:id/edit', component: <EditRVIPolicy /> },
  { path: '/admin/localization', component: <Localization /> },
  { path: '/operator/add-car-to-dealer', component: <AddNewVehicle /> },
  { path: '/operator/companies', component: <Companies /> },
]

export const publicRoutes = [
  { path: '/finance/customer/loan-applications/:id', component: <CustomerBankResponses /> },
  {
    path: '/insurance/customer/insurance-applications/:id',
    component: <CustomerInsuranceResponses />,
  },
  { path: '/sign-in', component: <SignIn /> },
  { path: '/sign-up', component: <SignUp /> },
  { path: '/go-greener', component: <GreenPage /> },
  { path: '/auth-mobile', component: <AuthMobile /> },
  { path: '/admin/localization', component: <Localization /> },
  { path: '/', component: <LandingPage /> },
  { path: '*', component: <Navigate to="/" /> },
  { path: '/forgot-password', component: <PasswordRecovery /> },
  { path: '*', component: <Navigate to="/sign-in" /> },
]

export const expertRoutes = [
  { path: '/expert/dashboard', component: <ExpertDashboard /> },
  { path: '/cars/:id/appraisal', component: <ExpertAppraisal /> },
]

export const adminRoutes = [
  { path: '/admin/users', component: <AdminPanelUsers /> },
  { path: '/admin/users/:id', component: <AdminPanelUser /> },
  { path: '/admin/coin-pricings', component: <CoinPricings /> },
  { path: '/admin/appraisal/documents', component: <AdminPanelDocuments /> },
  { path: '/admin/appraisal/damages', component: <AdminPanelCarParts /> },
  { path: '/admin/appraisal/interior-conditions', component: <AdminPanelInteriorConditions /> },
  { path: '/admin/appraisal/mechanical-conditions', component: <AdminPanelMechanicalConditions /> },
  { path: '/admin/financing/banks', component: <Banks /> },
  { path: '/admin/financing/banks/add', component: <AddBank /> },
  { path: '/admin/financing/banks/:id/edit', component: <EditBank /> },
  { path: '/admin/financing/loan-programs', component: <LoanPrograms /> },
  { path: '/admin/financing/loan-programs/add', component: <AddLoanProgram /> },
  { path: '/admin/financing/loan-programs/:id/edit', component: <EditLoanProgram /> },
  { path: '/admin/financing/product-types', component: <ProductTypes /> },
  { path: '/admin/financing/product-types/add', component: <AddProductType /> },
  { path: '/admin/financing/product-types/:id/edit', component: <EditProductType /> },
  { path: '/admin/financing/products', component: <Products /> },
  { path: '/admin/financing/products/add', component: <AddProduct /> },
  { path: '/admin/financing/products/:id/edit', component: <EditProduct /> },
  { path: '/admin/financing/rv-matrix', component: <RVMatrix /> },
  { path: '/admin/insurance/risks', component: <AdminPanelInsuranceRisks /> },
  { path: '/admin/insurance/risks/add', component: <AddRisk /> },
  { path: '/admin/insurance/risks/:id/edit', component: <EditRisk /> },
  { path: '/admin/insurance/companies', component: <AdminPanelInsuranceCompanies /> },
  { path: '/admin/insurance/companies/add', component: <AdminPanelInsuranceCompaniesAdd /> },
  { path: '/admin/insurance/companies/:id/edit', component: <AdminPanelInsuranceCompaniesEdit /> },
  { path: '/admin/warranty/products', component: <WarrantyProducts /> },
  { path: '/admin/warranty/products/:id/edit', component: <EditWarrantyProduct /> },
  { path: '/admin/warranty/components', component: <WarrantyCoveredComponents /> },
  { path: '/admin/warranty/components/add', component: <AddCoveredComponent /> },
  { path: '/admin/warranty/components/:id/edit', component: <EditCoveredComponent /> },
  { path: '/admin/localization', component: <Localization /> },
  { path: '*', component: <Navigate to="/admin/users" /> },
]
