import React from 'react'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import { CDN_BASE_URL } from '../../../config/default'
import QualityCheckPhoto from '../QualityCheck/QualityCheckPhoto'
import { Gallery } from 'react-photoswipe-gallery'
import PhotoSectionItem from './PhotoSectionItem'
import TextNormal from '../../../ui/Text/TextNormal'
import { colorsConfig } from '../../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const PhotosSection = () => {
  const { t } = useTranslation()
  const carPhotos = useSelector((state) => state.car.carData.foreshortenings)
  const role = useSelector((state) => state.auth.role)
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        padding: '32px',
        boxShadow: '0 0 24px 0 rgba(0, 0, 0, .1)',
        borderRadius: '6px',
        '&.xs-only': {
          padding: '8px',
        },
      }}
    >
      {carPhotos.length > 0 ? (
        <Gallery>
          <Grid container spacing={xsOnly ? 1 : 2}>
            {carPhotos.map((item, index) => {
              return (
                <Grid item xs={6} md={3} key={index}>
                  <Box
                    className={[
                      role === 'OPERATOR' ? 'quality-check' : '',
                      xsOnly ? 'xs-only' : '',
                    ].join(' ')}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '6px',
                      overflow: 'hidden',
                      height: '160px',
                      '&.quality-check': {
                        position: 'relative',
                        alignItems: 'flex-start',
                        '&> img': {
                          filter: 'brightness(70%)',
                        },
                        '&::after': {
                          content: '" "',
                          position: 'absolute',
                          display: 'block',
                          top: 0,
                          left: 0,
                          background: 'rgba(0,0,0,.1)',
                          borderRadius: '6px',
                          zIndex: -1,
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          pointerEvents: 'none',
                        },
                      },
                      '&.xs-only': {
                        height: 'auto',
                      },
                    }}
                  >
                    <PhotoSectionItem photo={`${CDN_BASE_URL}${item.photo.path}`} />
                    {role === 'OPERATOR' ? <QualityCheckPhoto photoIndex={index} /> : null}
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Gallery>
      ) : (
        <TextNormal
          sx={{
            color: colorsConfig.iconGray,
            textAlign: 'center',
          }}
        >
          {t('empty_car_photos')}
        </TextNormal>
      )}
    </Box>
  )
}

export default PhotosSection
