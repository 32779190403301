import React, { useState } from 'react'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ConfirmRestitutionModal from './ConfirmRestitutionModal'
import RestituteVehicleModal from './RestituteVehicleModal'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import {
  getActivePolicies,
  getPoliciesStatusCount,
} from '../../../store/features/rviDealer/rviDealer.actions'
import UITableActionsList from '../../../ui/UITable/UITableActionsList'
import { policyWorkflowStates } from '../../../utils/constants/workflows.constants'
import CloseRVIContractModal from './CloseRVIContractModal'
import { closePolicyAsDealer } from '../../../store/features/rviPolicy/rviPolicy.actions'
import CloseRunningContractModal from '../Operator/Modals/CloseRunningContractModal'
import { useNavigate } from 'react-router-dom'

const RVIPoliciesActionItems = ({ data }) => {
  const [confirmRestitutionModal, setConfirmRestitutionModal] = useState(false)
  const [restituteVehicleModal, setRestituteVehicleModal] = useState(false)
  const [closeContractModal, setCloseContractModal] = useState(false)
  const [closeRunningContractModal, setCloseRunningContractModal] = useState(false)

  const policy = data?.initialData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleConfirmRestitution = () => {
    setConfirmRestitutionModal(false)
    setRestituteVehicleModal(true)
  }

  const onRestitutionStarted = () => {
    toast.success('Restitution successfully started')
    setRestituteVehicleModal(false)
    dispatch(getActivePolicies())
    dispatch(getPoliciesStatusCount())
  }

  const onClosePolicy = () => {
    setCloseRunningContractModal(false)
    dispatch(getActivePolicies())
    dispatch(getPoliciesStatusCount())
  }

  const actions = [
    {
      name: 'table_action_restitute',
      handleAction: () => navigate(`/rvi/policies/${policy?.id}/restitute-vehicle`),
      workflowStates: [policyWorkflowStates.linked_to_dealer],
    },
    {
      name: 'Close running contract',
      handleAction: () => {
        setCloseRunningContractModal(true)
      },
      workflowStates: [policyWorkflowStates.linked_to_dealer],
    },
  ]

  const filteredActions = actions.filter((action) =>
    action.workflowStates.find((item) => item === policy?.currentWorkflowState),
  )

  return (
    <>
      {filteredActions?.length > 0 ? <UITableActionsList actions={filteredActions} /> : null}

      {confirmRestitutionModal && (
        <ConfirmRestitutionModal
          open={confirmRestitutionModal}
          setOpen={setConfirmRestitutionModal}
          handleAction={handleConfirmRestitution}
        />
      )}
      {restituteVehicleModal && (
        <RestituteVehicleModal
          open={restituteVehicleModal}
          setOpen={setRestituteVehicleModal}
          policy={data?.initialData}
          onClose={onRestitutionStarted}
        />
      )}
      {closeRunningContractModal && (
        <CloseRunningContractModal
          open={closeRunningContractModal}
          setOpen={setCloseRunningContractModal}
          data={policy}
          onClose={onClosePolicy}
        />
      )}
    </>
  )
}

export default RVIPoliciesActionItems
