import React from 'react'
import { Box, TextField } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const UIMatrixCell = ({ value, onChange }) => {
  return (
    <Box
      sx={{
        width: 'calc(100% / 7)',
        borderRight: '1px solid',
        borderColor: colorsConfig.buttonDisabled,
        '&:last-child': {
          border: 'none',
        },
      }}
    >
      <TextField
        value={value}
        sx={{
          height: '100%',
          width: '100%',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      />
    </Box>
  )
}

export default UIMatrixCell
