import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import financeCalculatorApiService from '../../../api/financeCalculatorApiService/financeCalculatorApiService'

export const calculateRV = createAsyncThunk(
  'financing/calculateRV',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await financeCalculatorApiService.calculateRv(data)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
