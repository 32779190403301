import React from 'react'
import { colorsConfig } from '../../../config/themeConfig'
import { Slider, Stack } from '@mui/material'
import UITextRegular from '../../../ui/UIText/UITextRegular'

const FormDurationSlider = ({ value, onChange, disabled, ...props }) => {
  return (
    <Stack gap="10px" sx={{ width: '100%' }}>
      <Stack gap="5px">
        <UITextRegular fontWeight="700" text="Duration" />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <UITextRegular text="12 months" />
          <UITextRegular text="60 months" />
        </Stack>
      </Stack>
      <Slider
        marks
        min={12}
        step={1}
        max={60}
        value={value}
        disabled={disabled}
        valueLabelDisplay="on"
        valueLabelFormat={(val) => `${val} months`}
        onChange={(e, newVal) => {
          onChange(newVal)
        }}
        className={disabled ? 'disabled' : ''}
        sx={{
          width: '100%',
          color: colorsConfig.mainBlue,
          height: '10px',
          '&.disabled': {
            opacity: 0.5,
            pointerEvents: 'none',
          },
          '& .MuiSlider-valueLabel': {
            top: '44px',
            left: '-24px',
            backgroundColor: colorsConfig.mainBlue,
            borderRadius: '8px',
            '&::before': {
              transform: 'translate(-50%, 50%) rotate(135deg)',
              bottom: '100%',
              left: '24%',
            },
          },
          '& .MuiSlider-track': {
            background: colorsConfig.mainBlue,
            border: 'none',
          },
          '& .MuiSlider-rail': {
            background: colorsConfig.bgLightBlue,
            border: 'none',
            boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.10) inset',
          },
          '& .MuiSlider-thumb': {
            width: 0,
            height: 0,
            boxShadow: 'none !important',
            '&:hover': {
              boxShadow: 'none',
            },
            '&::before': {
              display: 'none',
            },
          },
          '& .MuiSlider-mark': {
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            background: '#ECEFF0',
            transform: 'translate(-5px, -50%)',
            opacity: 0,
            '&.MuiSlider-markActive': {
              background: colorsConfig.mainBlue,
            },
          },
        }}
        {...props}
      />
    </Stack>
  )
}

export default FormDurationSlider
