import { createSlice } from '@reduxjs/toolkit'
import getEntriesBuilder from './builders/getEntries.builder'

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  params: {
    page: 1,
    itemsPerPage: 10,
  },
  total: 0,
}

const residualValueMatrixEntriesSlice = createSlice({
  name: 'residualValueMatrixEntriesReducer',
  initialState,
  reducers: {
    setResidualValueMatrixEntriesParams: (state, action) => {
      state.params = action.payload
    },
  },
  extraReducers: (builder) => {
    getEntriesBuilder(builder)
  },
})

export const { setResidualValueMatrixEntriesParams } = residualValueMatrixEntriesSlice.actions

export default residualValueMatrixEntriesSlice.reducer
