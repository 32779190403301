import React from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { colorsConfig } from '../../../../config/themeConfig'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import TextNormal from '../../../../ui/Text/TextNormal'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import { setSelectedPackage, setSelectedRisks } from '../../../../store/features/insuranceSlice'
import RBox from '../../../../ui/RBox'
import SimpleCheckbox from '../../../../ui/SimpleCheckbox'
import PackageItemWrapper from './PackageItemWrapper'
import UITextTitleSmall from '../../../../ui/UIText/UITextTitleSmall'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import IconDollarBlue from '../../../../assests/img/icons/icon-dollar-blue.svg'
import IconRocket from '../../../../assests/img/icons/icon-rocket.svg'
import IconToolkit from '../../../../assests/img/icons/icon-toolkit.svg'
import IconCheckmark from '../../../../assests/img/icons/icon-checkmark-black.svg'
import UIFormInputSelect from '../../../../ui/UIFormComponents/UIFormInputSelect/UIFormInputSelect'

const FormPackages = ({ formData, setFormData }) => {
  const packages = [
    {
      key: 'basic',
      name: 'Basic offer',
    },
    {
      key: 'fastest',
      name: 'Basic offer',
    },
    {
      key: 'custom',
      name: 'Basic offer',
    },
  ]

  return (
    <Grid container spacing="16px">
      <Grid container spacing={'16px'} alignItems={'stretch'}>
        <Grid item xs={12} md={4}>
          <PackageItemWrapper className={formData.selectedPackage === 'basic' ? 'active' : ''}>
            <Stack
              gap="30px"
              justifyContent="space-between"
              sx={{ minHeight: '100%', alignSelf: 'stretch' }}
            >
              <Stack gap="20px">
                <Stack direction="row" gap="5px" alignItems="center">
                  <img src={IconDollarBlue} alt="" />
                  <UITextTitleSmall text="Basic Offer" />
                </Stack>
                <Stack direction="row" gap="5px" alignItems="center">
                  {/*<img src={IconBank} alt="" />*/}
                  <UITextRegular fontWeight="700">
                    Monobank: <span style={{ color: colorsConfig.mainBlue }}>Tasty Loan</span>
                  </UITextRegular>
                </Stack>
                <Stack gap="10px">
                  <Stack direction="row" gap="5px" alignItems="center">
                    <img src={IconCheckmark} alt="" />
                    <UITextRegular text="RVI" />
                  </Stack>
                </Stack>
              </Stack>
              <Stack gap="20px" alignItems="center">
                <UITextRegular>
                  From <span style={{ fontWeight: 700, fontSize: '24px' }}>2 351.30</span> CHF/month
                </UITextRegular>
                <ButtonLG
                  className="select-button"
                  sx={{
                    minWidth: '180px',
                  }}
                  onClick={() => setFormData({ ...formData, selectedPackage: 'basic' })}
                >
                  {formData.selectedPackage === 'basic' ? 'Selected' : 'Choose'}
                </ButtonLG>
              </Stack>
            </Stack>
          </PackageItemWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <PackageItemWrapper
            className={[formData.selectedPackage === 'fastest' ? 'active' : ''].join(' ')}
          >
            <Stack
              gap="30px"
              justifyContent="space-between"
              sx={{ minHeight: '100%', alignSelf: 'stretch' }}
            >
              <Stack gap="20px">
                <Stack direction="row" gap="5px" alignItems="center">
                  <img src={IconRocket} alt="" />
                  <UITextTitleSmall text="Fastest Deal" />
                </Stack>
                <Stack direction="row" gap="5px" alignItems="center">
                  {/*<img src={IconBank} alt="" />*/}
                  <UITextRegular fontWeight="700">
                    Swiss bank: <span style={{ color: colorsConfig.mainBlue }}>Loan+</span>
                  </UITextRegular>
                </Stack>
                <Stack gap="18px">
                  <Stack direction="row" gap="5px" alignItems="center">
                    <img src={IconCheckmark} alt="" />
                    <UITextRegular text="RVI" />
                  </Stack>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <img src={IconCheckmark} alt="" />
                    <UITextRegular text="Warranty" />
                  </Stack>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <img src={IconCheckmark} alt="" />
                    <UITextRegular text="Casco" />
                  </Stack>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <img src={IconCheckmark} alt="" />
                    <UITextRegular text="PPI" />
                  </Stack>
                </Stack>
              </Stack>
              <Stack gap="20px" alignItems="center">
                <UITextRegular>
                  From <span style={{ fontWeight: 700, fontSize: '24px' }}>2 351.30</span> CHF/month
                </UITextRegular>
                <ButtonLG
                  className="select-button"
                  sx={{
                    minWidth: '180px',
                  }}
                  onClick={() => setFormData({ ...formData, selectedPackage: 'fastest' })}
                >
                  {formData.selectedPackage === 'fastest' ? 'Selected' : 'Choose'}
                </ButtonLG>
              </Stack>
            </Stack>
          </PackageItemWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <PackageItemWrapper className={formData.selectedPackage === 'custom' ? 'active' : ''}>
            <Stack
              gap="30px"
              justifyContent="space-between"
              sx={{ minHeight: '100%', alignSelf: 'stretch', width: '100%' }}
            >
              <Stack gap="20px">
                <Stack direction="row" gap="5px" alignItems="center">
                  <img src={IconToolkit} alt="" />
                  <UITextTitleSmall text="Customized Pack" />
                </Stack>
                <UIFormInputSelect
                  fullWidth
                  label="Select bank"
                  options={[
                    {
                      label: 'Swiss bank',
                      value: 'swiss-bank',
                    },
                    {
                      label: 'Monobank',
                      value: 'monobank',
                    },
                  ]}
                  sx={{
                    background: 'white',
                  }}
                />
                <UIFormInputSelect
                  label="Select interest rate"
                  options={[
                    {
                      label: '0.5 %',
                      value: '0.5',
                    },
                    {
                      label: '1.0 %',
                      value: '1.0',
                    },
                    {
                      label: '1.5 %',
                      value: '1.5',
                    },
                    {
                      label: '2.0 %',
                      value: '2.0',
                    },
                  ]}
                  sx={{
                    background: 'white',
                  }}
                />
                <Stack gap="15px">
                  <Stack direction="row" gap="5px" alignItems="center">
                    <SimpleCheckbox
                      icon={IconCheckmark}
                      value={formData.customOptions.rvi}
                      setValue={(newVal) => {
                        setFormData({
                          ...formData,
                          customOptions: { ...formData.customOptions, rvi: newVal },
                        })
                      }}
                      boxStyles={{
                        width: '24px',
                        height: '24px',
                      }}
                      checkboxStyles={{
                        p: '4px',
                      }}
                    />
                    <UITextRegular text="RVI" />
                  </Stack>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <SimpleCheckbox
                      icon={IconCheckmark}
                      value={formData.customOptions.warranty}
                      setValue={(newVal) => {
                        setFormData({
                          ...formData,
                          customOptions: { ...formData.customOptions, warranty: newVal },
                        })
                      }}
                      boxStyles={{
                        width: '24px',
                        height: '24px',
                      }}
                      checkboxStyles={{
                        p: '4px',
                      }}
                    />
                    <UITextRegular text="Warranty" />
                  </Stack>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <SimpleCheckbox
                      icon={IconCheckmark}
                      value={formData.customOptions.rvi}
                      setValue={(newVal) => {
                        setFormData({
                          ...formData,
                          customOptions: { ...formData.customOptions, casco: newVal },
                        })
                      }}
                      boxStyles={{
                        width: '24px',
                        height: '24px',
                      }}
                      checkboxStyles={{
                        p: '4px',
                      }}
                    />
                    <UITextRegular text="Casco" />
                  </Stack>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <SimpleCheckbox
                      icon={IconCheckmark}
                      value={formData.customOptions.ppi}
                      setValue={(newVal) => {
                        setFormData({
                          ...formData,
                          customOptions: { ...formData.customOptions, ppi: newVal },
                        })
                      }}
                      boxStyles={{
                        width: '24px',
                        height: '24px',
                      }}
                      checkboxStyles={{
                        p: '4px',
                      }}
                    />
                    <UITextRegular text="PPI" />
                  </Stack>
                </Stack>
              </Stack>

              <Stack gap="20px" alignItems="center">
                <UITextRegular>
                  From <span style={{ fontWeight: 700, fontSize: '24px' }}>2 351.30</span> CHF/month
                </UITextRegular>
                <ButtonLG
                  className="select-button"
                  sx={{
                    minWidth: '180px',
                  }}
                  onClick={() => setFormData({ ...formData, selectedPackage: 'custom' })}
                >
                  {formData.selectedPackage === 'custom' ? 'Selected' : 'Choose'}
                </ButtonLG>
              </Stack>
            </Stack>
          </PackageItemWrapper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FormPackages
