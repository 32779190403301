import React, { useRef, useState, useEffect } from 'react'
import { Box, Button, useMediaQuery } from '@mui/material'
import IconArrowDown from '../assests/img/icons/icon-arrow-down.svg'
import IconArrowDownWhite from '../assests/img/icons/icon-arrow-white.svg'
import { localesConfig } from '../i18n/langConfig'
import { colorsConfig } from '../config/themeConfig'
import TextNormal from '../ui/Text/TextNormal'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'i18next'
import { patchRequest, privateMultipartRequest, privateRequest } from '../utils/request'
import { API_ENDPOINTS } from '../config/default'
import { setUser } from '../store/features/authSlice'

const LanguageSelect = (props) => {
  const { variant } = props

  const auth = useSelector((state) => state.auth.isAuthenticated)
  const user = useSelector((state) => state.auth.user)
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const dispatch = useDispatch()

  const [locale, setLocale] = useState(i18n.language)

  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef(null)
  const buttonRef = useRef(null)

  const handleChangeUserLocale = (code) => {
    if (!auth) return
    const formData = {
      locale: code,
    }
    privateRequest.put(API_ENDPOINTS.locale, formData).then(() => {
      privateRequest.defaults.headers['Accept-Language'] = code
      privateMultipartRequest.defaults.headers['Accept-Language'] = code
      patchRequest.defaults.headers['Accept-Language'] = code
      dispatch(setUser({ ...user, locale: code }))
    })
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef, buttonRef])

  useEffect(() => {
    if (user && user?.locale) {
      i18n.changeLanguage(user?.locale).then(() => setLocale(user?.locale))
    }
  }, [user])

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Button
        className={tabletOnly ? 'tablet-only' : ''}
        ref={buttonRef}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          '& img': {
            width: variant === 'standard' ? '39px' : '26px',
            borderRadius: variant === 'square' ? '50%' : '',
          },
          '&.tablet-only': {
            '& img': {
              width: variant === 'standard' ? '24px' : '26px',
            },
          },
        }}
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      >
        <img
          src={
            variant === 'standard'
              ? localesConfig.find((item) => item.code === locale).flagIcon
              : localesConfig.find((item) => item.code === locale).flagIconSquare
          }
          alt=""
        />
        <Box
          className={[menuOpen ? 'open' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            width: '11px',
            height: '6px',
            background: `url(${variant === 'square' ? IconArrowDownWhite : IconArrowDown}) no-repeat center`,
            transform: variant === 'square' ? 'rotate(180deg)' : '',
            transition: '0.25s all ease-in-out',
            '&.open': {
              transform: variant === 'square' ? 'rotate(360deg)' : 'rotate(180deg)',
              transition: '0.25s all ease-in-out',
            },
            '&.tablet-only': {
              width: '7px',
              height: '4px',
              backgroundSize: '100% auto',
            },
          }}
        />
      </Button>
      <Box
        ref={menuRef}
        className={menuOpen ? 'open' : ''}
        sx={{
          position: 'absolute',
          top: 'calc(100% + 6px)',
          left: 0,
          borderRadius: '6px',
          overflow: 'hidden',
          zIndex: 3,
          boxShadow: '0 0 12px 0 rgba(0,0,0,.1)',
          transform: 'scale(0)',
          pointerEvents: 'none',
          opacity: '0',
          transformOrigin: '25% 0%',
          transition: '0.25s all ease-in-out',
          '&.open': {
            transform: 'scale(1)',
            pointerEvents: 'auto',
            opacity: '1',
            transition: '0.25s all ease-in-out',
          },
        }}
      >
        {localesConfig.map((item, index) => {
          return (
            <Button
              fullWidth
              key={index}
              className={item.code === locale ? 'selected' : ''}
              disabled={item.code === locale}
              sx={{
                px: '10px',
                py: '3px',
                background: 'white',
                borderRadius: 0,
                borderBottom: '1px solid',
                borderColor: colorsConfig.bgLightBlue,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                textTransform: 'none',
                gap: '10px',
                transition: '0.25s all ease-in-out',
                '&:hover': {
                  background: colorsConfig.bgLightBlue,
                  transition: '0.25s all ease-in-out',
                },
                '&.selected': {
                  background: colorsConfig.bgLightBlue,
                },
                '&:last-child': {
                  borderBottom: 'none',
                },
              }}
              onClick={() => {
                i18n.changeLanguage(item.code).then(() => {
                  setLocale(item.code)
                  setMenuOpen(false)
                  handleChangeUserLocale(item.code)
                })
              }}
            >
              <img
                src={item.flagIcon}
                alt=""
                style={{
                  width: '28px',
                  borderRadius: '4px',
                }}
              />
              <TextNormal>{item.name}</TextNormal>
            </Button>
          )
        })}
      </Box>
    </Box>
  )
}

export default LanguageSelect
