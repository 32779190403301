import React from 'react'
import { Box, Slider, Stack } from '@mui/material'
import TextNormal from '../../../ui/Text/TextNormal'
import { colorsConfig } from '../../../config/themeConfig'
import IconBlueCar from '../../../assests/img/icons/icon-blue-car.svg'
import { useTranslation } from 'react-i18next'
import UITextRegular from '../../../ui/UIText/UITextRegular'

const FormCarSlider = ({ label, value, disabled, onChange, ...props }) => {
  const { t } = useTranslation()

  return (
    <Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '25px',
          mt: '30px',
        }}
      >
        <UITextRegular fontWeight="700">{label}</UITextRegular>
        <TextNormal>{value * 5000 - 5000} km/year</TextNormal>
      </Box>
      <Slider
        marks
        min={1}
        step={1}
        max={10}
        value={value}
        disabled={disabled}
        onChange={(e, newVal) => {
          onChange(newVal === 1 ? 2 : newVal)
        }}
        sx={{
          color: colorsConfig.mainBlue,
          height: '10px',
          '& .MuiSlider-track': {
            background: colorsConfig.mainBlue,
            border: 'none',
          },
          '& .MuiSlider-rail': {
            background: colorsConfig.bgLightBlue,
            border: 'none',
            boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.10) inset',
          },
          '& .MuiSlider-thumb': {
            width: 0,
            height: 0,
            boxShadow: 'none !important',
            '&:hover': {
              boxShadow: 'none',
            },
            '&::before': {
              display: 'none',
            },
            '&::after': {
              content: '" "',
              position: 'absolute',
              display: 'block',
              width: '79px',
              height: '30px',
              background: `url(${IconBlueCar}) no-repeat`,
              borderRadius: '0',
              transform: 'none',
              top: '-35px',
              left: '-79px',
            },
          },
          '& .MuiSlider-mark': {
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            background: '#ECEFF0',
            transform: 'translate(-5px, -50%)',
            opacity: 1,
            '&.MuiSlider-markActive': {
              background: colorsConfig.mainBlue,
            },
          },
        }}
        {...props}
      />
    </Stack>
  )
}

export default FormCarSlider
