import { financeRequest } from '../../utils/request'

class FinanceCalculatorApiService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      rvCalculation: '/rv/calculation',
    }
    this.#abortControllers = {}
  }

  calculateRv = async (data, prefix = 'calculateRV') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.post(this.#endPoints.rvCalculation, data, {
      signal: this.#abortControllers[prefix]?.signal,
    })
    return response
  }
}

const financeCalculatorApiService = new FinanceCalculatorApiService({ api: financeRequest })

export default financeCalculatorApiService
