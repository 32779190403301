import React, { useEffect, useState } from 'react'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import UITextLabelLarge from '../../../ui/UIText/UITextLabelLarge'
import UINoDataIcon from '../../../ui/UIIcons/UINoDataIcon'
import { getFormattedDate } from '../../../utils/date.helpers'
import CollapseBlock from '../../../ui/Accordions/CollapseBlock'
import { vehicleExpertiseNames } from '../../../utils/constants/enums.constants'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import { colorsConfig } from '../../../config/themeConfig'
import IconFile from '../../../assests/img/icons/icon-document.svg'
import { useTranslation } from 'react-i18next'
import { downloadPrivateFile } from '../../../store/features/files/files.actions'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'

const RVIPolicyRestitutionInfo = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const policy = useSelector((state) => state.rviPolicy.data)
  const restitutionDetails = policy?.restitutionDetails ?? {}
  const {
    restitutionDate,
    location,
    vehicleExpertise,
    mileage,
    thirdPartyLink,
    comment,
    vehicleRegistrationCard,
    appraisalDocument,
  } = restitutionDetails ?? {}
  const isLoadingSalesPhotos = useSelector((state) => state.rviPolicy.salesPhotos.isLoading)
  const salesPhotos = useSelector((state) => state.rviPolicy.salesPhotos.data)

  const [collapsed, setCollapsed] = useState(false)

  return (
    <CollapseBlock
      title="Restitution details"
      collapsed={collapsed}
      onCollapseChange={(value) => setCollapsed(value)}
    >
      <Stack gap="20px">
        <Stack direction="row" gap="40px" sx={{ mb: '20px', flexWrap: 'wrap' }}>
          <Stack gap="4px">
            <UITextRegular text="Restitution date" />
            <UITextLabelLarge>
              {getFormattedDate(restitutionDate) ?? <UINoDataIcon isNormalized />}
            </UITextLabelLarge>
          </Stack>
          <Stack gap="4px">
            <UITextRegular text="Location" />
            <UITextLabelLarge>{location ?? <UINoDataIcon isNormalized />}</UITextLabelLarge>
          </Stack>
          <Stack gap="4px">
            <UITextRegular text="Restitution date" />
            <UITextLabelLarge>
              {vehicleExpertiseNames?.[vehicleExpertise] ?? <UINoDataIcon isNormalized />}
            </UITextLabelLarge>
          </Stack>
          <Stack gap="4px">
            <UITextRegular text="Restitution date" />
            <UITextLabelLarge>
              {mileage ? `${sumDelimiter(mileage)} km` : <UINoDataIcon isNormalized />}
            </UITextLabelLarge>
          </Stack>
          <Stack gap="4px">
            <UITextRegular text="Third party link" />
            <UITextRegular
              component="a"
              href={thirdPartyLink}
              target="_blank"
              sx={{ color: colorsConfig.mainBlue }}
            >
              {thirdPartyLink ?? <UINoDataIcon isNormalized />}
            </UITextRegular>
          </Stack>
          <Stack gap="4px">
            <UITextRegular text="Vehicle registration card" />
            <Button
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                px: '15px',
                justifyContent: 'flex-start',
                fontFamily: '"Helvetica", sans-serif',
                textTransform: 'none',
                fontWeight: 700,
                fontSize: '16px',
                py: '2px',
                borderRadius: '8px',
                minHeight: 0,
                color: colorsConfig.mainBlue,
                pl: '10px !important',
                position: 'relative',
                ml: '-10px',
              }}
              onClick={() => dispatch(downloadPrivateFile({ fileId: vehicleRegistrationCard }))}
            >
              <img style={{ width: '15px' }} src={IconFile} alt="" />
              Download
            </Button>
          </Stack>
          <Stack gap="4px">
            <UITextRegular text="Appraisal document" />
            <Button
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                px: '15px',
                fontFamily: '"Helvetica", sans-serif',
                textTransform: 'none',
                fontWeight: 700,
                fontSize: '16px',
                py: '2px',
                borderRadius: '8px',
                minHeight: 0,
                color: colorsConfig.mainBlue,
                pl: '10px !important',
                position: 'relative',
                ml: '-10px',
              }}
              onClick={() => dispatch(downloadPrivateFile({ fileId: appraisalDocument }))}
            >
              <img style={{ width: '15px' }} src={IconFile} alt="" />
              Download
            </Button>
          </Stack>
          <Stack gap="4px">
            <UITextLabelLarge text="Comment" />
            <UITextRegular>{comment ?? <UINoDataIcon isNormalized />}</UITextRegular>
          </Stack>
        </Stack>
        <Grid container spacing="24px">
          <Grid item xs={12}>
            <UITextLabelLarge text="Sales photos" />
          </Grid>
          {isLoadingSalesPhotos ? (
            <Box sx={{ position: 'relative', height: '100px' }}>
              <UIPreloader text="Loading sales photos..." />
            </Box>
          ) : (
            salesPhotos.map((item) => {
              return (
                <Grid key={item?.id} item xs={6} md={3}>
                  <Box
                    sx={{
                      height: '150px',
                      overflow: 'hidden',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      '& img': {
                        width: '100%',
                        objectFit: 'cover',
                      },
                    }}
                  >
                    <img src={item?.signedUrl} alt="" />
                  </Box>
                </Grid>
              )
            })
          )}
        </Grid>
      </Stack>
    </CollapseBlock>
  )
}

export default RVIPolicyRestitutionInfo
