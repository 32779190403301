import React, { useEffect, useState } from 'react'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import { Stack } from '@mui/material'
import UITextHeadlineLarge from '../../../../ui/UIText/UITextHeadlineLarge'
import UIFormInputSelect from '../../../../ui/UIFormComponents/UIFormInputSelect/UIFormInputSelect'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonAttention from '../../../../ui/Buttons/ButtonAttention'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import { closingReasonEnumNames, closingReasonOptions } from '../../policies.constants'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import { colorsConfig } from '../../../../config/themeConfig'
import { closeRunningContract } from '../../../../store/features/rviPolicy/rviPolicy.actions'
import { toast } from 'react-toastify'
import UIPreloader from '../../../../ui/UIPreloader/UIPreloader'

const CloseRunningContractModal = ({ open, setOpen, data, onClose }) => {
  const isLoading = useSelector((state) => state.rviPolicy.closeRunningContract.isLoading)
  const [formData, setFormData] = useState({
    closingReason: '',
    comment: '',
  })
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [showErrors, setShowErrors] = useState(false)
  const [formValid, setFormValid] = useState(false)

  const handleValidate = () => {
    const { closingReason, comment } = formData
    let isValid = true
    if (!closingReason) isValid = false
    if (!comment && closingReason === closingReasonEnumNames.OTHER_REASON) isValid = false
    setFormValid(isValid)
    return isValid
  }

  const handleSubmit = async () => {
    const isValid = handleValidate()
    if (!isValid) {
      setShowErrors(true)
      return
    }
    const res = await dispatch(closeRunningContract({ data: formData, policyId: data?.id }))
    if (closeRunningContract.fulfilled.match(res)) {
      toast.success('The policy has been closed')
      onClose()
    }
  }

  useEffect(() => {
    if (showErrors) {
      handleValidate()
    }
  }, [showErrors, formData])

  return (
    <ModalWrap open={open} setOpen={setOpen}>
      {isLoading && <UIPreloader />}
      <Stack gap="30px">
        <Stack gap="5px">
          <UITextHeadlineLarge text="Close running contract" />
          <UITextRegular
            sx={{ color: colorsConfig.iconGray }}
            text="Are you sure you want to close this contract?"
          />
        </Stack>
        <Stack gap="20px">
          <UIFormInputSelect
            required
            label="Closing reason"
            options={closingReasonOptions}
            value={formData.closingReason}
            onChange={(e) => {
              setFormData({
                ...formData,
                closingReason: e.target.value,
              })
            }}
            showHelperText={!formData.closingReason && showErrors}
            error={!formData.closingReason && showErrors}
            helperText={!formData.closingReason && showErrors ? 'Required field' : ''}
          />
          {formData.closingReason === closingReasonEnumNames.OTHER_REASON ? (
            <TextFieldSmallEdible
              required
              fullWidth
              label="Comment"
              multiline
              rows={4}
              value={formData.comment}
              onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
              showHelperText={
                !formData.comment &&
                formData.closingReason === closingReasonEnumNames.OTHER_REASON &&
                showErrors
              }
              error={
                !formData.comment &&
                formData.closingReason === closingReasonEnumNames.OTHER_REASON &&
                showErrors
              }
              helperText={
                !formData.comment &&
                formData.closingReason === closingReasonEnumNames.OTHER_REASON &&
                showErrors
                  ? 'Please specify the reason'
                  : ''
              }
            />
          ) : null}
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} gap="8px">
          <ButtonHollow sx={{ flex: 1 }} onClick={() => setOpen(false)}>
            {t('button_cancel')}
          </ButtonHollow>
          <ButtonAttention
            disabled={!formValid && showErrors}
            sx={{ flex: 1 }}
            onClick={() => handleSubmit()}
          >
            Confirm
          </ButtonAttention>
        </Stack>
      </Stack>
    </ModalWrap>
  )
}

export default CloseRunningContractModal
