import React, { useState, useEffect } from 'react'
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  Pagination,
  TablePagination,
  Table,
  TextField,
  InputAdornment,
  useMediaQuery,
} from '@mui/material'
import { API_ENDPOINTS } from '../../../config/default'
import { privateRequest } from '../../../utils/request'
import { colorsConfig } from '../../../config/themeConfig'
import Preloader from '../../../components/Preloader'
import { OperatorTableHead, OperatorTableBody } from '../../OperatorPanel/OperatorTable'
import { approveSellingTableHeadCells, paymentRequiredTableHeadCells } from '../DashboardTableData'
import { useSelector } from 'react-redux'
import iconSearch from '../../../assests/img/icons/icon-search.svg'
import { useTranslation } from 'react-i18next'

const DealerTasks = ({ title }) => {
  const { t } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const role = useSelector((state) => state.auth.role)
  const companyId = useSelector((state) => state.auth.companyId)

  const [sort, setSort] = useState({
    field: 0, // Field to sort by
    order: 'desc', // 'asc' or 'desc'
  })

  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState(0)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [approveSelling, setApproveSelling] = useState([])
  const [paymentRequired, setPaymentRequired] = useState([])
  const [paginatedRequests, setPaginatedRequests] = useState([])

  const [searchLine, setSearchLine] = useState('')

  const filterBySearchLine = (request) => {
    // Filter by searchLine
    const searchValue = searchLine.toLowerCase()
    return (
      request.auctionCarId.toString().toLowerCase().includes(searchValue) ||
      request.cells[0].value.toString().toLowerCase().includes(searchValue) ||
      request.cells[1].value.toString().toLowerCase().includes(searchValue) ||
      request.cells[1].caption.toString().toLowerCase().includes(searchValue)
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const actions = [
    {
      title: 'Sell',
      specialConditions: 'has-bids',
      conditions: [
        {
          role: 'COMPANY',
          status: 'auction_workflow.completed',
        },
      ],
      actions: [
        {
          type: 'navigate-auction-car',
          link: `/cars/:id`,
        },
      ],
    },
    {
      title: "Don't sell",
      specialConditions: 'has-bids',
      conditions: [
        {
          role: 'COMPANY',
          status: 'auction_workflow.completed',
        },
      ],
      actions: [
        {
          type: 'navigate-auction-car',
          link: '/cars/:id',
        },
      ],
    },
  ]

  function handleFormatDate(date, symbol) {
    const regex = /^(\d{4})-(\d{2})-(\d{2}).*$/

    const match = date.match(regex)

    const day = match[3]
    const month = match[2]
    const year = match[1]

    return `${day}${symbol}${month}${symbol}${year}`
  }

  const getActionList = (carStatus, isArchived, maxBid) => {
    if (isArchived) {
      return []
    }
    const availableActions = actions.filter((item) => {
      if (item.specialConditions === 'has-bids' && !maxBid) {
        return false
      }
      if (item.specialConditions === 'no-bids' && maxBid) {
        return false
      }
      return item.conditions.filter(
        (condition) => role === condition.role && carStatus === condition.status,
      )[0]
    })
    return availableActions
  }

  const getApproveSelling = () => {
    setLoading(true)

    privateRequest
      .get(
        API_ENDPOINTS.myAuctions(companyId),
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        },
      )
      .then((response) => {
        const filteredArray = response.data[`hydra:member`]
        console.log('getApproveSelling RESPONSE', filteredArray)
        const approveSellingArray = filteredArray
          .filter((request) => request.currentWorkflowState === 'completed')
          .map((item) => {
            const {
              car: {
                id: carId,
                make: { name: makeName },
                model: { name: modelName },
                vin,
              },
              updatedAt: date,
            } = item

            return {
              id: carId,
              link: `/cars/${carId}`,
              auctionCarId: item.car.id,
              date: date,
              greenCar: item.car.engineType === 'electric' || item.car.engineType === 'hybrid',
              cells: [
                {
                  type: 'text',
                  value: carId,
                },
                {
                  type: 'text-with-caption',
                  value: `${makeName} ${modelName}`,
                  caption: `VIN ${vin}`,
                },
                {
                  type: 'text',
                  value: 'Waiting for payment',
                },
                {
                  type: 'text',
                  value: date,
                },
                {
                  type: 'actions',
                  value: getActionList(item.car.combinedStatus, false, item.maxBid),
                },
              ],
            }
          })
        setTimeout(() => setApproveSelling(approveSellingArray), 0)
      })
  }

  const getPaymentRequired = () => {
    setLoading(true)

    privateRequest.get(API_ENDPOINTS.dealsPaymentRequired).then((response) => {
      const filteredArray = response.data[`hydra:member`].filter((item) => !item.car.isCarObtained)
      console.log('getPaymentRequired RESPONSE', filteredArray)
      const paymentRequiredArray = filteredArray.map((item) => {
        const {
          id: dealId,
          car: {
            id: carId,
            make: { name: makeName },
            model: { name: modelName },
            vin,
          },
          updatedAt: date,
        } = item

        return {
          id: carId,
          link: `/purchased-cars/${dealId}`,
          auctionCarId: item.car.id,
          date: date,
          cells: [
            {
              type: 'text',
              value: carId,
            },
            {
              type: 'text-with-caption',
              value: `${makeName} ${modelName}`,
              caption: `VIN ${vin}`,
            },
            {
              type: 'text',
              value: 'Waiting for payment',
            },
            {
              type: 'text',
              value: date,
            },
            {
              type: 'actions',
              value: getActionList(item.car.combinedStatus, false, item.maxBid),
            },
          ],
        }
      })
      setTimeout(() => setPaymentRequired(paymentRequiredArray), 0)
    })
  }

  const filteredApproveSelling = approveSelling.filter(filterBySearchLine)
  const filteredPaymentRequired = paymentRequired.filter(filterBySearchLine)

  const getPaginatedData = (data, page, rowsPerPage) => {
    const sortedData = [...data]
    if (sort.field !== null) {
      sortedData.sort((a, b) => {
        const aValue = a.cells[sort.field].value
        const bValue = b.cells[sort.field].value

        if (isDateWithFormat(aValue) && isDateWithFormat(bValue)) {
          if (sort.order === 'asc') {
            return compareDateStrings(aValue, bValue)
          } else {
            return compareDateStrings(bValue, aValue)
          }
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          if (sort.order === 'asc') {
            return aValue.localeCompare(bValue)
          } else {
            return bValue.localeCompare(aValue)
          }
        } else {
          // Handle comparison for non-string values
          if (sort.order === 'asc') {
            return aValue - bValue
          } else {
            return bValue - aValue
          }
        }
      })
    }

    const startIndex = (page - 1) * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return sortedData.slice(startIndex, endIndex)
  }

  const getTableHeadCells = () => {
    switch (tab) {
      case 0:
        return approveSellingTableHeadCells
      case 1:
        return paymentRequiredTableHeadCells
      default:
        return approveSellingTableHeadCells
    }
  }

  const getDataLength = () => {
    switch (tab) {
      case 0:
        return filteredApproveSelling.length
      case 1:
        return filteredPaymentRequired.length
      default:
        return 0
    }
  }

  const updatePagination = () => {
    setPage(1) // Reset page to 1 when changing tabs
  }

  useEffect(() => {
    // Update data length and paginated requests when switching tabs
    updatePagination()
  }, [tab, rowsPerPage, filteredPaymentRequired, filteredApproveSelling])

  const getCurrentData = () => {
    switch (tab) {
      case 0:
        return filteredApproveSelling
      case 1:
        return filteredPaymentRequired
      default:
        return []
    }
  }

  const getPaginatedRequests = () => {
    const sortedData = getPaginatedData(getCurrentData(), page, rowsPerPage)
    setPaginatedRequests(handleShowContent(sortedData))
  }

  useEffect(() => {
    getApproveSelling()
    getPaymentRequired()
    getPaginatedRequests()
  }, [])

  useEffect(() => {
    setLoading(false)
  }, [approveSelling, paymentRequired, loading])

  const getMarkerColor = (createdAt, fill, stroke) => {
    const empty = !fill || !stroke

    const createdAtTime = new Date(createdAt).getTime()
    const currentTime = new Date().getTime()
    const timeDifferenceInHours = (currentTime - createdAtTime) / 3600000

    if (empty) {
      if (timeDifferenceInHours > 24) {
        fill = '#AC0000'
        stroke = '#B83629' // Red color
      } else if (timeDifferenceInHours > 20) {
        fill = '#F5BD4F'
        stroke = '#D69E3C' // Yellow color
      } else {
        fill = '#4DED6D'
        stroke = '#3CC457' // Green color
      }
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <circle cx="10" cy="10" r="9" fill={fill} stroke={stroke} strokeWidth="0.25" />
      </svg>
    )
  }

  const hasRequestsOver24Hours = (tab) => {
    const requests = tab === 0 ? filteredApproveSelling : tab === 1 ? filteredPaymentRequired : null
    const currentTime = new Date()
    const twentyFourHoursAgo = new Date(currentTime.getTime() - 24 * 60 * 60 * 1000)

    return requests.some((request) => {
      const requestTime = new Date(request.cells[request.cells.length - 2].value)
      return requestTime < twentyFourHoursAgo
    })
  }

  const isDateWithFormat = (dateStr) => {
    const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/
    return regex.test(dateStr)
  }

  const compareDateStrings = (dateStr1, dateStr2) => {
    const [day1, month1, year1] = dateStr1.split('.')
    const [day2, month2, year2] = dateStr2.split('.')

    if (year1 !== year2) {
      return parseInt(year1) - parseInt(year2)
    }

    if (month1 !== month2) {
      return parseInt(month1) - parseInt(month2)
    }

    return parseInt(day1) - parseInt(day2)
  }

  const handleShowContent = (data) => {
    const res = data.map((request) => {
      const modifiedCells = request.cells.map((cell, index) => {
        if (index === request.cells.length - 2) {
          return {
            ...cell,
            value: (
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <Box
                  sx={{
                    marginTop: '1px',
                  }}
                >
                  {getMarkerColor(request.date)}
                </Box>
                {handleFormatDate(cell.value, '.')}
              </Box>
            ),
          }
        }
        return cell
      })
      return {
        ...request,
        cells: modifiedCells,
      }
    })

    return res
  }

  const handleSortRequest = (field) => {
    if (sort.field === field) {
      setSort({
        ...sort,
        order: sort.order === 'asc' ? 'desc' : 'asc',
      })
    } else {
      setSort({
        field,
        order: 'asc',
      })
    }
  }

  const handleSortClick = (columnIndex, order) => {
    setSort({
      field: columnIndex,
      order: order,
    })
  }

  const sections = [
    {
      id: 1,
      name: `${t('tab_approve_selling')} (${filteredApproveSelling.length})`,
    },
    {
      id: 2,
      name: `${t('tab_payment_required')} (${filteredPaymentRequired.length})`,
    },
  ]

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
      sx={{
        py: '37px',
        px: '30px',
        borderRadius: '6px',
        boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.10)',
        '&.xs-only': {
          px: '8px',
          pt: '45px',
          pb: 0,
        },
        '&.tablet-only': {
          p: '16px',
        },
      }}
    >
      <Container
        className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
        sx={{
          margin: 0,
          '&.xs-only': {
            px: 0,
          },
          '&.tablet-only': {
            px: 0,
          },
        }}
      >
        {xsOnly ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: '15px',
            }}
          >
            <Typography
              className={xsOnly ? 'xs-only' : ''}
              variant="body1"
              sx={{
                color: colorsConfig.mainBlack,
                fontSize: '40px',
                fontWeight: 700,
                marginRight: '20px',
                marginBottom: '10px',
                width: 1,
                '&.xs-only': {
                  width: 'auto',
                  fontSize: '24px',
                },
              }}
            >
              {t('title_tasks')}
            </Typography>
            {!loading ? (
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: '24px',
                  justifyContent: 'space-between',
                  '& .MuiBox-root': {
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiTypography-root': {
                      whiteSpace: 'nowrap',
                    },
                  },
                  marginBottom: '20px',
                  '&.xs-only': {
                    mb: 0,
                    width: '200px',
                    gap: 0,
                  },
                }}
              >
                <TextField
                  id="search"
                  label={t('form_input_label_search')}
                  size="small"
                  value={searchLine}
                  onChange={(e) => {
                    setSearchLine(e.target.value)
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      width: '205px',
                      height: '38px',
                      boxSizing: 'border-box',
                      borderRadius: '6px',
                      borderColor: colorsConfig.mainBlack,
                      color: colorsConfig.mainBlack,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '6px',
                      borderColor: colorsConfig.mainBlack,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={iconSearch} alt="" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            ) : null}
          </Box>
        ) : null}
        <Typography
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          variant="body1"
          sx={{
            color: colorsConfig.mainBlack,
            fontSize: '40px',
            fontWeight: 700,
            marginRight: '20px',
            marginBottom: '10px',
            width: 1,
            '&.xs-only': {
              display: 'none',
            },
            '&.tablet-only': {
              fontSize: '32px',
            },
          }}
        >
          {t('title_tasks')}
        </Typography>
        {loading ? (
          <Preloader type="gooey" text={t('preloader_loading_the_requests')} />
        ) : (
          <>
            <Box
              className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
              sx={{
                width: '100%',
                display: 'flex',
                gap: '24px',
                justifyContent: 'space-between',
                '& .MuiBox-root': {
                  display: 'flex',
                  alignItems: 'center',
                  '& .MuiTypography-root': {
                    whiteSpace: 'nowrap',
                  },
                },
                marginBottom: '20px',
                '&.xs-only': {
                  display: 'none',
                },
                '&.tablet-only': {
                  gap: '12px',
                },
              }}
            >
              <TextField
                id="search"
                label={t('form_input_label_search')}
                size="small"
                value={searchLine}
                onChange={(e) => {
                  setSearchLine(e.target.value)
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    width: '205px',
                    height: '38px',
                    boxSizing: 'border-box',
                    borderRadius: '6px',
                    borderColor: colorsConfig.mainBlack,
                    color: colorsConfig.mainBlack,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '6px',
                    borderColor: colorsConfig.mainBlack,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={iconSearch} alt="" />
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
                sx={{
                  display: 'flex',
                  gap: '24px',
                  '&.xs-only': {
                    display: 'none !important',
                  },
                  '&.tablet-only': {
                    gap: '12px',
                    '& p': {
                      fontSize: '14px',
                    },
                  },
                }}
              >
                <Box>
                  {getMarkerColor('', '#4DED6D', '#3CC457')}
                  <Typography>—{t('p_new_request')}</Typography>
                </Box>
                <Box>
                  {getMarkerColor('', '#F5BD4F', '#D69E3C')}
                  <Typography>—{t('p_approaching_deadline')}</Typography>
                </Box>
                <Box>
                  {getMarkerColor('', '#AC0000', '#B83629')}
                  <Typography>—{t('p_action_required')}</Typography>
                </Box>
              </Box>
            </Box>
            <Tabs
              className={xsOnly ? 'xs-only' : ''}
              value={tab}
              onChange={(e, newVal) => {
                setTab(newVal)
              }}
              variant="scrollable"
              sx={{
                position: 'relative',
                marginBottom: '40px',
                width: '100%',
                '& .MuiTabs-indicator': {
                  background: colorsConfig.mainGreen,
                },
                '& .MuiTab-textColorPrimary': {
                  color: colorsConfig.mainBlack,
                  textTransform: 'none',
                  fontFamily: '"Helvetica", sans-serif',
                  fontSize: '16px',
                  fontWeight: 500,
                  paddingLeft: '15px',
                },
                '& .Mui-selected': {
                  color: colorsConfig.mainBlack,
                },
                '& .MuiButtonBase-root': {
                  background: colorsConfig.bgLightBlue,
                  fontSize: '10px',
                },
                ' .MuiButtonBase-root': {
                  height: '41px',
                  padding: '0 30px',
                },
                '& .MuiTabScrollButton-root': {
                  height: '48px',
                },
                '& .MuiTouchRipple-root': {
                  height: '48px',
                },
                '&.xs-only': {
                  width: '100%',
                  ' .MuiButtonBase-root': {
                    p: 0,
                  },
                },
              }}
            >
              {sections.map((section, index) => {
                return (
                  <Tab
                    className={xsOnly ? 'xs-only' : ''}
                    key={index}
                    label={
                      <div>
                        {section.name}
                        {hasRequestsOver24Hours(index) && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '8px',
                              right: '17px',
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                            >
                              <circle cx="5" cy="5" r="5" fill="#AC0000" />
                            </svg>
                          </Box>
                        )}
                      </div>
                    }
                    sx={{
                      fontSize: '13px',
                      '&.xs-only': {
                        flex: 1,
                      },
                    }}
                  />
                )
              })}
            </Tabs>
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                '& .MuiPaper-root': {
                  marginBottom: '30px',
                },
                '& .MuiAccordionSummary-gutters': {
                  height: '69px',
                },
                '&.xs-only': {
                  overflow: 'auto',
                  maxWidth: 'calc(100vw - 48px)',
                },
              }}
            >
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  marginBottom: '40px',
                  '&.xs-only': {
                    minWidth: '800px',
                  },
                }}
              >
                {tab === 0 && (
                  <Table>
                    <OperatorTableHead
                      tableCells={getTableHeadCells()}
                      sort={sort}
                      setSort={setSort}
                      onSortRequest={handleSortRequest}
                      handleSortClick={handleSortClick}
                    />
                    <OperatorTableBody
                      content={handleShowContent(
                        getPaginatedData(filteredApproveSelling, page, rowsPerPage),
                      )}
                      getActionList={getActionList}
                    />
                  </Table>
                )}
                {tab === 1 && (
                  <Table>
                    <OperatorTableHead
                      tableCells={getTableHeadCells()}
                      sort={sort}
                      setSort={setSort}
                      onSortRequest={handleSortRequest}
                      handleSortClick={handleSortClick}
                    />
                    <OperatorTableBody
                      content={handleShowContent(
                        getPaginatedData(filteredPaymentRequired, page, rowsPerPage),
                      )}
                      cars={getCurrentData()}
                      getActionList={getActionList}
                    />
                  </Table>
                )}
              </Box>
              {getDataLength() > 0 ? (
                <Box
                  sx={{
                    '& .MuiToolbar-root': {
                      paddingLeft: 0,
                    },
                    '& .MuiTablePagination-spacer': {
                      display: 'none',
                    },
                    '& .MuiTablePagination-displayedRows': {
                      display: 'flex',
                      flexGrow: 1,
                      justifyContent: 'flex-end',
                      fontSize: '12px',
                    },
                    '& .MuiInputBase-root': {
                      order: -1,
                      margin: 'auto 10px',
                      fontSize: '12px',
                      '& .MuiSvgIcon-root': {
                        fontSize: '16px',
                        marginRight: '10px',
                      },
                      background: 'white',
                      border: `1px solid ${colorsConfig.bgLightBlue}`,
                      borderRadius: '4px',
                      padding: '7px 15px 7px 13px',
                    },
                    '& .MuiPagination-root': {
                      marginLeft: '12px',
                    },
                    '& .MuiPaginationItem-previousNext': {
                      padding: '8px 12px',
                      '& .MuiSvgIcon-root': {
                        display: 'none',
                      },
                      "&[aria-label='Go to previous page']": {
                        '&::before': {
                          content: "'Previous'",
                        },
                      },
                      "&[aria-label='Go to next page']": {
                        '&::before': {
                          content: "'Next'",
                        },
                      },
                      '&.Mui-disabled': {
                        opacity: 1,
                      },
                    },
                    '& .MuiPaginationItem-root': {
                      background: colorsConfig.bgLightBlue,
                      border: 'none',
                      fontSize: '12px',

                      '&.MuiPaginationItem-previousNext': {
                        background: 'white',
                        border: `1px solid ${colorsConfig.bgLightBlue}`,
                      },
                    },
                    '& .MuiPaginationItem-page.Mui-selected': {
                      background: colorsConfig.mainBlue,
                      color: 'white',
                      '&:hover': {
                        background: 'rgb(21, 101, 192)',
                      },
                    },
                    '& .MuiTablePagination-selectLabel': {
                      fontSize: '12px',
                    },
                    '& .MuiTablePagination-select': {
                      // paddingRight: '18px',
                    },
                    '& .MuiSelect-select': {
                      padding: 0,
                      paddingRight: '12px',
                      '&:focus': {
                        background: 'none',
                      },
                    },
                  }}
                >
                  <TablePagination
                    component="div"
                    count={getDataLength()}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton={false}
                    showLastButton={false}
                    ActionsComponent={() => (
                      <>
                        <p
                          className="MuiTablePagination-selectLabel css-pdct74-MuiTablePagination-selectLabel"
                          id=":rh:"
                          style={{
                            display: 'flex',
                            order: -2,
                          }}
                        >
                          Show
                        </p>
                        <Pagination
                          count={Math.ceil(getDataLength() / rowsPerPage)}
                          variant="outlined"
                          shape="rounded"
                          page={page}
                          onChange={(event, newPage) => handleChangePage(event, newPage)}
                        />
                      </>
                    )}
                    labelRowsPerPage="entries"
                    labelDisplayedRows={({ count }) => {
                      if (xsOnly) return ''
                      const from = (page - 1) * rowsPerPage + 1
                      const to = Math.min(page * rowsPerPage, getDataLength())

                      if (!count) return `Show 0 entries`

                      return `Show ${from}-${to} of ${count} entries`
                    }}
                  />
                </Box>
              ) : null}
            </Box>
          </>
        )}
      </Container>
    </Box>
  )
}

export default DealerTasks
