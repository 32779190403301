import React from 'react'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import { FileUploader } from 'react-drag-drop-files'
import { Box, Stack, Typography } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import IconUpload from '../../../assests/img/icons/icon-upload.svg'
import { useTranslation } from 'react-i18next'
import UITextBodySmall from '../../../ui/UIText/UITextBodySmall'

const FormUploadPhotos = ({ label, handleSetFiles, error, helperText }) => {
  const { t } = useTranslation()
  return (
    <Stack gap="10px">
      <UITextRegular sx={{ fontWeight: 700 }} text={label} />
      <Stack gap="5px">
        <FileUploader
          handleChange={handleSetFiles}
          name="file"
          types={['JPG', 'PNG', 'jpeg']}
          hoverTitle="Drop here"
          label="Upload or drop all the photos here"
          classes="upload-picture"
          multiple
          maxSize={10}
          children={
            <Box
              sx={{
                width: '239px',
                height: '160px',
                background: colorsConfig.darkGray,
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  mb: '5px',
                }}
              >
                <img src={IconUpload} alt="" />
              </Box>
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '18px',
                  fontWeight: 700,
                }}
              >
                {t('button_upload')}
              </Typography>
            </Box>
          }
        />
        {error ? (
          <UITextBodySmall
            text={helperText}
            sx={{ color: colorsConfig.mainRed, fontSize: '12px' }}
          />
        ) : null}
      </Stack>
    </Stack>
  )
}

export default FormUploadPhotos
