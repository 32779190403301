import React from 'react'
import { Box, IconButton, Stack, useMediaQuery } from '@mui/material'
import { ExpandMoreRounded } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'
import RBox from '../RBox'
import TextBoldNormal from '../Text/TextBoldNormal'
import TextNormal from '../Text/TextNormal'

const CollapseBlock = ({
  collapsed,
  onCollapseChange,
  title,
  description,
  saved,
  disabled,
  counter,
  titleTrailing,
  children,
}) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <RBox
      className={disabled ? 'disabled' : ''}
      sx={{
        overflow: 'hidden',
        position: 'relative',
        background: colorsConfig.bgLightBlue,
        '&.disabled': {
          pointerEvents: 'none',
          opacity: 0.5,
          transition: '0.25s all ease-in-out',
        },
      }}
    >
      <RBox
        sx={{
          height: '79px',
          py: '17px',
          px: '29px',
          border: '1px solid #DCDCDC',
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          background: 'white',
          zIndex: 2,
          '&.xs-only': {
            px: '16px',
          },
          '&.tablet-only': {
            px: '16px',
          },
        }}
      >
        <Stack direction="row" gap="40px">
          <Box>
            <TextBoldNormal
              sx={{
                color: colorsConfig.mainBlue,
                fontSize: '18px',
              }}
            >
              {title}{' '}
              {counter ? (
                <span style={{ color: colorsConfig.mainGreen, fontWeight: 700 }}>({counter})</span>
              ) : null}
            </TextBoldNormal>
            <TextNormal>{description}</TextNormal>
          </Box>
          {titleTrailing ?? null}
        </Stack>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {saved ? (
            <TextBoldNormal
              sx={{
                fontSize: '18px',
                color: colorsConfig.mainGreen,
                mr: '20px',
              }}
            >
              Saved
            </TextBoldNormal>
          ) : null}
          <IconButton
            className={!collapsed ? 'open' : ''}
            sx={{
              position: 'relative',
              mr: '-15px',
              transition: '0.25s all ease-in-out',
              '&.open': {
                transform: 'rotate(180deg)',
                transition: '0.25s all ease-in-out',
              },
            }}
            onClick={() => {
              onCollapseChange(!collapsed)
            }}
          >
            <ExpandMoreRounded
              sx={{
                fontSize: '23px',
                width: '40px',
                height: '40px',
              }}
            />
          </IconButton>
        </Box>
      </RBox>
      <Box
        className={[collapsed ? 'collapsed' : '', xsOnly ? 'xs-only' : ''].join(' ')}
        sx={{
          paddingTop: '119px',
          mt: '-79px',
          position: 'relative',
          px: '40px',
          pb: '35px',
          borderRadius: '6px',
          border: '1px solid',
          borderColor: colorsConfig.buttonDisabled,
          background: colorsConfig.bgLightBlue,
          transition: '0.3s all ease-in-out',
          '&.collapsed': {
            mt: '-70%',
            display: 'none',
            opacity: '0',
            pointerEvents: 'none',
            transition: '0.3s all ease-in-out',
          },
          '&.xs-only': {
            px: '20px',
          },
          '&.tablet-only': {
            px: '16px',
          },
        }}
      >
        {disabled ? null : children}
      </Box>
    </RBox>
  )
}

export default CollapseBlock
