import { getRestitutionSalesPhotos } from '../rviPolicy.actions'

export default (builder) => {
  builder
    .addCase(getRestitutionSalesPhotos.pending, (state) => {
      state.salesPhotos.isLoading = true
    })
    .addCase(getRestitutionSalesPhotos.fulfilled, (state, action) => {
      state.salesPhotos.isLoading = false
      state.salesPhotos.error = null
      state.salesPhotos.data = action.payload
    })
    .addCase(getRestitutionSalesPhotos.rejected, (state, action) => {
      state.salesPhotos.isLoading = false
      state.salesPhotos.error = action.payload
    })
}
