import { createSlice } from '@reduxjs/toolkit'
import importPoliciesBuilder from './builders/importPolicies.builder'
import archiveRviPolicyBuilder from './builders/archiveRviPolicy.builder'
import attachPolicyToDealerBuilder from './builders/attachPolicyToDealer.builder'
import rviApproveResititutionBuilder from './builders/rviApproveResititution.builder'
import rviCloseRestitutionBuilder from './builders/rviCloseRestitution.builder'
import getPoliciesBuilder from './builders/getPolicies.builder'
import getPolicyImportsBuilder from './builders/getPolicyImports.builder'
import getImportedPoliciesBuilder from './builders/getImportedPolicies.builder'
import linkPoliciesToDealersBuilder from './builders/linkPoliciesToDealers.builder'
import { policyWorkflowStates } from '../../../utils/constants/workflows.constants'

export const closedPoliciesCurrentWorkflowStates = [
  policyWorkflowStates.closed,
  policyWorkflowStates.closed_by_dealer,
  policyWorkflowStates.closed_due_to_end_of_contract,
  policyWorkflowStates.cancelled,
]

const initialState = {
  importPolicies: {
    isLoading: false,
    error: null,
    importResult: null,
    imported: {
      isLoading: false,
      error: null,
      data: null,
    },
    linkPolicies: {
      isLoading: false,
      error: null,
    },
  },
  unlinkedPolicies: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      isArchived: false,
      page: 1,
      itemsPerPage: 10,
      policyExtendedSearch: '',
      'exists[company]': false,
      'order[createdAt]': 'desc',
    },
  },
  linkedPolicies: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      isArchived: false,
      page: 1,
      itemsPerPage: 10,
      policyExtendedSearch: '',
      'order[createdAt]': 'desc',
    },
  },
  policiesInRestitution: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      isArchived: false,
      page: 1,
      itemsPerPage: 10,
      policyExtendedSearch: '',
      'exists[company]': true,
      'order[createdAt]': 'desc',
    },
  },
  closedPolicies: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      isArchived: false,
      page: 1,
      itemsPerPage: 10,
      policyExtendedSearch: '',
      'order[createdAt]': 'desc',
    },
    currentWorkflowStates: [policyWorkflowStates.closed],
  },
  cancelledPolicies: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      isArchived: false,
      page: 1,
      itemsPerPage: 10,
      policyExtendedSearch: '',
      'order[createdAt]': 'desc',
    },
    currentWorkflowStates: [policyWorkflowStates.cancelled],
  },
  policyImports: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      page: 1,
      itemsPerPage: 10,
      'order[createdAt]': 'desc',
    },
  },
  statusCount: {
    unlinkedPolicies: 0,
    linkedPolicies: 0,
    policiesInRestitution: 0,
    closedPolicies: 0,
    cancelledPolicies: 0,
  },
  archivePolicy: {
    isLoading: false,
    error: null,
  },
  attachPolicy: {
    isLoading: false,
    error: null,
  },
  approveRestitution: {
    isLoading: false,
    error: null,
  },
  closeRestitution: {
    isLoading: false,
    error: null,
  },
}

const rviOperatorSlice = createSlice({
  name: 'rviOperatorReducer',
  initialState,
  reducers: {
    setUnlinkedPoliciesParams: (state, action) => {
      state.unlinkedPolicies.params = action.payload
    },
    setLinkedPoliciesParams: (state, action) => {
      state.linkedPolicies.params = action.payload
    },
    setPoliciesInRestitutionParams: (state, action) => {
      state.policiesInRestitution.params = action.payload
    },
    setClosedPoliciesParams: (state, action) => {
      state.closedPolicies.params = action.payload
    },
    setCancelledPoliciesParams: (state, action) => {
      state.cancelledPolicies.params = action.payload
    },
    setClosedPolicyCurrentWorkflowStates: (state, action) => {
      state.closedPolicies.currentWorkflowStates = action.payload
      state.closedPolicies.params.page = 1
    },
    setPolicyImportsParams: (state, action) => {
      state.policyImports.params = action.payload
    },
    setImportedPolicyMatchedCompany: (state, action) => {
      const { policyId, value } = action.payload
      const updatePolicy = state.importPolicies.imported.data?.policies?.find(
        (item) => item?.id === policyId,
      )
      if (updatePolicy) {
        updatePolicy.matchedCompany = value
      }
    },
  },
  extraReducers: (builder) => {
    getPoliciesBuilder(builder)
    importPoliciesBuilder(builder)
    archiveRviPolicyBuilder(builder)
    attachPolicyToDealerBuilder(builder)
    rviApproveResititutionBuilder(builder)
    rviCloseRestitutionBuilder(builder)
    getPolicyImportsBuilder(builder)
    getImportedPoliciesBuilder(builder)
    linkPoliciesToDealersBuilder(builder)
  },
})

export const {
  setPoliciesInRestitutionParams,
  setClosedPoliciesParams,
  setUnlinkedPoliciesParams,
  setLinkedPoliciesParams,
  setPolicyImportsParams,
  setCancelledPoliciesParams,
  setImportedPolicyMatchedCompany,
  setClosedPolicyCurrentWorkflowStates,
} = rviOperatorSlice.actions

export default rviOperatorSlice.reducer
