import { getPoliciesStatusCount } from '../rviDealer.actions'

export default (builder) => {
  builder
    // .addCase(.pending, (state) => {
    //
    // })
    .addCase(getPoliciesStatusCount.fulfilled, (state, action) => {
      state.statusCount = action.payload
    })
  // .addCase(.rejected, (state, action) => {
  //
  // })
}
