import React, { useEffect, useState } from 'react'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import { Box, Stack, useMediaQuery } from '@mui/material'
import { HistoryToggleOffRounded, UploadFileRounded } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import SearchInput from '../../../components/Search/SearchInput'
import AddButton from '../../../ui/Buttons/AddButton'
import UploadRVIPoliciesModal from './Modals/UploadRVIPoliciesModal/UploadRVIPoliciesModal'
import {
  getClosedPoliciesTableData,
  getOperatorRviRestitutionInProgressTableData,
  getOperatorRviTableData,
} from '../../../utils/helpers/table.helpers'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import {
  getCancelledPolicies,
  getClosedPolicies,
  getLinkedPolicies,
  getPoliciesInRestitution,
  getPoliciesStatusCount,
  getUnlinkedPolicies,
} from '../../../store/features/rviOperator/rviOperator.actions'
import UITable from '../../../ui/UITable/UITable'
import {
  awaitingRestitutionPoliciesHead,
  policiesOperatorTableHead,
} from '../../../utils/constants/table.constants'
import OperatorRVIPoliciesActionItems from './RVIPoliciesActions/OperatorRVIPoliciesActionItems'
import UITabs from '../../../ui/UITabs/UITabs'
import UITab from '../../../ui/UITabs/UITab'
import RestitutionInProgressActionItems from './RVIPoliciesActions/RestitutionInProgressActionItems'
import ClosedPoliciesActionItems from './RVIPoliciesActions/ClosedPoliciesActionItems'
import {
  closedPoliciesCurrentWorkflowStates,
  setCancelledPoliciesParams,
  setClosedPoliciesParams,
  setClosedPolicyCurrentWorkflowStates,
  setLinkedPoliciesParams,
  setPoliciesInRestitutionParams,
  setUnlinkedPoliciesParams,
} from '../../../store/features/rviOperator/rviOperator.reducer'
import UITableWrapper from '../../../ui/UITable/UITableWrapper'
import UITablePagination from '../../../ui/UITable/UITablePagination'
import OperatorMarkers from '../../OperatorPanel/OperatorPanelComponents/OperatorMarkers'
import UITextButton from '../../../ui/UIButtons/UITextButton'
import UploadHistoryModal from './Modals/UploadHistoryModal/UploadHistoryModal'
import ImportedPoliciesModal from './Modals/ImportedPoliciesModal/ImportedPoliciesModal'
import LinkedPoliciesActionItems from './RVIPoliciesActions/LinkedPoliciesActionItems'
import { policyWorkflowStateNames } from '../../../utils/constants/workflows.constants'
import FiltersInput from '../../../components/FiltersInput/FiltersInput'
import CancelledPoliciesActionItems from './RVIPoliciesActions/CancelledPoliciesActionItems'
import rviApiService from '../../../api/rviService/rviApiService'

const OperatorRVIPolicies = () => {
  const unlinkedPolices = useSelector((state) => state.rviOperator.unlinkedPolicies.data)
  const isLoadingUnlinkedPolicies = useSelector(
    (state) => state.rviOperator.unlinkedPolicies.isLoading,
  )
  const totalUnlinkedPolicies = useSelector((state) => state.rviOperator.unlinkedPolicies.total)
  const unlinkedPoliciesParams = useSelector((state) => state.rviOperator.unlinkedPolicies.params)

  const linkedPolices = useSelector((state) => state.rviOperator.linkedPolicies.data)
  const isLoadingLinkedPolicies = useSelector((state) => state.rviOperator.linkedPolicies.isLoading)
  const totalLinkedPolicies = useSelector((state) => state.rviOperator.linkedPolicies.total)
  const linkedPoliciesParams = useSelector((state) => state.rviOperator.linkedPolicies.params)

  const policiesInRestitution = useSelector((state) => state.rviOperator.policiesInRestitution.data)
  const isLoadingPoliciesInRestitution = useSelector(
    (state) => state.rviOperator.policiesInRestitution.isLoading,
  )
  const totalPoliciesInRestitution = useSelector(
    (state) => state.rviOperator.policiesInRestitution.total,
  )
  const policiesInRestitutionParams = useSelector(
    (state) => state.rviOperator.policiesInRestitution.params,
  )

  const closedPolicies = useSelector((state) => state.rviOperator.closedPolicies.data)
  const isLoadingClosedPolicies = useSelector((state) => state.rviOperator.closedPolicies.isLoading)
  const totalClosedPolicies = useSelector((state) => state.rviOperator.closedPolicies.total)
  const closedPoliciesParams = useSelector((state) => state.rviOperator.closedPolicies.params)
  const closedPoliciesFilter = useSelector(
    (state) => state.rviOperator.closedPolicies.currentWorkflowStates,
  )

  const cancelledPolicies = useSelector((state) => state.rviOperator.cancelledPolicies.data)
  const isLoadingCancelledPolicies = useSelector(
    (state) => state.rviOperator.cancelledPolicies.isLoading,
  )
  const totalCancelledPolicies = useSelector((state) => state.rviOperator.cancelledPolicies.total)
  const cancelledPoliciesParams = useSelector((state) => state.rviOperator.cancelledPolicies.params)

  const statusCount = useSelector((state) => state.rviOperator.statusCount)

  const dispatch = useDispatch()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [tab, setTab] = useState(0)
  const [uploadPoliciesModal, setUploadPoliciesModal] = useState(false)
  const [importedPoliciesModal, setImportedPoliciesModal] = useState(false)
  const [displayUploadHistory, setDisplayUploadHistory] = useState(false)

  const tablesParams = [
    unlinkedPoliciesParams,
    linkedPoliciesParams,
    policiesInRestitutionParams,
    closedPoliciesParams,
    cancelledPoliciesParams,
  ]

  const handleSetSearchLine = (e) => {
    switch (tab) {
      case 0:
        dispatch(
          setUnlinkedPoliciesParams({
            ...unlinkedPoliciesParams,
            policyExtendedSearch: e.target.value,
            page: 1,
          }),
        )
        return
      case 1:
        dispatch(
          setLinkedPoliciesParams({
            ...linkedPoliciesParams,
            policyExtendedSearch: e.target.value,
            page: 1,
          }),
        )
        return
      case 2:
        dispatch(
          setPoliciesInRestitutionParams({
            ...policiesInRestitutionParams,
            policyExtendedSearch: e.target.value,
            page: 1,
          }),
        )
        return
      case 3:
        dispatch(
          setClosedPoliciesParams({
            ...closedPoliciesParams,
            policyExtendedSearch: e.target.value,
            page: 1,
          }),
        )
        return
      case 4:
        dispatch(
          setCancelledPoliciesParams({
            ...cancelledPoliciesParams,
            policyExtendedSearch: e.target.value,
            page: 1,
          }),
        )
        return
      default:
        dispatch(
          setUnlinkedPoliciesParams({
            ...unlinkedPoliciesParams,
            policyExtendedSearch: e.target.value,
            page: 1,
          }),
        )
        return
    }
  }

  const onFinishLinkingPolicies = () => {
    if (tab !== 0) {
      setTab(0)
      return
    }
    dispatch(getUnlinkedPolicies())
    dispatch(getLinkedPolicies())
  }

  useEffect(() => {
    if (tab === 0) {
      dispatch(getUnlinkedPolicies())
    } else if (tab === 1) {
      dispatch(getLinkedPolicies())
    } else if (tab === 2) {
      dispatch(getPoliciesInRestitution())
    } else if (tab === 3) {
      dispatch(getClosedPolicies())
    } else if (tab === 4) {
      dispatch(getCancelledPolicies())
    }
  }, [
    tab,
    unlinkedPoliciesParams,
    policiesInRestitutionParams,
    closedPoliciesParams,
    linkedPoliciesParams,
    closedPoliciesFilter,
    cancelledPoliciesParams,
  ])

  useEffect(() => {
    dispatch(getPoliciesStatusCount())
    dispatch(setCurrentPageTitle('Residual Value Insurance'))
  }, [])

  // useEffect(() => {
  //   if (closedPolicies.length > 0) {
  //     const outdated = closedPolicies.filter((item) => item?.closingReason === 0)
  //     outdated.map((item) => {
  //       rviApiService.archivePolicy(item?.id)
  //     })
  //   }
  // }, [closedPolicies])

  return (
    <Stack gap="30px">
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          px: {
            xs: '16px',
            md: 0,
          },
        }}
      >
        <AddButton
          fullWidth={xsOnly}
          prefixIcon={<UploadFileRounded />}
          text="Upload policies"
          onClick={() => setUploadPoliciesModal(true)}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <UITabs value={tab} onChange={(e, newVal) => setTab(newVal)}>
          <UITab label={`Unlinked policies (${statusCount.unlinkedPolicies})`} />
          <UITab label={`Linked policies (${statusCount.linkedPolicies})`} />
          <UITab label={`Awaiting restitution (${statusCount.policiesInRestitution})`} />
          <UITab label={`Closed (${statusCount.closedPolicies})`} />
          <UITab label={`Cancelled (${statusCount.cancelledPolicies})`} />
        </UITabs>
        {tab === 0 || tab === 1 ? (
          <UITextButton
            text="Upload history"
            prefixIcon={<HistoryToggleOffRounded />}
            onClick={() => setDisplayUploadHistory(true)}
          />
        ) : null}
        {tab === 2 ? <OperatorMarkers /> : null}
      </Stack>
      <BoxShadowed
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          '&.xs-only': {
            p: 0,
            boxShadow: 'none',
          },
        }}
      >
        <Stack gap="30px">
          <Stack
            className={xsOnly ? 'xs-only' : ''}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            sx={{
              '&.xs-only': {
                px: '16px',
              },
            }}
            gap={{
              xs: '20px',
              md: 0,
            }}
          >
            <SearchInput
              fullWidth={xsOnly}
              value={tablesParams[tab].policyExtendedSearch}
              onChange={handleSetSearchLine}
            />
            {/*{tab === 3 ? (*/}
            {/*  <FiltersInput*/}
            {/*    filters={closedPoliciesCurrentWorkflowStates}*/}
            {/*    filterToLabel={(item) => policyWorkflowStateNames[item]}*/}
            {/*    currentFilter={closedPoliciesFilter}*/}
            {/*    handleFilter={(value) => dispatch(setClosedPolicyCurrentWorkflowStates(value))}*/}
            {/*  />*/}
            {/*) : null}*/}
          </Stack>
          {tab === 0 ? (
            <>
              <UITableWrapper>
                {isLoadingUnlinkedPolicies && <UIPreloader />}
                <UITable
                  head={policiesOperatorTableHead}
                  content={getOperatorRviTableData(unlinkedPolices)}
                  emptyText="No policies found."
                  renderActionItems={(data) => {
                    return <OperatorRVIPoliciesActionItems data={data} />
                  }}
                />
              </UITableWrapper>
              <UITablePagination
                count={totalUnlinkedPolicies}
                page={unlinkedPoliciesParams.page}
                rowsPerPage={unlinkedPoliciesParams.itemsPerPage}
                onPageChange={(e, newValue) => {
                  dispatch(
                    setUnlinkedPoliciesParams({
                      ...unlinkedPoliciesParams,
                      page: newValue,
                    }),
                  )
                }}
                onRowsPerPageChange={(e) => {
                  dispatch(
                    setUnlinkedPoliciesParams({
                      ...unlinkedPoliciesParams,
                      page: 1,
                      itemsPerPage: e.target.value,
                    }),
                  )
                }}
              />
            </>
          ) : tab === 1 ? (
            <UITableWrapper>
              {isLoadingLinkedPolicies && <UIPreloader />}
              <UITable
                head={policiesOperatorTableHead}
                content={getOperatorRviTableData(linkedPolices)}
                emptyText="No policies found."
                renderActionItems={(data) => {
                  return <LinkedPoliciesActionItems data={data} />
                }}
              />
              <UITablePagination
                count={totalLinkedPolicies}
                page={linkedPoliciesParams.page}
                rowsPerPage={linkedPoliciesParams.itemsPerPage}
                onPageChange={(e, newValue) => {
                  dispatch(
                    setLinkedPoliciesParams({
                      ...linkedPoliciesParams,
                      page: newValue,
                    }),
                  )
                }}
                onRowsPerPageChange={(e) => {
                  dispatch(
                    setLinkedPoliciesParams({
                      ...linkedPoliciesParams,
                      page: 1,
                      itemsPerPage: e.target.value,
                    }),
                  )
                }}
              />
            </UITableWrapper>
          ) : tab === 2 ? (
            <UITableWrapper>
              {isLoadingPoliciesInRestitution && <UIPreloader />}
              <UITable
                head={awaitingRestitutionPoliciesHead}
                content={getOperatorRviRestitutionInProgressTableData(policiesInRestitution)}
                emptyText="No policies found."
                renderActionItems={(data) => {
                  return <RestitutionInProgressActionItems data={data} />
                }}
              />
              <UITablePagination
                count={totalPoliciesInRestitution}
                page={policiesInRestitutionParams.page}
                rowsPerPage={policiesInRestitutionParams.itemsPerPage}
                onPageChange={(e, newValue) => {
                  dispatch(
                    setPoliciesInRestitutionParams({
                      ...policiesInRestitutionParams,
                      page: newValue,
                    }),
                  )
                }}
                onRowsPerPageChange={(e) => {
                  dispatch(
                    setPoliciesInRestitutionParams({
                      ...policiesInRestitutionParams,
                      page: 1,
                      itemsPerPage: e.target.value,
                    }),
                  )
                }}
              />
            </UITableWrapper>
          ) : tab === 3 ? (
            <UITableWrapper>
              {isLoadingClosedPolicies && <UIPreloader />}
              <UITable
                head={policiesOperatorTableHead}
                content={getClosedPoliciesTableData(closedPolicies)}
                emptyText="No policies found."
                renderActionItems={(data) => {
                  return <ClosedPoliciesActionItems data={data} />
                }}
              />
              <UITablePagination
                count={totalClosedPolicies}
                page={closedPoliciesParams.page}
                rowsPerPage={closedPoliciesParams.itemsPerPage}
                onPageChange={(e, newValue) => {
                  dispatch(
                    setClosedPoliciesParams({
                      ...closedPoliciesParams,
                      page: newValue,
                    }),
                  )
                }}
                onRowsPerPageChange={(e) => {
                  dispatch(
                    setClosedPoliciesParams({
                      ...unlinkedPoliciesParams,
                      page: 1,
                      itemsPerPage: e.target.value,
                    }),
                  )
                }}
              />
            </UITableWrapper>
          ) : tab === 4 ? (
            <UITableWrapper>
              {isLoadingCancelledPolicies && <UIPreloader />}
              <UITable
                head={policiesOperatorTableHead}
                content={getOperatorRviTableData(cancelledPolicies)}
                emptyText="No policies found."
                renderActionItems={(data) => {
                  return <CancelledPoliciesActionItems data={data} />
                }}
              />
              <UITablePagination
                count={totalCancelledPolicies}
                page={cancelledPoliciesParams.page}
                rowsPerPage={cancelledPoliciesParams.itemsPerPage}
                onPageChange={(e, newValue) => {
                  dispatch(
                    setCancelledPoliciesParams({
                      ...cancelledPoliciesParams,
                      page: newValue,
                    }),
                  )
                }}
                onRowsPerPageChange={(e) => {
                  dispatch(
                    setCancelledPoliciesParams({
                      ...cancelledPoliciesParams,
                      page: 1,
                      itemsPerPage: e.target.value,
                    }),
                  )
                }}
              />
            </UITableWrapper>
          ) : null}
        </Stack>
      </BoxShadowed>
      {uploadPoliciesModal ? (
        <UploadRVIPoliciesModal
          open={uploadPoliciesModal}
          setOpen={setUploadPoliciesModal}
          onImportSuccess={() => setImportedPoliciesModal(true)}
        />
      ) : null}
      {displayUploadHistory && (
        <UploadHistoryModal open={displayUploadHistory} setOpen={setDisplayUploadHistory} />
      )}
      {importedPoliciesModal && (
        <ImportedPoliciesModal
          open={importedPoliciesModal}
          setOpen={setImportedPoliciesModal}
          onClose={onFinishLinkingPolicies}
        />
      )}
    </Stack>
  )
}

export default OperatorRVIPolicies
